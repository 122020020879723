import type { SVGProps } from 'react';

export const PointHistoryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={39.818} height={39.52} {...props}>
    <g fill="#ff9a24">
      <path data-name="\u30D1\u30B9 374" d="M27.774 24.182h-1.716v3.452h1.716a1.741 1.741 0 1 0 0-3.452Z" />
      <path
        data-name="\u30D1\u30B9 375"
        d="M27.384 14.653a12.435 12.435 0 1 0 12.435 12.435 12.436 12.436 0 0 0-12.435-12.435Zm.39 14.9h-1.716v2.513a.875.875 0 0 1-.874.875h-.439a.875.875 0 0 1-.875-.875v-8.929a.874.874 0 0 1 .875-.874h3.028a3.677 3.677 0 1 1 0 7.29Z"
      />
      <path
        data-name="\u30D1\u30B9 376"
        d="M20.2 4.299c0 2.376-4.52 4.3-10.1 4.3S0 6.678 0 4.299s4.522-4.3 10.1-4.3 10.1 1.926 10.1 4.3Z"
      />
      <path
        data-name="\u30D1\u30B9 377"
        d="M0 11.579c0 2.374 4.522 4.3 10.1 4.3s10.1-1.925 10.1-4.3V10.51a.977.977 0 0 0-1.361-.9 17.75 17.75 0 0 1-8.738 1.952A17.74 17.74 0 0 1 1.365 9.61a.978.978 0 0 0-1.363.9Z"
      />
      <path
        data-name="\u30D1\u30B9 378"
        d="M13.363 18.851a15.837 15.837 0 0 0-1.811 4.5 21.7 21.7 0 0 1-1.456.045c-5.573 0-10.1-1.926-10.1-4.3v-1.062a.977.977 0 0 1 1.36-.9 17.754 17.754 0 0 0 8.736 1.953 22.544 22.544 0 0 0 3.271-.236Z"
      />
      <path
        data-name="\u30D1\u30B9 379"
        d="M11.566 30.873c-.476.033-.973.046-1.469.046-5.573 0-10.1-1.927-10.1-4.3V25.55a.976.976 0 0 1 1.36-.9 17.763 17.763 0 0 0 8.736 1.952c.355 0 .7-.006 1.03-.019-.012.168-.012.335-.012.5a15.99 15.99 0 0 0 .455 3.79Z"
      />
      <path
        data-name="\u30D1\u30B9 380"
        d="M15.193 37.846a20.909 20.909 0 0 1-5.1.593c-5.573 0-10.1-1.927-10.1-4.3V33.07a.977.977 0 0 1 1.36-.9 17.763 17.763 0 0 0 8.736 1.952 22.356 22.356 0 0 0 2.552-.141 16.193 16.193 0 0 0 2.552 3.865Z"
      />
    </g>
  </svg>
);
