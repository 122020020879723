import { Box, Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { Suspense } from 'react';
import { MainLoadingOverlay } from 'src/components/loader/MainLoadingOverlay';
import { DischargeHistoryList } from 'src/pages/dischargeHistory/components/DischargeHistoryList';

export const DischargeHistory: FC = () => {
  return (
    <Box px={16} py={26}>
      <Center pb={36}>
        <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
          放電きろく
        </Text>
      </Center>
      <Suspense fallback={<MainLoadingOverlay />}>
        <DischargeHistoryList />
      </Suspense>
    </Box>
  );
};
