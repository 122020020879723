import type { SVGProps } from 'react';

export const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={13} {...props}>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 595" fill="#fff">
      <path data-name="\u9577\u65B9\u5F62 397" d="M4 0h14v2H4z" />
      <path data-name="\u9577\u65B9\u5F62 402" d="M0 0h2v2H0z" />
      <path data-name="\u9577\u65B9\u5F62 398" d="M4 5.5h14v2H4z" />
      <path data-name="\u9577\u65B9\u5F62 401" d="M0 5.5h2v2H0z" />
      <path data-name="\u9577\u65B9\u5F62 399" d="M4 11h14v2H4z" />
      <path data-name="\u9577\u65B9\u5F62 400" d="M0 11h2v2H0z" />
    </g>
  </svg>
);
