import { Box, Center, Image } from '@mantine/core';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLOR, SIZE } from 'src/constants';
import { URL_PATH } from 'src/router/Router';

export const Header: FC = () => {
  const navigate = useNavigate();
  const onClickLogo = () => navigate(URL_PATH.TOP);
  return (
    <Box
      component="header"
      sx={{
        backgroundColor: COLOR.WHITE,
        height: SIZE.HEADER_HEIGHT,
        left: 0,
        position: 'fixed',
        textAlign: 'center',
        top: 0,
        width: '100%',
        zIndex: 2,
      }}
      py={16}
    >
      <Center>
        <Image src={`${process.env.PUBLIC_URL}/image/logo.png`} width={196} onClick={onClickLogo} />
      </Center>
    </Box>
  );
};
