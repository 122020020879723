import { Box, Center, Paper, Space, Text } from '@mantine/core';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COLOR } from 'src/constants';
import { useMessageReadMark } from 'src/pages/news/hooks/useMessageReadMark';
import { URL_PATH } from 'src/router/Router';

type NewsArticleType = {
  news: {
    content: string;
    createdAt: string;
    id: string;
    readAt: string;
    subject: string;
  };
};

export const NewsArticle: FC = () => {
  const location = useLocation();
  const state = location.state as NewsArticleType | undefined;
  const navigate = useNavigate();

  const { updateMessageReadMark } = useMessageReadMark();

  // 単一のお知らせを取得するAPIは無いので仮にstateに設定されていない場合は一覧ページに遷移させる
  if (!state?.news) navigate(URL_PATH.NEWS);

  useEffect(() => {
    if (!state?.news) return;
    if (!state?.news.readAt) {
      // 既読処理されていないお知らせの場合は既読API実行
      updateMessageReadMark({ id: state?.news.id });
    }
  }, [state?.news, updateMessageReadMark]);

  const onClickBack = () => navigate(-1);

  return (
    <Box py={26}>
      <Center pb={35}>
        <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
          お知らせ
        </Text>
      </Center>
      <Box component="section" pt={46} pb={65} px={16} sx={{ backgroundColor: COLOR.BG_GRAY }}>
        <Text size={18} sx={{ fontWeight: 'bold' }}>
          {state?.news.subject}
        </Text>
        <Space h={12} />
        <Text size={13} color={COLOR.MAIN} sx={{ fontWeight: 500 }}>
          {state?.news.createdAt}
        </Text>
        <Space h={23} />
        <Paper radius="lg" px={16} pt={37} pb={58} sx={{ whiteSpace: 'pre-wrap' }}>
          {state?.news.content}
        </Paper>
      </Box>
      <Box pt={50}>
        <Center>
          <Text size={15} sx={{ fontWeight: 500 }} onClick={onClickBack}>
            もどる
          </Text>
        </Center>
      </Box>
    </Box>
  );
};
