import type { PaginationItemProps } from '@mantine/core';
import { Text } from '@mantine/core';
import type { usePagination } from '@mantine/hooks';
import type { FC } from 'react';
import { COLOR } from 'src/constants';

type PagingItemPropsType = PaginationItemProps & {
  pagination: ReturnType<typeof usePagination>;
};

export const PagingItem: FC<PagingItemPropsType> = ({ active, page, pagination }) => {
  if (typeof page === 'number') {
    return (
      <Text
        size={15}
        color={active ? COLOR.MAIN : COLOR.BLACK}
        sx={{
          fontWeight: 'bold',
          textDecoration: active ? 'underline' : 'none',
          textDecorationThickness: '2px',
          textUnderlineOffset: '4px',
        }}
        onClick={() => pagination.setPage(page)}
      >
        {page}
      </Text>
    );
  } else if (page === 'prev') {
    return (
      <Text
        size={15}
        mr={14}
        color={pagination.active === 1 ? COLOR.GRAY : COLOR.BLACK}
        sx={{ fontWeight: 500 }}
        onClick={() => pagination.previous()}
      >
        前へ
      </Text>
    );
  } else if (page === 'next') {
    return (
      <Text
        size={15}
        ml={14}
        color={pagination.range[pagination.range.length - 1] === pagination.active ? COLOR.GRAY : COLOR.BLACK}
        sx={{ fontWeight: 500 }}
        onClick={() => pagination.next()}
      >
        次へ
      </Text>
    );
  } else if (page === 'dots') {
    return (
      <Text size={15} sx={{ fontWeight: 500 }}>
        …
      </Text>
    );
  } else {
    return null;
  }
};
