import { Box, Center, List, Text } from '@mantine/core';
import type { FC } from 'react';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainLoadingOverlay } from 'src/components/loader/MainLoadingOverlay';
import { LinkText } from 'src/components/text/LinkText';
import { COLOR } from 'src/constants';
import { PointHistoryList } from 'src/pages/pointExchange/components/PointHistoryList';
import { URL_PATH } from 'src/router/Router';

export const PointHistory: FC = () => {
  const navigate = useNavigate();
  const onClickContact = () => navigate(URL_PATH.CONTACT);
  return (
    <Box py={26} px={16}>
      <Center pb={26}>
        <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
          進呈予定のポイント履歴
        </Text>
      </Center>
      <Box pb={26} pl={10} pr={16}>
        <List
          size={12}
          styles={() => ({
            item: {
              '&:before': {
                background: COLOR.RED,
              },
              color: COLOR.RED,
            },
          })}
        >
          <List.Item>
            放電時間30分で24WAON POINTを進呈します。ポイントは月毎に集計し翌月21日以降に使用可能となります。
          </List.Item>
          <List.Item>
            ポイントは放電時間15分単位で進呈します。15分未満の場合、ポイント進呈はできません。
            <br />
            （例：40分放電の場合、24ポイント進呈）
          </List.Item>
          <List.Item>ご登録のWAON POINT番号またはWAON番号に誤りがないか、必ずご確認ください。</List.Item>
        </List>
      </Box>
      <Suspense fallback={<MainLoadingOverlay />}>
        <PointHistoryList />
      </Suspense>
      <Center pt={20}>
        <LinkText size={13} onClick={onClickContact}>
          ご不明な点はこちらからお問い合わせ可能です。
        </LinkText>
      </Center>
    </Box>
  );
};
