import type { SVGProps } from 'react';

export const NewsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={46.273} height={33.73} {...props}>
    <path
      data-name="\u30D1\u30B9 369"
      d="M37.372 20.814V7.327h5.078a3.823 3.823 0 0 1 3.822 3.823v5.841a3.824 3.824 0 0 1-3.823 3.824h-5.077Z"
      fill="#f75944"
    />
    <path
      data-name="\u30D1\u30B9 370"
      d="m17.595 1.172 8.974 4.8a11.48 11.48 0 0 0 5.41 1.356h3.059v13.487h-3.063a11.464 11.464 0 0 0-5.41 1.356l-8.974 4.8v1.172h-2.337V0h2.341Zm-.025 23.19 7.909-4.231a13.826 13.826 0 0 1 6.5-1.63h.747V9.639h-.747a13.825 13.825 0 0 1-6.5-1.63L17.57 3.78Z"
      fill="#f75944"
    />
    <path data-name="\u9577\u65B9\u5F62 344" fill="#f75944" d="M11.557 11.373h1.888v5.396h-1.888z" />
    <path
      data-name="\u30D1\u30B9 371"
      d="M45.031 30.52v1.5a1.712 1.712 0 0 1-1.713 1.712h-2.855a1.711 1.711 0 0 1-1.712-1.712v-.939l-2.93-8.094h6.838Z"
      fill="#f75944"
    />
    <path data-name="\u9577\u65B9\u5F62 345" fill="#f75944" d="M0 13.356h8.035v1.445H0z" />
    <path data-name="\u9577\u65B9\u5F62 346" fill="#f75944" d="m9.299 8.263-.736 1.244-6.335-3.745.736-1.244z" />
    <path data-name="\u30D1\u30B9 372" d="m9.297 19.539-6.333 3.745-.736-1.243 6.333-3.746Z" fill="#f75944" />
  </svg>
);
