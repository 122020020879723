import { Box } from '@mantine/core';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from 'src/components/layout/Header';

export const HeaderLayout: FC = () => {
  return (
    <Box>
      <Header />
      <Outlet />
    </Box>
  );
};
