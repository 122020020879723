import type { SVGProps } from 'react';

export const HowToUseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={49} height={37.249} {...props}>
    <g fill="#77be30">
      <path
        data-name="\u30D1\u30B9 342"
        d="M20.006 31.459H1.669V4.571h18.337v10.738l1.667.836V2.672A2.68 2.68 0 0 0 19 0H2.671A2.677 2.677 0 0 0 0 2.672v31.905a2.678 2.678 0 0 0 2.671 2.672H19a2.68 2.68 0 0 0 2.671-2.672v-9.729c-.562-.281-1.125-.562-1.667-.836v7.447Zm-9.17 3.273a.8.8 0 1 1 .8-.8.8.8 0 0 1-.799.801Z"
      />
      <path
        data-name="\u30D1\u30B9 343"
        d="m43.895 15.242-9.31-4.673a3.178 3.178 0 0 0-4.262 1.413 3.061 3.061 0 0 0-.281.861c-.058 0-.117-.009-.174-.009a3.172 3.172 0 0 0-2.84 1.75 3.088 3.088 0 0 0-.33 1.371 2.571 2.571 0 0 0-.887.5 4.118 4.118 0 0 0-.964 1.292 2.52 2.52 0 0 0-.218.685l-6.118-3.07a3.177 3.177 0 0 0-4.265 1.413 3.18 3.18 0 0 0 1.418 4.262l11.641 5.841 2.3 1.154a2.658 2.658 0 0 1 .356.212l-4.156.994a3.483 3.483 0 0 0-2.671 3.383 3.438 3.438 0 0 0 .1.811 3.474 3.474 0 0 0 4.19 2.577l8.815-2.108c1.842-.4 2.988-.785 4.54-1.234a17.128 17.128 0 0 0 4.076-1.6 8.026 8.026 0 0 0 3.158-3.409 9.245 9.245 0 0 0-4.121-12.416Zm2.339 11.522a6.017 6.017 0 0 1-2.4 2.59 15.294 15.294 0 0 1-3.607 1.4c-1.6.464-2.669.821-4.422 1.2l-.019.006-8.826 2.111a1.641 1.641 0 0 1-.347.04 1.491 1.491 0 0 1-1.445-1.141 1.568 1.568 0 0 1-.04-.347 1.49 1.49 0 0 1 1.141-1.445l4.38-1.047a.926.926 0 0 0 .232-.087 2.828 2.828 0 0 0 .884-.679 1.674 1.674 0 0 0 .4-1.059 1.692 1.692 0 0 0-.247-.861 2.664 2.664 0 0 0-.7-.754 4.65 4.65 0 0 0-.716-.441l-2.3-1.151-11.643-5.842a1.175 1.175 0 0 1-.65-1.057 1.155 1.155 0 0 1 .126-.528 1.181 1.181 0 0 1 1.585-.525l8.377 4.205a.911.911 0 0 0 .188.07l.036.007a1.417 1.417 0 0 0 .156.025h.048a.951.951 0 0 0 .147-.006 1.12 1.12 0 0 0 .136-.03.164.164 0 0 0 .051-.012 1.049 1.049 0 0 0 .335-.188l.009-.012a.989.989 0 0 0 .233-.3 1.039 1.039 0 0 0 .072-.187c0-.015 0-.03.006-.045a.859.859 0 0 0 .025-.148v-.051a.911.911 0 0 0-.006-.144.763.763 0 0 0-.018-.081.645.645 0 0 0-.024-.106 1.016 1.016 0 0 0-.19-.333s-.006 0-.009-.009a.984.984 0 0 0-.3-.232.5.5 0 0 1-.224-.229.891.891 0 0 1-.091-.407.734.734 0 0 1 .052-.29 2.189 2.189 0 0 1 .481-.673.482.482 0 0 1 .338-.147.679.679 0 0 1 .175.025 1 1 0 0 0 1.171-1.365 1.236 1.236 0 0 1-.1-.49 1.126 1.126 0 0 1 .121-.511 1.183 1.183 0 0 1 1.587-.523l.3.153a1 1 0 0 0 1.371-1.265 1.207 1.207 0 0 1-.09-.455 1.122 1.122 0 0 1 .123-.51 1.179 1.179 0 0 1 1.584-.525l9.312 4.672a7.252 7.252 0 0 1 3.233 9.739Z"
      />
    </g>
  </svg>
);
