import type { SVGProps } from 'react';

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26.521} height={26.521} {...props}>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 845">
      <path
        data-name="\u30D1\u30B9 514"
        d="M13.26 0a13.26 13.26 0 1 0 13.26 13.26A13.276 13.276 0 0 0 13.26 0Zm7.411 9.771L12.2 18.179a1.3 1.3 0 0 1-1.828.033l-4.49-4.088a1.348 1.348 0 0 1-.1-1.861 1.315 1.315 0 0 1 1.862-.063l3.556 3.254 7.577-7.577a1.34 1.34 0 0 1 1.894 1.894Z"
        fill="#fff"
      />
    </g>
  </svg>
);
