import { Radio } from '@mantine/core';
import type { FC } from 'react';
import type { STORE_INFO } from 'src/constants';
import { COLOR } from 'src/constants';

type StoreSelectRadioPropsType = {
  onChange: (value: string) => void;
  stores: typeof STORE_INFO;
  value: string;
};

export const StoreSelectRadio: FC<StoreSelectRadioPropsType> = ({ onChange, stores, value }) => {
  return (
    <Radio.Group name="selectedStore" orientation="vertical" value={value} onChange={onChange}>
      {stores.map((store) => (
        <Radio
          key={store.installationId}
          value={store.installationId}
          label={store.name}
          labelPosition="left"
          description={store.address}
          styles={() => ({
            body: {
              borderBottom: `solid 1px ${COLOR.GRAY}`,
              paddingBottom: '16px',
              width: '100%',
            },
            description: {
              fontSize: '13px',
              fontWeight: 500,
            },
            icon: {
              color: COLOR.MAIN,
            },
            inner: {
              marginLeft: 'auto',
              marginTop: '8px',
            },
            label: {
              fontSize: '18px',
              fontWeight: 'bold',
            },
            radio: {
              '&:checked': {
                backgroundColor: COLOR.MAIN,
                border: 'none',
              },
              '&:focus': {
                outline: 'none',
              },
            },
          })}
        />
      ))}
    </Radio.Group>
  );
};
