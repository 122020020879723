import { Box, Center, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainButton } from 'src/components/button/MainButton';
import { COLOR } from 'src/constants';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { AccountCheckbox } from 'src/pages/account/components/AccountCheckbox';
import { ErrorCheckModal } from 'src/pages/account/components/ErrorCheckModal';
import { URL_PATH } from 'src/router/Router';

export const AccountSetting: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const isInitialFlow = !!state?.isInitialFlow;

  const { userInfo } = useUserInfo();

  const [isOpenErrorCheckModal, handleErrorCheckModal] = useDisclosure(false);

  // note1,2には文字列で回答内容が設定されるのでフラグとして変換
  const [isChecked1, setIsChecked1] = useState<boolean>(!!userInfo?.note1);
  const [isChecked2, setIsChecked2] = useState<boolean>(!!userInfo?.note2);

  const onClickCheck1 = () => setIsChecked1((prev) => !prev);
  const onClickCheck2 = () => setIsChecked2((prev) => !prev);

  const onClickConfirm = () => {
    if (!isChecked1 || !isChecked2) {
      handleErrorCheckModal.open();
      return;
    }
    navigate(URL_PATH.ACCOUNT_SETTING_CONFIRM, { state: { isInitialFlow } });
  };
  const onCloseErrorCheckModal = useCallback(() => handleErrorCheckModal.close(), [handleErrorCheckModal]);

  return (
    <Box>
      <Box px={16} py={26}>
        <Center pb={20}>
          <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
            アカウント設定
          </Text>
        </Center>
        <Center pb={35}>
          <Text size={15} sx={{ fontWeight: 500, textAlign: 'center' }}>
            V2AEONMALLサービスのご利用にあたり、以下項目のご確認をお願い致します。
          </Text>
        </Center>
        <Stack spacing={58}>
          <Box>
            <Text size={15} mb={6} sx={{ fontWeight: 500 }}>
              ご自宅に太陽光発電設備、および、EV充電器を設置していますか？
            </Text>
            <Text size={13} mb={20} color={COLOR.RED} sx={{ fontWeight: 400 }}>
              ※ V2AEONMALLサービスをご利用いただく為に、必要な設備となります。
            </Text>
            <AccountCheckbox isChecked={isChecked1} onClick={onClickCheck1} />
          </Box>
          <Box>
            <Text size={15} mb={6} sx={{ fontWeight: 500 }}>
              太陽光発電設備で発電した電気をEVに充電していますか？
            </Text>
            <Text size={13} mb={20} color={COLOR.RED} sx={{ fontWeight: 400 }}>
              ※ V2AEONMALLサービスをご利用いただくには、太陽光発電でEV充電を実施いただく必要があります。
            </Text>
            <AccountCheckbox isChecked={isChecked2} onClick={onClickCheck2} />
          </Box>
        </Stack>
        <Center pt={79} pb={22} px={57}>
          <MainButton isDisabled={isInitialFlow && (!isChecked1 || !isChecked2)} onClick={onClickConfirm}>
            {isInitialFlow ? '登録する' : '更新する'}
          </MainButton>
        </Center>
        <Text size={13} sx={{ fontWeight: 500 }}>
          ※上記の条件をご確認の上チェックを入れてください。
        </Text>
      </Box>
      <ErrorCheckModal isOpen={isOpenErrorCheckModal} onClose={onCloseErrorCheckModal} />
    </Box>
  );
};
