import { Checkbox, Group, Paper, Text } from '@mantine/core';
import type { FC, ReactNode } from 'react';
import { COLOR } from 'src/constants';

type ConfirmCheckboxPropsType = {
  children: ReactNode;
  isChecked: boolean;
  onClick: VoidFunction;
};

export const ConfirmCheckbox: FC<ConfirmCheckboxPropsType> = ({ children, isChecked, onClick }) => {
  return (
    <Paper
      py={14}
      pl={16}
      pr={11}
      radius="md"
      onClick={onClick}
      sx={{ border: `solid 1px ${isChecked ? COLOR.MAIN : COLOR.DARK_GRAY}` }}
    >
      <Group position="apart" align="center" sx={{ flexWrap: 'nowrap' }} spacing={4}>
        <Text size={14} sx={{ fontWeight: 500 }}>
          {children}
        </Text>
        <Checkbox
          size="lg"
          checked={isChecked}
          styles={() => ({
            input: {
              '&:checked': {
                backgroundColor: COLOR.MAIN,
                border: 'none',
              },
              backgroundColor: COLOR.LIGHT_GRAY,
              border: `solid 1px ${COLOR.DARK_GRAY}`,
            },
            root: {
              alignItems: 'center',
              display: 'flex',
            },
          })}
        />
      </Group>
    </Paper>
  );
};
