import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useState } from 'react';
import { API_PATH, MESSAGE } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { UserInfoResType, UserUpdate400ResType } from 'src/types';
import { getApikey } from 'src/utils';

export const useUpdateWaonNo = () => {
  const queryClient = useQueryClient();

  const [errorText, setErrorText] = useState<string>('');

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: async ({ waon }: { waon: string }) => {
      return await axiosInstance.put(
        `${API_PATH.USER_UPDATE}`,
        {
          waon,
        },
        {
          headers: {
            Authorization: `Bearer ${getApikey()}`,
          },
        },
      );
    },
    onError: (error: AxiosError<UserUpdate400ResType>) => {
      if (error.response?.data.error?.waon) {
        setErrorText(MESSAGE.WAON_NO_REGISTER_FAULT_DUPLICATE);
      } else {
        setErrorText(MESSAGE.WAON_NO_REGISTER_FAULT);
      }
    },
    onMutate() {
      setErrorText('');
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData<UserInfoResType>([API_PATH.USER_INFO], (oldUserInfo?: UserInfoResType) => {
        if (!oldUserInfo) return undefined;
        return { ...oldUserInfo, waon: variables.waon };
      });
    },
  });
  return { errorText, isLoading, updateWaonNo: mutateAsync };
};
