import type { SVGProps } from 'react';

export const PointHistoryLgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={102.409} height={101.64} {...props}>
    <g fill="#ff9a24">
      <path
        data-name="\u30D1\u30B9 374"
        d="M71.431 62.193h-4.413v8.879h4.413c2.794 0 5.063-1.991 5.063-4.441s-2.269-4.438-5.063-4.438Z"
      />
      <path
        data-name="\u30D1\u30B9 375"
        d="M70.428 37.681a31.981 31.981 0 1 0 31.981 31.981 31.984 31.984 0 0 0-31.981-31.981Zm1 38.332h-4.413v6.463a2.25 2.25 0 0 1-2.247 2.25h-1.127a2.251 2.251 0 0 1-2.25-2.25V59.508a2.248 2.248 0 0 1 2.25-2.247h7.788c5.9 0 10.695 4.2 10.695 9.37s-4.795 9.379-10.693 9.379Z"
      />
      <path
        data-name="\u30D1\u30B9 376"
        d="M51.948 11.057c0 6.11-11.626 11.06-25.974 11.06S0 17.167 0 11.057 11.629 0 25.974 0s25.974 4.95 25.974 11.057Z"
      />
      <path
        data-name="\u30D1\u30B9 377"
        d="M0 29.78c0 6.107 11.629 11.057 25.974 11.057s25.974-4.95 25.974-11.057v-2.751a2.512 2.512 0 0 0-3.5-2.313s-8.126 5.019-22.474 5.019-22.468-5.019-22.468-5.019A2.516 2.516 0 0 0 0 27.029Z"
      />
      <path
        data-name="\u30D1\u30B9 378"
        d="M34.369 48.483a40.731 40.731 0 0 0-4.657 11.582 56.695 56.695 0 0 1-3.744.116c-14.332 0-25.965-4.954-25.965-11.07V46.38a2.514 2.514 0 0 1 3.5-2.322s8.135 5.022 22.468 5.022a57.987 57.987 0 0 0 8.398-.597Z"
      />
      <path
        data-name="\u30D1\u30B9 379"
        d="M29.746 79.401c-1.225.084-2.5.119-3.779.119C11.635 79.52.002 74.563.002 68.466v-2.75a2.51 2.51 0 0 1 3.5-2.319s8.135 5.019 22.468 5.019c.913 0 1.791-.016 2.65-.05-.031.431-.031.863-.031 1.294a41.126 41.126 0 0 0 1.157 9.741Z"
      />
      <path
        data-name="\u30D1\u30B9 380"
        d="M39.075 97.331a53.771 53.771 0 0 1-13.107 1.525C11.636 98.856.003 93.899.003 87.802v-2.75a2.514 2.514 0 0 1 3.5-2.319s8.135 5.019 22.468 5.019a57.492 57.492 0 0 0 6.563-.363 41.643 41.643 0 0 0 6.541 9.942Z"
      />
    </g>
  </svg>
);
