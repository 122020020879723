import type { SVGProps } from 'react';

export const ScanIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21.792} height={21.792} {...props}>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 524" fill="#fff">
      <path
        data-name="\u30D1\u30B9 409"
        d="M21.792 21.792h-8.915V20.8H20.8v-7.923h.99v8.914Zm-12.876 0H0v-8.915h.991V20.8h7.925v.989ZM21.792 8.916H20.8V.99h-7.923V0h8.915v8.915Zm-20.8 0H0V0h8.916v.99H.991v7.924Z"
      />
      <path
        data-name="\u30D1\u30B9 409 - \u30A2\u30A6\u30C8\u30E9\u30A4\u30F3"
        d="M21.792 21.792h-8.915V20.8H20.8v-7.923h.99Zm-12.876 0H0v-8.915h.991V20.8h7.925ZM21.792 8.916H20.8V.99h-7.923V0h8.915Zm-20.8 0H0V0h8.916v.99H.991Z"
      />
      <path data-name="\u30D1\u30B9 410" d="M1.061 10.404h19.671v.984H1.061Z" />
      <path data-name="\u30D1\u30B9 410 - \u30A2\u30A6\u30C8\u30E9\u30A4\u30F3" d="M1.061 10.404h19.671v.984H1.061Z" />
    </g>
  </svg>
);
