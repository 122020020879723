import 'react-spring-bottom-sheet/dist/style.css';

import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { BaseLayout } from 'src/components/layout/BaseLayout';
import { MainLoadingOverlay } from 'src/components/loader/MainLoadingOverlay';
import { COLOR } from 'src/constants';
import { router } from 'src/router/Router';

if (process.env.REACT_APP_IS_USE_MSW === 'true') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./.mock/browser');
  worker.start();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

const FONT_FAMILY = `'M PLUS Rounded 1c', sans-serif`;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        components: {
          List: {
            styles: () => ({
              item: {
                '&:before': {
                  background: COLOR.BLACK,
                  borderRadius: '100%',
                  content: "''",
                  display: 'inline-block',
                  height: '3px',
                  left: '0',
                  marginRight: '8px',
                  position: 'relative',
                  top: '-2px',
                  width: '3px',
                },
                listStyleType: 'none',
              },
            }),
          },
        },
        fontFamily: FONT_FAMILY,
        fontFamilyMonospace: FONT_FAMILY,
        globalStyles: () => ({
          body: {
            backgroundColor: COLOR.LIGHT_GRAY,
            letterSpacing: '1px',
          },
        }),
        headings: { fontFamily: FONT_FAMILY },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<MainLoadingOverlay />}>
          <BaseLayout>
            <RouterProvider router={router} />
          </BaseLayout>
        </Suspense>
      </QueryClientProvider>
    </MantineProvider>
  </StrictMode>,
);
