import { Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { TextButton } from 'src/components/button/TextButton';
import { MainModal } from 'src/components/modal/MainModal';

type ConfirmDeleteAccountModalPropsType = {
  isLoading: boolean;
  isOpen: boolean;
  onClickDeleteAccount: VoidFunction;
  onClose: VoidFunction;
};

export const ConfirmDeleteAccountModal: FC<ConfirmDeleteAccountModalPropsType> = memo(
  ({ isLoading, isOpen, onClickDeleteAccount, onClose }) => {
    return (
      <MainModal
        isOpen={isOpen}
        onClose={onClose}
        title={`本当に退会しても${'\n'}よろしいですか？`}
        mainButton={
          <MainButton isLoading={isLoading} onClick={onClose}>
            戻る
          </MainButton>
        }
        subButton={
          <TextButton isDisabled={isLoading} onClick={onClickDeleteAccount}>
            退会する
          </TextButton>
        }
      >
        <Center pt={14} pb={8} px={32}>
          <Text size={15} sx={{ fontWeight: 500, textAlign: 'center' }}>
            退会すると、各種履歴やポイント等、全ての情報が削除されます。ご注意ください。
          </Text>
        </Center>
      </MainModal>
    );
  },
);
ConfirmDeleteAccountModal.displayName = 'ConfirmDeleteAccountModal';
