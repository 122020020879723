import type { SVGProps } from 'react';

export const MemoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={73.749} height={70.2} {...props}>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 579" fill="#519ff3">
      <path
        data-name="\u30D1\u30B9 271"
        d="m72.533 20.696-2.675-2.674a4.153 4.153 0 0 0-5.873 0l-2.5 2.5 8.548 8.548 2.5-2.5a4.157 4.157 0 0 0 0-5.874Z"
      />
      <path data-name="\u30D1\u30B9 272" d="M38.039 43.97v8.548h8.548L67.7 31.406l-8.548-8.548Z" />
      <path data-name="\u9577\u65B9\u5F62 289" d="M17.445 18.723h24.144v4.024H17.445z" />
      <path data-name="\u9577\u65B9\u5F62 290" d="M17.445 33.478h24.144v4.024H17.445z" />
      <path data-name="\u9577\u65B9\u5F62 291" d="M17.445 48.232h14.754v4.024H17.445z" />
      <path
        data-name="\u30D1\u30B9 273"
        d="M53.668 63.715a1.122 1.122 0 0 1-1.119 1.12H6.484a1.124 1.124 0 0 1-1.119-1.12V6.485a1.124 1.124 0 0 1 1.119-1.12H52.55a1.123 1.123 0 0 1 1.119 1.12V22.65l5.365-5.365v-10.8A6.486 6.486 0 0 0 52.55 0H6.484A6.487 6.487 0 0 0 0 6.485v57.23A6.487 6.487 0 0 0 6.484 70.2H52.55a6.485 6.485 0 0 0 6.484-6.485V45.762l-5.365 5.365Z"
      />
    </g>
  </svg>
);
