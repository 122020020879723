import type { FC } from 'react';
import { memo } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { ErrorModal } from 'src/components/modal/ErrorModal';

type ErrorCheckModalPropsType = {
  isOpen: boolean;
  onClose: VoidFunction;
};

export const ErrorCheckModal: FC<ErrorCheckModalPropsType> = memo(({ isOpen, onClose }) => {
  return (
    <ErrorModal isOpen={isOpen} onClose={onClose} buttonElement={<MainButton onClick={onClose}>OK</MainButton>}>
      大変申し訳ございません。{'\n'}どちらも満たしていない場合は、本サービスをご利用いただけません。
    </ErrorModal>
  );
});
ErrorCheckModal.displayName = 'ErrorCheckModal';
