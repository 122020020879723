import type { SVGProps } from 'react';

export const NewTabIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={13} {...props}>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 665" fill="#b60081">
      <path data-name="\u9577\u65B9\u5F62 423" d="M1.2 4.2v7.6h10.6V4.2H1.2M0 3h13v10H0Z" />
      <path data-name="\u9577\u65B9\u5F62 424" d="M4.2 1.2v7.6h10.6V1.2H4.2M3 0h13v10H3Z" />
    </g>
  </svg>
);
