import dayjs from 'dayjs';

export const formatYYYYMMDD = (date: string): string => {
  return dayjs(date).format('YYYYMMDD');
};

export const nowDateYYYYMMDD_dash = (): string => {
  return dayjs().format('YYYY-MM-DD');
};

export const twoYearsBeforeDateYYYYMMDD_dash = (): string => {
  return dayjs().subtract(2, 'year').format('YYYY-MM-DD');
};

export const formatYYYYMDjaText = (date: string): string => {
  return dayjs(date).format('YYYY年M月D日');
};

export const formatMDHmjaText = (date: string): string => {
  return dayjs(date).format('M月D日H時m分');
};

export const hasPassedOneYear = (date: string | null | undefined): boolean => {
  if (!date) false;
  return dayjs().isAfter(dayjs(date).add(1, 'year'));
};

export const hasPassed12hour = (date: string): boolean => {
  if (!date) true;
  return dayjs().isAfter(dayjs(date).add(12, 'hour'));
};
