export const COLOR = {
  BG_GRAY: '#F0F0F0',
  BLACK: '#1E1C1C',
  DARK_GRAY: '#999999',
  GRAY: '#DDDDDD',
  LIGHT_BLUE: '#F8FEFF',
  LIGHT_GRAY: '#FAFAFA',
  MAIN: '#B60081',
  MAIN_GREEN: '#09B555',
  MEDIUM_GRAY: '#989898',
  MEDIUM_GREEN: '#77BE30',
  RED: '#E82817',
  WHITE: '#FFFFFF',
} as const;
