import { Box, Center, Paper, Stack, Text } from '@mantine/core';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainButton } from 'src/components/button/MainButton';
import { LinkText } from 'src/components/text/LinkText';
import { URL_PATH } from 'src/router/Router';

export const DischargeFinish: FC = () => {
  const navigate = useNavigate();

  const onClickComplete = () => navigate(URL_PATH.TOP);
  const onClickToDischargeHistory = () => navigate(URL_PATH.DISCHARGE_HISTORY);
  const onClickToPointHistory = () => navigate(URL_PATH.POINT_HISTORY);
  const onClickContact = () => navigate(URL_PATH.CONTACT);

  return (
    <Box py={26} px={32}>
      <Center>
        <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
          放電終了
        </Text>
      </Center>
      <Center pt={20} pb={43}>
        <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
          放電が終了しました。{'\n'}スタンドに戻り、ご確認の上、{'\n'}放電コネクタを外してください。
        </Text>
      </Center>
      <Paper radius="xl" pt={53} pb={42} px={25}>
        <Stack spacing={24}>
          <Center>
            <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
              放電量を計測中です。{'\n'}
              放電量・付与ポイントは確定後{'\n'}
              <LinkText onClick={onClickToDischargeHistory}>放電きろく</LinkText>・
              <LinkText onClick={onClickToPointHistory}>ポイント履歴</LinkText>
              よりそれぞれ確認可能となります。放電により進呈されるWAON POINTは、翌月21日以降に使用可能となります。
            </Text>
          </Center>
          <Center>
            <Text size={13} sx={{ fontWeight: 500, textAlign: 'center' }}>
              ※ 本サービスは1日1回限りのご利用とさせていただきます。
            </Text>
          </Center>
        </Stack>
        <Center pt={27}>
          <MainButton onClick={onClickComplete}>完了</MainButton>
        </Center>
      </Paper>
      <Box pt={37}>
        <Text size={13} color="#696969" sx={{ fontWeight: 400, whiteSpace: 'pre-wrap' }}>
          ポイントに関するお問い合わせは、アプリ内の{'\n'}
          <LinkText onClick={onClickContact}>お問い合わせ</LinkText>
          より、お願いいたします。
        </Text>
      </Box>
    </Box>
  );
};
