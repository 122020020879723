// 16桁のWAON番号をformで扱えるように4桁区切りにして返却
export const splitWaonNo = (waon: string | undefined | null): string[] => {
  if (!waon) return ['', '', '', ''];
  return [waon.substring(0, 4), waon.substring(4, 8), waon.substring(8, 12), waon.substring(12, 16)];
};

const REG_NOT_HALF_SIZE_NUM = /[^0-9]/;

// 半角数字かどうか
export const isNotHalfSizeNum = (str: string): boolean => {
  const regex = RegExp(REG_NOT_HALF_SIZE_NUM);
  return regex.test(str);
};

// 半角数字以外の文字を除去
export const replaceNotHalfSizeNum = (str: string): string => {
  return str.replace(REG_NOT_HALF_SIZE_NUM, '');
};
