import { TextInput } from '@mantine/core';
import type { FC } from 'react';
import { COLOR } from 'src/constants';

export const WaonNoInput: FC = ({ ...props }) => {
  return (
    <TextInput
      {...props}
      size="sm"
      type="tel"
      maxLength={4}
      required
      styles={() => ({
        input: {
          border: `solid 1px ${COLOR.DARK_GRAY}`,
          width: '70px',
        },
      })}
    />
  );
};
