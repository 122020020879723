export const STORE_INFO = [
  {
    address: '大阪府堺市',
    installationId: 'A0000001017',
    name: 'イオンモール堺北花田',
    stands: [1, 2],
  },
  {
    address: '大阪府堺市',
    installationId: 'A0000001155',
    name: 'イオンモール堺鉄砲町',
    stands: [1, 2],
  },
  {
    address: '奈良県橿原市',
    installationId: 'A0000001012',
    name: 'イオンモール橿原',
    stands: [1, 2],
  },
] as const;
