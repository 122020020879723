import { Box, Text } from '@mantine/core';
import type { FC, ReactNode } from 'react';
import { NewTabIcon } from 'src/components/icon/NewTabIcon';
import { COLOR } from 'src/constants';

type CommonPropsType = {
  children: ReactNode;
  isWithIcon?: boolean;
  size?: number;
};

type LinkTextPropsType = CommonPropsType &
  (
    | {
        onClick: VoidFunction;
      }
    | {
        url: string;
      }
  );

export const LinkText: FC<LinkTextPropsType> = (props) => {
  const { children, isWithIcon = false, size = 15 } = props;

  if ('url' in props) {
    return (
      <Box
        component="span"
        onClick={() => (window.location.href = props.url)}
        sx={{ ':hover': { cursor: 'pointer' }, borderBottom: `solid 1px ${COLOR.MAIN}` }}
      >
        <Text component="span" size={size} sx={{ color: COLOR.MAIN, fontWeight: 500 }}>
          {children}
        </Text>
        {isWithIcon && (
          <Box component="span" ml={4} mt={4}>
            <NewTabIcon />
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Box
        component="span"
        onClick={props.onClick}
        sx={{ ':hover': { cursor: 'pointer' }, borderBottom: `solid 1px ${COLOR.MAIN}` }}
      >
        <Text component="span" size={size} sx={{ color: COLOR.MAIN, fontWeight: 500 }}>
          {children}
        </Text>
        {isWithIcon && (
          <Box component="span" ml={4} mt={4}>
            <NewTabIcon />
          </Box>
        )}
      </Box>
    );
  }
};
