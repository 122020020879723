import { useMutation } from '@tanstack/react-query';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { MessageReadMarkResType } from 'src/types';
import { getApikey } from 'src/utils';

export const useMessageReadMark = () => {
  const { mutate } = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      return await axiosInstance.post<MessageReadMarkResType>(
        `${API_PATH.MESSAGE_READ_MARK}/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getApikey()}`,
          },
        },
      );
    },
  });
  return { updateMessageReadMark: mutate };
};
