import { Box, Center, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainButton } from 'src/components/button/MainButton';
import { TextButton } from 'src/components/button/TextButton';
import { ErrorModal } from 'src/components/modal/ErrorModal';
import { ERROR_NO } from 'src/constants/errorNo';
import { AccountSettingFinishModal } from 'src/pages/account/components/AccountSettingFinishModal';
import { ConfirmCheckYes } from 'src/pages/account/components/ConfirmCheckYes';
import { useUpdateUserAccountInfo } from 'src/pages/account/hooks/useUpdateUserAccountInfo';
import { URL_PATH } from 'src/router/Router';

export const AccountSettingConfirm: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const isInitialFlow = !!state?.isInitialFlow;

  const { isLoading, updateUserAccountInfo } = useUpdateUserAccountInfo();

  const [isOpenFinishModal, handleOpenFinishModal] = useDisclosure(false);
  const [isOpenErrorModal, handleOpenErrorModal] = useDisclosure(false);

  const onClickRegister = async () => {
    try {
      await updateUserAccountInfo();
      handleOpenFinishModal.open();
    } catch {
      handleOpenErrorModal.open();
    }
  };
  const onClickBack = () => navigate(-1);

  const onCloseFinishModal = useCallback(() => {
    if (isInitialFlow) {
      navigate(URL_PATH.TOP);
    } else {
      navigate(URL_PATH.ACCOUNT);
    }
  }, [navigate, isInitialFlow]);
  const onCloseErrorModal = useCallback(() => handleOpenErrorModal.close(), [handleOpenErrorModal]);

  return (
    <Box>
      <Box px={16} py={26}>
        <Center pb={20}>
          <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
            入力内容確認
          </Text>
        </Center>
        <Center pb={60}>
          <Text size={15} sx={{ fontWeight: 500, textAlign: 'center' }}>
            以下の内容でお間違い無いですか
          </Text>
        </Center>
        <Stack spacing={42}>
          <Box>
            <Text size={15} mb={13} sx={{ fontWeight: 500 }}>
              ご自宅に太陽光発電設備、および、EV充電器を設置していますか？
            </Text>
            <ConfirmCheckYes />
          </Box>
          <Box>
            <Text size={15} mb={6} sx={{ fontWeight: 500 }}>
              太陽光発電設備で発電した電気をEVに充電していますか？
            </Text>
            <ConfirmCheckYes />
          </Box>
        </Stack>
        <Center pt={68} px={57}>
          <MainButton isLoading={isLoading} onClick={onClickRegister}>
            {isInitialFlow ? '登録する' : '更新する'}
          </MainButton>
        </Center>
        <Center pt={31}>
          <TextButton isDisabled={isLoading} onClick={onClickBack}>
            戻る
          </TextButton>
        </Center>
      </Box>
      <AccountSettingFinishModal
        isOpen={isOpenFinishModal}
        onClose={onCloseFinishModal}
        isInitialFlow={isInitialFlow}
      />
      <ErrorModal
        errorNo={ERROR_NO.E007}
        isOpen={isOpenErrorModal}
        onClose={onCloseErrorModal}
        buttonElement={<MainButton onClick={onCloseErrorModal}>OK</MainButton>}
      >
        エラーが発生しました。{'\n'}イオンモールアプリを再起動し、操作をやり直してください。
      </ErrorModal>
    </Box>
  );
};
