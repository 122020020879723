export const API_PATH = {
  ENEGATE_AUTH: '/enegate/auth',
  ENEGATE_GEOLOC: '/enegate/geoloc',
  ENEGATE_HISTORY: '/enegate/history',
  ENEGATE_START_DISCHARGE: '/enegate/discharge',
  ENEGATE_STATUS: '/enegate/status',
  ENEGATE_STOP_DISCHARGE: '/enegate/discharge',
  LOGIN_AEON: '/login/aeon',
  MESSAGE_LIST: '/message/history',
  MESSAGE_READ_MARK: '/message/read',
  POINT_LIST: '/point/history',
  USER_INFO: '/key',
  USER_QUIT: '/quit',
  USER_UPDATE: '/key',
} as const;
