import type { SVGProps } from 'react';

export const StandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={75.326} height={75.584} {...props}>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 473" fill="#09b555">
      <path
        data-name="\u524D\u9762\u30AA\u30D6\u30B8\u30A7\u30AF\u30C8\u3067\u578B\u629C\u304D 6"
        d="M52.905 75.584H1.35A1.35 1.35 0 0 1 0 74.234v-8.24a1.35 1.35 0 0 1 .4-.955 1.35 1.35 0 0 1 .955-.4h3.004V8.177A8.127 8.127 0 0 1 12.415 0H41.84a8.127 8.127 0 0 1 8.057 8.177V35.05a9.648 9.648 0 0 1 5.42 2.062 10.465 10.465 0 0 1 3.266 4.5 9.857 9.857 0 0 1 .653 3.463v9.508a3.573 3.573 0 0 0 .568 2.3.537.537 0 0 0 .443.235.945.945 0 0 0 .648-.351 3.312 3.312 0 0 0 .622-2.187V37.49a1.35 1.35 0 0 1 1.35-1.35h4.983a1.35 1.35 0 0 1 1.35 1.35v17.1a10.948 10.948 0 0 1-2.6 7.387 8.557 8.557 0 0 1-6.358 2.918h-.033a8.058 8.058 0 0 1-3.368-.75 8.269 8.269 0 0 1-2.849-2.175 11.125 11.125 0 0 1-2.437-7.382v-9.517a2.487 2.487 0 0 0-.351-1.1l-.007-.011a2.332 2.332 0 0 0-.967-.941 1.957 1.957 0 0 0-.334-.127v21.752h3.009a1.35 1.35 0 0 1 1.35 1.35v8.239a1.351 1.351 0 0 1-1.35 1.351ZM2.7 72.884h48.855v-5.54H48.55a1.35 1.35 0 0 1-1.35-1.35V41.518a1.35 1.35 0 0 1 1.15-1.336 6.238 6.238 0 0 1 .921-.079 4.832 4.832 0 0 1 2.142.477 5.016 5.016 0 0 1 2.115 2 5.1 5.1 0 0 1 .73 2.506v9.5a8.486 8.486 0 0 0 1.792 5.628v.005a5.589 5.589 0 0 0 1.926 1.473 5.336 5.336 0 0 0 2.228.5h.037a5.858 5.858 0 0 0 4.344-2.016 8.271 8.271 0 0 0 1.913-5.585V38.84h-2.28v15.753a5.864 5.864 0 0 1-1.3 3.965l-.01.011a3.593 3.593 0 0 1-2.666 1.251h-.031a3.227 3.227 0 0 1-2.446-1.17l-.015-.01a6.074 6.074 0 0 1-1.216-4.049v-9.51a7.161 7.161 0 0 0-.479-2.511 7.76 7.76 0 0 0-2.42-3.342 6.963 6.963 0 0 0-4.333-1.491h-.03c-.216 0-.436.014-.622.029a1.35 1.35 0 0 1-1.452-1.346V8.177A5.424 5.424 0 0 0 41.84 2.7H12.415a5.424 5.424 0 0 0-5.356 5.477v57.817a1.35 1.35 0 0 1-1.35 1.35H2.7Zm35.884-37.862H15.671a1.35 1.35 0 0 1-1.35-1.35V11.49a1.35 1.35 0 0 1 1.35-1.35h22.913a1.35 1.35 0 0 1 1.35 1.35v22.182a1.35 1.35 0 0 1-1.35 1.35Zm-21.563-2.7h20.213V12.84H17.021Z"
      />
      <g data-name="\u30D5\u309A\u30E9\u30AF\u3099\u3001\u30B3\u30F3\u30BB\u30F3\u30C8\u306E\u30A2\u30A4\u30B3\u30F31">
        <path
          data-name="\u30D1\u30B9 264"
          d="M71.562 19.543v-6.086a.821.821 0 0 0-.868-.763h-1.389a.821.821 0 0 0-.869.763v6.086h-6.288v-6.086a.821.821 0 0 0-.869-.763H59.89a.821.821 0 0 0-.869.763v6.086h-3.623v2.592h19.788v-2.592Z"
        />
        <path
          data-name="\u30D1\u30B9 265"
          d="M55.258 22.936h20.067v4.562a9.069 9.069 0 0 1-4.525 7.835v.852a2.809 2.809 0 0 1-2.806 2.806h-5.4a2.809 2.809 0 0 1-2.806-2.806v-.852a9.07 9.07 0 0 1-4.525-7.835Zm17.367 2.7H57.958v1.862a6.363 6.363 0 0 0 3.732 5.783l.793.359v2.545a.106.106 0 0 0 .106.106h5.4a.106.106 0 0 0 .106-.106V33.64l.793-.359a6.362 6.362 0 0 0 3.732-5.783Z"
        />
      </g>
    </g>
  </svg>
);
