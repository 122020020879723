import { useQuery } from '@tanstack/react-query';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { EnegateGeolocResType } from 'src/types';
import { getApikey } from 'src/utils';

type UseMessageListPropsType = {
  qr: string;
};

export const useEnegateGeoloc = ({ qr }: UseMessageListPropsType) => {
  const { data, status } = useQuery<EnegateGeolocResType>({
    cacheTime: Infinity,
    enabled: !!getApikey() && !!qr,
    queryFn: async () => {
      const { data } = await axiosInstance.get<EnegateGeolocResType>(`${API_PATH.ENEGATE_GEOLOC}?qr=${qr}`, {
        headers: {
          Authorization: `Bearer ${getApikey()}`,
        },
      });
      return data;
    },
    queryKey: [API_PATH.ENEGATE_GEOLOC, qr],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
  return { enegateGeolocList: data, status };
};
