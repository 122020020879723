import { Checkbox, Group, Paper, Text } from '@mantine/core';
import type { FC } from 'react';
import { COLOR } from 'src/constants';

export const ConfirmCheckYes: FC = () => {
  return (
    <Paper py={14} pl={16} pr={11} radius="md">
      <Group position="apart" align="center">
        <Text size={15} sx={{ fontWeight: 500 }}>
          はい
        </Text>
        <Checkbox
          size="lg"
          checked
          styles={() => ({
            input: {
              '&:checked': {
                backgroundColor: COLOR.MAIN,
                border: 'none',
              },
              backgroundColor: COLOR.LIGHT_GRAY,
              border: `solid 1px ${COLOR.DARK_GRAY}`,
            },
            root: {
              alignItems: 'center',
              display: 'flex',
            },
          })}
        />
      </Group>
    </Paper>
  );
};
