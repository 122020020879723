import { Grid } from '@mantine/core';
import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { NewsListCard } from 'src/pages/news/components/NewsListCard';
import { useMessageList } from 'src/pages/news/hooks/useMessageList';

export const TopNewsList: FC = memo(() => {
  const { messageList, onClickNewsListItem } = useMessageList({ activePage: 1 });

  const onClickNews = useCallback(
    (id: string) => onClickNewsListItem(id, messageList),
    [messageList, onClickNewsListItem],
  );

  return (
    <>
      {messageList?.list?.slice(0, 3).map((message) => (
        <Grid.Col key={message.id} span={12} pb={4}>
          <NewsListCard subject={message.subject} createdAt={message.createdAt} id={message.id} onClick={onClickNews} />
        </Grid.Col>
      ))}
    </>
  );
});
TopNewsList.displayName = 'TopNewsList';
