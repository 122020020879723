import type { FC } from 'react';
import { memo } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { ErrorModal } from 'src/components/modal/ErrorModal';

type HasPassedOneYearModalPropsType = {
  isOpen: boolean;
  onClick: VoidFunction;
};

export const HasPassedOneYearModal: FC<HasPassedOneYearModalPropsType> = memo(({ isOpen, onClick }) => {
  return (
    <ErrorModal isOpen={isOpen} onClose={() => ({})} buttonElement={<MainButton onClick={onClick}>OK</MainButton>}>
      V2AEONMALLでは、年に1度登録情報の更新が必要です。{'\n'}
      {'\n'}登録情報の変更有無を問わず、アカウント設定画面から内容を確認し、「更新する」をタップしてください。
    </ErrorModal>
  );
});
HasPassedOneYearModal.displayName = 'HasPassedOneYearModal';
