import { Box, Button, Card, Center, Grid, Image, Paper, Text } from '@mantine/core';
import { useDisclosure, useWindowScroll } from '@mantine/hooks';
import type { FC } from 'react';
import { Suspense, useCallback, useEffect, useMemo } from 'react';
import { Link, NavigationType, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { MainButton } from 'src/components/button/MainButton';
import { MyTotalPointAndEid } from 'src/components/dataDisplay/myTotalPoint/MyTotalPointAndEid';
import { AllMenuIcon } from 'src/components/icon/AllMenuIcon';
import { MemoIcon } from 'src/components/icon/MemoIcon';
import { MenuIcon } from 'src/components/icon/MenuIcon';
import { PointHistoryLgIcon } from 'src/components/icon/PointHistoryLgIcon';
import { RightArrowIcon } from 'src/components/icon/RightArrowIcon';
import { StandIcon } from 'src/components/icon/StandIcon';
import { MainLoader } from 'src/components/loader/MainLoader';
import { ErrorModal } from 'src/components/modal/ErrorModal';
import { COLOR } from 'src/constants';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { useEnegateDischargingStatus } from 'src/pages/discharge/hooks/useEnegateDischargingStatus';
import { useEnegateHistory } from 'src/pages/dischargeHistory/hooks/useEnegateHistory';
import { Balloon } from 'src/pages/top/components/Balloon';
import { BottomSheetMenu } from 'src/pages/top/components/bottomSheetMenu/BottomSheetMenu';
import { HasPassedOneYearModal } from 'src/pages/top/components/HasPassedOneYearModal';
import { HowToUseFinishModal } from 'src/pages/top/components/howToUse/HowToUseFinishModal';
import { HowToUseModal } from 'src/pages/top/components/howToUse/HowToUseModal';
import { HowToUseStep } from 'src/pages/top/components/howToUse/HowToUseStep';
import { NoWaonNoModal } from 'src/pages/top/components/NoWaonNoModal';
import { TopMainButton } from 'src/pages/top/components/TopMainButton';
import { TopNewsList } from 'src/pages/top/components/TopNewsList';
import { useLogin } from 'src/pages/top/hooks/useLogin';
import { URL_PATH } from 'src/router/Router';
import { STAND_STATUS } from 'src/types';
import {
  getIsCheckedDischargeError,
  hasPassed12hour,
  hasPassedOneYear,
  isErrorDischargingStandStatus,
  setIsCheckedDischargeError,
} from 'src/utils';

export const Top: FC = () => {
  useLogin();
  const { userInfo } = useUserInfo();

  const [isOpenHasPassedOneYearModal, handleHasPassedOneYearModal] = useDisclosure(false);

  useEffect(() => {
    if (hasPassedOneYear(userInfo?.note3)) {
      handleHasPassedOneYearModal.open();
    }
  }, [handleHasPassedOneYearModal, userInfo]);

  const { state } = useLocation();
  const isFromHowToUsePage = !!state?.isShowHowToUse;

  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const [, scrollTo] = useWindowScroll();

  const [isOpenBottomMenu, bottomMenuHandlers] = useDisclosure(false);
  const [isOpenHowToUseModal, handleHowToUseModal] = useDisclosure(false);
  const [isOpenHowToUseFinishModal, handleHowToUseFinishModal] = useDisclosure(false);
  const [isStartHowToUseStep, handleHowToUseStep] = useDisclosure(false);
  const [isOpenDischargingErrorModal, handleOpenDischargingErrorModal] = useDisclosure(false);
  const [isOpenNoWaonNoModal, handleOpenNoWaonNoModal] = useDisclosure(false);

  // NOTE メニューの使い方から遷移して来た時は使い方モーダルを表示
  useEffect(() => {
    if (navigationType === NavigationType.Push && isFromHowToUsePage) handleHowToUseModal.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NOTE 初めてアプリを開いたユーザーには使い方モーダルを表示
  useEffect(() => {
    if (!userInfo) return;
    if (!userInfo.note3) handleHowToUseModal.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  // 放電中のデータがあるかどうか取得し処理する
  const { disChargingStatusList, isLoadingDisChargingStatus } = useEnegateDischargingStatus({ isDisableCache: false });
  useEffect(() => {
    if (!disChargingStatusList?.list) return;
    const { list } = disChargingStatusList;
    // エラー確認済みの場合はエラー表示しない
    if (getIsCheckedDischargeError()) return;
    // enegate-statusの実行結果がlist１件の時以外は認証中ではないのでエラー表示はしない
    if (list.length !== 1) return;
    // 故障ステータスの場合はエラーメッセージを表示
    if (isErrorDischargingStandStatus(list[0].standStatus)) handleOpenDischargingErrorModal.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disChargingStatusList]);
  const dischargeStatus = useMemo(() => {
    // enegate-statusの実行結果がlist１件の時以外は認証中ではないので放電中のものはない
    if (!disChargingStatusList?.list || disChargingStatusList.list.length !== 1) return STAND_STATUS.AVAILABLE;
    switch (disChargingStatusList.list[0].standStatus) {
      case STAND_STATUS.DISCHARGING:
        return STAND_STATUS.DISCHARGING;
      case STAND_STATUS.END:
        return STAND_STATUS.END;
      default:
        return STAND_STATUS.AVAILABLE;
    }
  }, [disChargingStatusList?.list]);

  // 12時間以内の最新の放電でエラーがあった場合はモーダルを表示しユーザーに知らせる
  const { enegateHistory } = useEnegateHistory({
    isDisableCache: true,
    isEnable: !getIsCheckedDischargeError(),
    isSuspense: false,
  }); // エラー確認済みの場合は問い合わせしない
  useEffect(() => {
    if (!enegateHistory?.list || enegateHistory.list.length === 0) return;
    const { list } = enegateHistory;
    if (hasPassed12hour(list[0].updatedAt) || getIsCheckedDischargeError()) return;
    if (list[0].standStatus === STAND_STATUS.OUT_OF_ORDER) {
      handleOpenDischargingErrorModal.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enegateHistory]);

  const onClickTopMainButton = () => {
    if (!userInfo) return;
    if (!userInfo.waon) {
      handleOpenNoWaonNoModal.open();
      return;
    }
    switch (dischargeStatus) {
      case STAND_STATUS.DISCHARGING:
        navigate(URL_PATH.DISCHARGING);
        break;
      case STAND_STATUS.END:
        navigate(URL_PATH.DISCHARGE_FINISH);
        break;
      default:
        navigate(URL_PATH.DISCHARGE_SCAN_QR);
    }
  };

  const onClickToCardCardRegistration = useCallback(() => navigate(URL_PATH.CARD_REGISTRATION), [navigate]);
  const onCloseNoWaonNoModal = useCallback(() => handleOpenNoWaonNoModal.close(), [handleOpenNoWaonNoModal]);

  const onClickCheckHowToUse = useCallback(() => {
    handleHowToUseModal.close();
    handleHowToUseStep.open();
  }, [handleHowToUseModal, handleHowToUseStep]);

  const onCloseHowToUseModal = useCallback(() => {
    if (!userInfo?.note3) navigate(URL_PATH.ACCOUNT_SETTING, { state: { isInitialFlow: true } });
    if (isFromHowToUsePage) {
      navigate(URL_PATH.HOW_TO_USE_TOP);
    } else {
      handleHowToUseModal.close();
    }
  }, [handleHowToUseModal, isFromHowToUsePage, navigate, userInfo?.note3]);

  const onCloseHowToUseFinishModal = useCallback(() => {
    if (!userInfo?.note3) navigate(URL_PATH.ACCOUNT_SETTING, { state: { isInitialFlow: true } });
    if (isFromHowToUsePage) {
      navigate(URL_PATH.HOW_TO_USE_TOP);
    } else {
      handleHowToUseFinishModal.close();
      scrollTo({ y: 0 });
    }
  }, [handleHowToUseFinishModal, isFromHowToUsePage, navigate, scrollTo, userInfo?.note3]);

  const onFinishHowToUse = useCallback(() => handleHowToUseFinishModal.open(), [handleHowToUseFinishModal]);

  const onCloseDischargingErrorModal = useCallback(() => {
    setIsCheckedDischargeError(true);
    handleOpenDischargingErrorModal.close();
  }, [handleOpenDischargingErrorModal]);

  return (
    <Box>
      <Box component="section" sx={{ backgroundColor: COLOR.LIGHT_BLUE }}>
        <Grid justify={'center'}>
          <Grid.Col span={12} mt={40}>
            <MyTotalPointAndEid />
          </Grid.Col>
          <Grid.Col span={12} pt={16}>
            <Center>
              <Balloon dischargeStatus={dischargeStatus} />
            </Center>
          </Grid.Col>
          <Grid.Col span={7} className="step-2">
            <TopMainButton
              dischargeStatus={dischargeStatus}
              isLoading={isLoadingDisChargingStatus}
              onClick={onClickTopMainButton}
            />
          </Grid.Col>
          <Grid.Col span={12} mt={-40}>
            <Image src={`${process.env.PUBLIC_URL}/image/car.png`} width="100%" />
          </Grid.Col>
        </Grid>
      </Box>
      <Box component="section" pt={40} px={16}>
        <Grid justify={'center'} align="stretch">
          <Grid.Col span={12}>
            <Card
              sx={{ textAlign: 'center' }}
              radius="xl"
              component={Link}
              to={URL_PATH.POINT_HISTORY}
              className="step-3"
            >
              <PointHistoryLgIcon />
              <Text size={21} sx={{ fontWeight: 'bold', letterSpacing: '3px' }}>
                ポイント履歴
              </Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={6}>
            <Card
              sx={{ textAlign: 'center' }}
              radius="xl"
              component={Link}
              to={URL_PATH.STAND_USAGE_STATUS}
              state={{ isDischarging: disChargingStatusList?.list.length === 1 }}
              className="step-4"
            >
              <StandIcon />
              <Text
                size={18}
                sx={{ fontWeight: 'bold', letterSpacing: '3px', lineHeight: '23px', whiteSpace: 'pre-wrap' }}
              >
                スタンド{`\n`}利用状況
              </Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={6}>
            <Card
              sx={{ height: '100%', textAlign: 'center' }}
              radius="xl"
              component={Link}
              to={URL_PATH.DISCHARGE_HISTORY}
              className="step-5"
            >
              <Box pt={10} pl={4}>
                <MemoIcon />
                <Text size={18} sx={{ fontWeight: 'bold', letterSpacing: '3px' }} mt={12}>
                  放電きろく
                </Text>
              </Box>
            </Card>
          </Grid.Col>
          <Grid.Col span={9} pt={20}>
            <Center>
              <MainButton
                rightIcon={<AllMenuIcon />}
                backgroundColor={COLOR.BLACK}
                size={16}
                fontWeight="bold"
                onClick={() => bottomMenuHandlers.open()}
              >
                すべてのメニュー
              </MainButton>
            </Center>
          </Grid.Col>
          <Grid.Col span={12} py={60}>
            <Paper radius="xl" shadow="md" pb={4} component={Link} to={URL_PATH.HOW_TO_USE_TOP}>
              <Box sx={{ position: 'relative' }} pt={16}>
                <Image src={`${process.env.PUBLIC_URL}/image/light-green-car.png`} height="96px" width="148px" />
                {/* TODO 正式なロゴに変更 */}
                <Text size={30} ml={-100} sx={{ color: '#0A6934', left: '50%', position: 'absolute', top: '18px' }}>
                  V2 AEONMALL
                </Text>
                <Text
                  size={21}
                  ml={-100}
                  sx={{
                    color: COLOR.BLACK,
                    fontWeight: 'bold',
                    left: '50%',
                    letterSpacing: '2px',
                    position: 'absolute',
                    top: '60px',
                  }}
                >
                  ってなあに？
                </Text>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={-30}>
                <Button rightIcon={<RightArrowIcon />} variant="subtle" sx={{ color: COLOR.MAIN, fontSize: '13px' }}>
                  使い方はこちら
                </Button>
              </Box>
            </Paper>
          </Grid.Col>
        </Grid>
      </Box>
      <Box component="section" py={60} px={20} sx={{ backgroundColor: COLOR.BG_GRAY }}>
        <Grid justify={'center'} align="stretch">
          <Grid.Col span={12}>
            <Center>
              <Text size={24} sx={{ fontWeight: 'bold', letterSpacing: '4px' }}>
                お知らせ
              </Text>
            </Center>
          </Grid.Col>
          <Suspense
            fallback={
              <Grid.Col span={12}>
                <Center py={160}>
                  <MainLoader />
                </Center>
              </Grid.Col>
            }
          >
            <TopNewsList />
          </Suspense>
          <Grid.Col span={9} pt={20}>
            <Center>
              <MainButton
                rightIcon={<MenuIcon />}
                backgroundColor={COLOR.BLACK}
                isLink
                to={URL_PATH.NEWS}
                size={16}
                fontWeight="bold"
              >
                お知らせ一覧
              </MainButton>
            </Center>
          </Grid.Col>
        </Grid>
      </Box>
      <Box component="section" pt={100}>
        <Box
          pt={60}
          sx={{
            backgroundColor: COLOR.WHITE,
            borderTopLeftRadius: '30px',
            borderTopRightRadius: '30px',
            height: '240px',
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
          }}
        >
          <Text size={15} sx={{ color: '#656363' }}>
            イオングループでは、{`\n`}脱炭素社会実現への貢献を掲げております。
          </Text>
          <Center pt={40}>
            <Image src={`${process.env.PUBLIC_URL}/image/aeon.png`} width="100px" height="30px" />
          </Center>
          <Center pt={28}>
            <Text size={14} sx={{ color: COLOR.MEDIUM_GRAY, fontWeight: 500 }}>
              {`バージョン ${process.env.REACT_APP_VERSION}${
                process.env.REACT_APP_ENV === 'staging' ? '(staging)' : ''
              }`}
            </Text>
          </Center>
        </Box>
      </Box>
      <BottomSheetMenu
        isOpen={isOpenBottomMenu}
        isDischarging={disChargingStatusList?.list.length === 1}
        onClose={() => bottomMenuHandlers.close()}
      />
      <HowToUseModal
        isOpen={isOpenHowToUseModal}
        isFromHowToUsePage={isFromHowToUsePage}
        onClose={onCloseHowToUseModal}
        onClick={onClickCheckHowToUse}
      />
      <HowToUseFinishModal
        isOpen={isOpenHowToUseFinishModal}
        isFromHowToUsePage={isFromHowToUsePage}
        onClose={onCloseHowToUseFinishModal}
      />
      <HowToUseStep isStart={isStartHowToUseStep} onFinish={onFinishHowToUse} />
      <HasPassedOneYearModal isOpen={isOpenHasPassedOneYearModal} onClick={() => navigate(URL_PATH.ACCOUNT_SETTING)} />
      <NoWaonNoModal
        isOpen={isOpenNoWaonNoModal}
        onClick={onClickToCardCardRegistration}
        onClose={onCloseNoWaonNoModal}
      />
      <ErrorModal
        isOpen={isOpenDischargingErrorModal}
        onClose={onCloseDischargingErrorModal}
        buttonElement={<MainButton onClick={onCloseDischargingErrorModal}>閉じる</MainButton>}
      >
        放電処理中に問題が発生しました。{'\n'}
        スタンドに戻り放電コネクタを外し、放電を終了してください。お車の移動をお願い致します。
        <Text size={14} mt={8} color={COLOR.RED}>
          ※エラー詳細を確認後、放電時間をもとにポイントを進呈致します。後日ポイント履歴よりご確認ください。{'\n'}
          ※放電時間が不足する場合はポイントの進呈がない場合もございます。
        </Text>
      </ErrorModal>
    </Box>
  );
};
