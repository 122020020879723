import { Box } from '@mantine/core';
import type { FC, ReactNode } from 'react';
import { SIZE } from 'src/constants';

type BaseLayoutPropsType = {
  children: ReactNode;
};

export const BaseLayout: FC<BaseLayoutPropsType> = ({ children }) => {
  return (
    <Box sx={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: SIZE.SCREEN_MAX, paddingTop: SIZE.HEADER_HEIGHT }}>
      {children}
    </Box>
  );
};
