import type { FC } from 'react';
import { Suspense } from 'react';
import { MainLoadingOverlay } from 'src/components/loader/MainLoadingOverlay';
import { DischargeScanQr } from 'src/pages/discharge/components/DischargeScanQr';

export const DischargeScanQrContainer: FC = () => {
  return (
    <Suspense fallback={<MainLoadingOverlay />}>
      <DischargeScanQr />
    </Suspense>
  );
};
