import { Box, Center, Group, Image, Text } from '@mantine/core';
import type { FC } from 'react';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyTotalPoint } from 'src/components/dataDisplay/myTotalPoint/MyTotalPoint';
import { WaonPointVertical } from 'src/components/icon/WaonPointVertical';
import { MainLoader } from 'src/components/loader/MainLoader';
import { LinkText } from 'src/components/text/LinkText';
import { URL_PATH } from 'src/router/Router';
import { getEid } from 'src/utils';

export const MyTotalPointAndEid: FC = () => {
  const eid = getEid();
  const navigate = useNavigate();
  const onClickToPointHistory = () => navigate(URL_PATH.POINT_HISTORY);

  return (
    <Box>
      <Center>
        <Text size={17} sx={{ fontWeight: 'bold', letterSpacing: '2px', whiteSpace: 'pre-wrap' }}>
          来月進呈予定のWAON POINT
        </Text>
      </Center>
      <Center pt={20}>
        <Group className="step-1" position="center" align="center">
          <Box>
            <Image src={`${process.env.PUBLIC_URL}/image/waon-point.png`} width="50px" />
          </Box>
          <Suspense
            fallback={
              <Box px={16} py={8}>
                <MainLoader />
              </Box>
            }
          >
            <MyTotalPoint />
          </Suspense>
          <Box mt={8}>
            <WaonPointVertical />
          </Box>
        </Group>
      </Center>
      <Center>
        <LinkText size={13} onClick={onClickToPointHistory}>
          詳しくはこちら
        </LinkText>
      </Center>
      <Center>
        <LinkText size={13} onClick={onClickToPointHistory}>
          （※進呈時期・ポイント数を確認）
        </LinkText>
      </Center>
      <Center pt={20}>
        <Text size={13} sx={{ fontWeight: 500 }}>
          あなたのeid : {eid}
        </Text>
      </Center>
    </Box>
  );
};
