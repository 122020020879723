import { Box, Group, Paper, Text } from '@mantine/core';
import type { FC } from 'react';
import { COLOR } from 'src/constants';
import type { PointTypeType } from 'src/types';
import { POINT_TYPE } from 'src/types';

type PointHistoryCardPropsType = {
  balance: number | undefined;
  datetime: string;
  point: number;
  type: PointTypeType;
};

export const PointHistoryCard: FC<PointHistoryCardPropsType> = ({ balance, datetime, point, type }) => {
  return (
    <Box>
      <Group position="apart" pb={7}>
        <Text size={13} color={COLOR.BLACK} sx={{ fontWeight: 400 }}>
          {datetime}
        </Text>
        {balance !== undefined && (
          <Text size={13} color={COLOR.BLACK} sx={{ fontWeight: 400 }}>
            合計{balance.toLocaleString()}
          </Text>
        )}
      </Group>
      <Paper radius="lg" px={16} pt={23} pb={21}>
        <Group spacing={0} sx={{ flexWrap: 'nowrap' }}>
          {point > 0 ? (
            <>
              <Text color={COLOR.MAIN_GREEN} sx={{ fontWeight: 900 }}>
                +
              </Text>
              <Text color={COLOR.MAIN_GREEN} size={24} sx={{ fontWeight: 'bold' }}>
                {point.toLocaleString()}
              </Text>
            </>
          ) : (
            <Text color={COLOR.MAIN} size={24} sx={{ fontWeight: 'bold' }}>
              {point.toLocaleString()}
            </Text>
          )}
          <Text size={11} mt={8} sx={{ fontWeight: 'bold' }}>
            pt
          </Text>
          <Text size={15} ml={21} sx={{ fontWeight: 'bold' }}>
            {type === POINT_TYPE.SCHEDULED_WAON_POINT
              ? '来月進呈予定のWAON POINT'
              : type === POINT_TYPE.GIVING_POINT
              ? 'ポイント進呈準備（21日以降利用可能）'
              : type === POINT_TYPE.ERROR
              ? 'ポイント返却（進呈処理エラー）'
              : ''}
          </Text>
        </Group>
      </Paper>
    </Box>
  );
};
