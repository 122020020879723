/* eslint-disable sort-keys-fix/sort-keys-fix */
export const STAND_STATUS = {
  AVAILABLE: '0', // 給電可能
  IN_PREPARATION: '3', // 利用準備中
  INACTIVE: '4', // 休止中
  OUT_OF_ORDER: '5', // 故障中
  DISCHARGING: '6', // 放電中
  END: '10', // 放電終了
} as const;

export type StandStatusType = typeof STAND_STATUS[keyof typeof STAND_STATUS];
