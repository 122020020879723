import { Box, Center, Stack, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { COLOR } from 'src/constants';
import { DischargeHistoryCard } from 'src/pages/dischargeHistory/components/DischargeHistoryCard';
import { useEnegateHistory } from 'src/pages/dischargeHistory/hooks/useEnegateHistory';
import {
  formatYYYYMDjaText,
  formatYYYYMMDD,
  getStoreStandNameByInstallationId,
  isShowDischargeHistory,
} from 'src/utils';

export const DischargeHistoryList: FC = memo(() => {
  const { enegateHistory } = useEnegateHistory({});

  // 同じ日付の場合は日付表示を省略するためstartedAtを空に
  const formattedEnegateHistory = useMemo(() => {
    let beforeDate = '';
    const filteredEnegateHistory = enegateHistory?.list.filter((history) =>
      isShowDischargeHistory(history.standStatus),
    );
    return filteredEnegateHistory?.map((history) => {
      if (beforeDate === formatYYYYMMDD(history.startedAt)) {
        history.startedAt = '';
      }
      beforeDate = formatYYYYMMDD(history.startedAt);
      return history;
    });
  }, [enegateHistory?.list]);

  return (
    <Box>
      <Stack spacing={6}>
        {formattedEnegateHistory?.length === 0 && (
          <Center>
            <Text size={16} sx={{ fontWeight: 500 }}>
              放電きろくはまだありません。
            </Text>
          </Center>
        )}
        {formattedEnegateHistory?.map((history) => (
          <Box key={`${history.startedAt}${history.updatedAt}`}>
            {history.startedAt !== '' && (
              <Text size={15} color={COLOR.BLACK} ml={16} mb={5} mt={9} sx={{ fontWeight: 500 }}>
                {formatYYYYMDjaText(history.startedAt)}
              </Text>
            )}
            <DischargeHistoryCard
              kwh={history.amount}
              time={history.utilityTime}
              standName={getStoreStandNameByInstallationId(history.installationId, history.standNo)}
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
});
DischargeHistoryList.displayName = 'DischargeHistoryList';
