import { Center, Stack, Text } from '@mantine/core';
import type { FC } from 'react';
import { PointHistoryCard } from 'src/pages/pointExchange/components/PointHistoryCard';
import { usePointList } from 'src/pages/pointExchange/hooks/usePointList';

export const PointHistoryList: FC = () => {
  const { pointList } = usePointList();
  return (
    <Stack spacing={12}>
      {pointList?.list.length === 0 && (
        <Center>
          <Text size={16} sx={{ fontWeight: 500 }}>
            ポイント履歴はまだありません。
          </Text>
        </Center>
      )}
      {pointList?.list.map((pointHistory, index) => (
        <PointHistoryCard
          key={pointHistory.createdAt}
          datetime={pointHistory.createdAt}
          point={pointHistory.point}
          balance={index === 0 ? pointList.balance : undefined}
          type={pointHistory.type}
        />
      ))}
    </Stack>
  );
};
