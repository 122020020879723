import { Select } from '@mantine/core';
import type { FC } from 'react';
import { COLOR } from 'src/constants';

export const WaonNoSelect: FC = ({ ...props }) => {
  return (
    <Select
      {...props}
      data={[
        { label: '1000', value: '1000' },
        { label: '6900', value: '6900' },
      ]}
      size="sm"
      required
      styles={() => ({
        input: {
          border: `solid 1px ${COLOR.DARK_GRAY}`,
          padding: '2px 4px',
        },
        root: {
          width: '70px',
        },
      })}
    />
  );
};
