import { Center } from '@mantine/core';
import { Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { HeaderLayout } from 'src/components/layout/HeaderLayout';
import { MainLoader } from 'src/components/loader/MainLoader';
import { ScrollToTop } from 'src/components/scroll/ScrollToTop';
import { Account } from 'src/pages/account/Account';
import { AccountSetting } from 'src/pages/account/AccountSetting';
import { AccountSettingConfirm } from 'src/pages/account/AccountSettingConfirm';
import { Contact } from 'src/pages/contact/Contact';
import { DeleteAccount } from 'src/pages/deleteAccount/DeleteAccount';
import { DischargeFinish } from 'src/pages/discharge/DischargeFinish';
import { DischargeScanQrContainer } from 'src/pages/discharge/DischargeScanQrContainer';
import { Discharging } from 'src/pages/discharge/Discharging';
import { SelectDischargeTime } from 'src/pages/discharge/SelectDischargeTime';
import { DischargeHistory } from 'src/pages/dischargeHistory/DischargeHistory';
import { RootErrorBoundary } from 'src/pages/error/RootErrorBoundary';
import { HowToUseTop } from 'src/pages/howToUse/HowToUseTop';
import { News } from 'src/pages/news/News';
import { NewsArticle } from 'src/pages/news/NewsArticle';
import { CardRegistration } from 'src/pages/pointExchange/CardRegistration';
import { PointHistory } from 'src/pages/pointExchange/PointHistory';
import { StandUsageStatus } from 'src/pages/standUsageStatus/StandUsageStatus';
import { Top } from 'src/pages/top/Top';

export const URL_PATH = {
  ACCOUNT: '/account',
  ACCOUNT_SETTING: '/account-setting',
  ACCOUNT_SETTING_CONFIRM: '/account-setting-confirm',
  CARD_REGISTRATION: '/card-registration',
  CONTACT: '/contact',
  DELETE_ACCOUNT: '/delete-account',
  DISCHARGE_FINISH: '/discharge-finish',
  DISCHARGE_HISTORY: '/discharge-history',
  DISCHARGE_SCAN_QR: '/discharge-scan-qr',
  DISCHARGING: '/discharging',
  HOW_TO_USE_TOP: '/how-to-use-top',
  NEWS: '/news',
  NEWS_ARTICLE: '/news-article',
  POINT_HISTORY: '/point-history',
  SELECT_DISCHARGE_TIME: '/select_discharge_time',
  STAND_USAGE_STATUS: '/stand-usage-status',
  TOP: '/',
} as const;

export const router = createBrowserRouter(
  [
    {
      children: [
        {
          element: (
            <Suspense
              fallback={
                <Center pt={200}>
                  <MainLoader />
                </Center>
              }
            >
              <Top />
            </Suspense>
          ),
          index: true,
        },
        {
          element: <Account />,
          path: URL_PATH.ACCOUNT,
        },
        {
          element: <AccountSetting />,
          path: URL_PATH.ACCOUNT_SETTING,
        },
        {
          element: <AccountSettingConfirm />,
          path: URL_PATH.ACCOUNT_SETTING_CONFIRM,
        },
        {
          element: <Contact />,
          path: URL_PATH.CONTACT,
        },
        {
          element: <PointHistory />,
          path: URL_PATH.POINT_HISTORY,
        },
        {
          element: <CardRegistration />,
          path: `${URL_PATH.CARD_REGISTRATION}`,
        },
        {
          element: <StandUsageStatus />,
          path: URL_PATH.STAND_USAGE_STATUS,
        },
        {
          element: <DischargeHistory />,
          path: URL_PATH.DISCHARGE_HISTORY,
        },
        {
          element: <DischargeFinish />,
          path: URL_PATH.DISCHARGE_FINISH,
        },
        {
          element: <DischargeScanQrContainer />,
          path: URL_PATH.DISCHARGE_SCAN_QR,
        },
        {
          element: <Discharging />,
          path: URL_PATH.DISCHARGING,
        },
        {
          element: <SelectDischargeTime />,
          path: URL_PATH.SELECT_DISCHARGE_TIME,
        },
        {
          element: <HowToUseTop />,
          path: URL_PATH.HOW_TO_USE_TOP,
        },
        {
          element: <News />,
          path: URL_PATH.NEWS,
        },
        {
          element: <NewsArticle />,
          path: URL_PATH.NEWS_ARTICLE,
        },
        {
          element: <DeleteAccount />,
          path: URL_PATH.DELETE_ACCOUNT,
        },
      ],
      element: (
        <>
          <ScrollToTop />
          <HeaderLayout />
        </>
      ),
      errorElement: <RootErrorBoundary />,
      path: URL_PATH.TOP,
    },
  ],
  { basename: '/vpss/miniapp/webview/entry' },
);
