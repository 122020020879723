import type { SVGProps } from 'react';

export const PinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17.294} height={21} {...props}>
    <path
      data-name="\u30D1\u30B9 438"
      d="M8.647 0A8.648 8.648 0 0 0 0 8.647C0 13.422 6.8 21 8.647 21s8.647-7.578 8.647-12.353A8.648 8.648 0 0 0 8.647 0Zm0 12.616a3.969 3.969 0 1 1 3.969-3.969 3.969 3.969 0 0 1-3.969 3.969Z"
      fill="#b60081"
    />
  </svg>
);
