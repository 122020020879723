import { Button, Text } from '@mantine/core';
import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { COLOR } from 'src/constants';
import type { URL_PATH } from 'src/router/Router';

type CommonPropsType = {
  backgroundColor?: 'string' | typeof COLOR[keyof typeof COLOR];
  children: ReactNode;
  color?: 'string' | typeof COLOR[keyof typeof COLOR];
  fontWeight?: number | 'bold';
  isDisabled?: boolean;
  isLoading?: boolean;
  rightIcon?: ReactNode;
  size?: number;
  type?: 'submit' | 'button';
};

// NOTE リンクとして使用する場合はリンク先を指定、そうでない場合はonClickイベントを設定
type MainButtonPropsType = CommonPropsType &
  (
    | {
        onClick?: VoidFunction;
      }
    | {
        isLink: boolean;
        to: typeof URL_PATH[keyof typeof URL_PATH];
      }
  );

export const MainButton: FC<MainButtonPropsType> = (props) => {
  const {
    backgroundColor = COLOR.MAIN,
    children,
    color = COLOR.WHITE,
    size = 21,
    fontWeight = 500,
    rightIcon,
    type = 'button',
    isLoading = false,
    isDisabled = false,
  } = props;
  if ('isLink' in props) {
    return (
      <Button
        rightIcon={rightIcon}
        radius="xl"
        size="xl"
        fullWidth
        component={Link}
        to={props.to}
        type={type}
        disabled={isDisabled}
        loading={isLoading}
        loaderPosition="center"
        sx={() => ({
          '&:hover': {
            backgroundColor,
          },
          backgroundColor,
          color,
          fontWeight,
          zIndex: 1,
        })}
      >
        <Text size={size}>{children}</Text>
      </Button>
    );
  } else {
    return (
      <Button
        rightIcon={rightIcon}
        radius="xl"
        size="xl"
        fullWidth
        onClick={props.onClick}
        type={type}
        disabled={isDisabled}
        loading={isLoading}
        loaderPosition="center"
        sx={() => ({
          '&:hover': {
            backgroundColor,
          },
          backgroundColor,
          color,
          fontWeight,
          zIndex: 1,
        })}
      >
        <Text size={size}>{children}</Text>
      </Button>
    );
  }
};
