import { useQuery } from '@tanstack/react-query';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { EnegateStatusResType } from 'src/types';
import { getApikey } from 'src/utils';

export const useEnegateDischargingStatus = ({
  isDisableCache,
  isEnable = true,
  refetchInterval = 5 * 60 * 1000, // デフォルト5分間隔でrefetch
}: {
  isDisableCache: boolean;
  isEnable?: boolean;
  refetchInterval?: number;
}) => {
  const { data, dataUpdatedAt, isLoading } = useQuery<EnegateStatusResType>({
    cacheTime: isDisableCache ? 0 : 5 * 60 * 1000, // 放電中画面ではキャッシュを使用しない
    enabled: !!getApikey() && isEnable,
    queryFn: async () => {
      const { data } = await axiosInstance.get<EnegateStatusResType>(API_PATH.ENEGATE_STATUS, {
        headers: {
          Authorization: `Bearer ${getApikey()}`,
        },
      });
      return data;
    },
    queryKey: [API_PATH.ENEGATE_STATUS, isDisableCache],
    refetchInterval,
    suspense: false,
  });
  return { dataUpdatedAt, disChargingStatusList: data, isLoadingDisChargingStatus: isLoading };
};
