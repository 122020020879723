import { Box, Center, CloseButton, createStyles, Grid, Stack, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { AccountIcon } from 'src/components/icon/AccountIcon';
import { ContactIcon } from 'src/components/icon/ContactIcon';
import { HowToUseIcon } from 'src/components/icon/HowToUseIcon';
import { MemoSmIcon } from 'src/components/icon/MemoSmIcon';
import { NewsIcon } from 'src/components/icon/NewsIcon';
import { PointHistoryIcon } from 'src/components/icon/PointHistoryIcon';
import { QaIcon } from 'src/components/icon/QaIcon';
import { StandSmIcon } from 'src/components/icon/StandSmIcon';
import { COLOR, SIZE, URL } from 'src/constants';
import { BottomSheetMenuIcon } from 'src/pages/top/components/bottomSheetMenu/BottomSheetMenuIcon';
import { BottomSheetMenuText } from 'src/pages/top/components/bottomSheetMenu/BottomSheetMenuText';
import { URL_PATH } from 'src/router/Router';

type BottomSheetMenuPropsType = {
  isDischarging: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
};

const useStyles = createStyles({
  wrapper: {
    '--rsbs-bg': COLOR.LIGHT_GRAY,
  },
});

export const BottomSheetMenu: FC<BottomSheetMenuPropsType> = memo((props) => {
  const { isDischarging, isOpen, onClose } = props;
  const { classes } = useStyles();

  return (
    <BottomSheet open={isOpen} onDismiss={onClose} className={classes.wrapper} initialFocusRef={false}>
      <Box sx={{ maxWidth: SIZE.SCREEN_MAX }} px={16} mx="auto" pb={53}>
        <Grid justify={'flex-start'} align={'center'}>
          <Grid.Col span={6}>
            <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '4px' }}>
              メニュー
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <CloseButton
              ml="auto"
              size={'xl'}
              radius="xl"
              onClick={onClose}
              sx={{ backgroundColor: '#8D8D8D', color: COLOR.WHITE }}
            />
          </Grid.Col>
          <Grid.Col span={4} sx={{ textAlign: 'center' }}>
            <Stack spacing={6}>
              <BottomSheetMenuIcon to={URL_PATH.STAND_USAGE_STATUS} state={{ isDischarging }}>
                <Center pt={28} pl={8}>
                  <StandSmIcon />
                </Center>
              </BottomSheetMenuIcon>
              <BottomSheetMenuText>スタンド状況</BottomSheetMenuText>
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} sx={{ textAlign: 'center' }}>
            <Stack spacing={6}>
              <BottomSheetMenuIcon to={URL_PATH.DISCHARGE_HISTORY}>
                <Center pt={28} pl={8}>
                  <MemoSmIcon />
                </Center>
              </BottomSheetMenuIcon>
              <BottomSheetMenuText>放電きろく</BottomSheetMenuText>
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} sx={{ textAlign: 'center' }}>
            <Stack spacing={6}>
              <BottomSheetMenuIcon to={URL_PATH.NEWS}>
                <Center pt={32} pr={4}>
                  <NewsIcon />
                </Center>
              </BottomSheetMenuIcon>
              <BottomSheetMenuText>お知らせ</BottomSheetMenuText>
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} sx={{ textAlign: 'center' }}>
            <Stack spacing={6}>
              <BottomSheetMenuIcon to={URL_PATH.POINT_HISTORY}>
                <Center pt={24}>
                  <PointHistoryIcon />
                </Center>
              </BottomSheetMenuIcon>
              <BottomSheetMenuText>ポイント履歴</BottomSheetMenuText>
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} sx={{ textAlign: 'center' }}>
            <Stack spacing={6}>
              <BottomSheetMenuIcon to={URL_PATH.HOW_TO_USE_TOP}>
                <Center pt={28} pl={8}>
                  <HowToUseIcon />
                </Center>
              </BottomSheetMenuIcon>
              <BottomSheetMenuText>使い方</BottomSheetMenuText>
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} sx={{ textAlign: 'center' }}>
            <Stack spacing={6}>
              <BottomSheetMenuIcon url={URL.FAQ}>
                <Center pt={28}>
                  <QaIcon />
                </Center>
              </BottomSheetMenuIcon>
              <BottomSheetMenuText>よくある質問</BottomSheetMenuText>
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} sx={{ textAlign: 'center' }}>
            <Stack spacing={6}>
              <BottomSheetMenuIcon to={URL_PATH.ACCOUNT}>
                <Center pt={28}>
                  <AccountIcon />
                </Center>
              </BottomSheetMenuIcon>
              <BottomSheetMenuText>アカウント</BottomSheetMenuText>
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} sx={{ textAlign: 'center' }}>
            <Stack spacing={6}>
              <BottomSheetMenuIcon to={URL_PATH.CONTACT}>
                <Center pt={32}>
                  <ContactIcon />
                </Center>
              </BottomSheetMenuIcon>
              <BottomSheetMenuText>お問い合わせ</BottomSheetMenuText>
            </Stack>
          </Grid.Col>
        </Grid>
      </Box>
    </BottomSheet>
  );
});
BottomSheetMenu.displayName = 'BottomSheetMenu';
