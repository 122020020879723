import { Paper, Stack } from '@mantine/core';
import type { Dispatch, FC, SetStateAction } from 'react';
import { memo, useEffect } from 'react';
import { StandStatusCard } from 'src/pages/standUsageStatus/components/StandStatusCard';
import { useEnegateStandStatus } from 'src/pages/standUsageStatus/hooks/useEnegateStandStatus';
import { formatMDHmjaText } from 'src/utils';

type StandStatusListPropsType = {
  installationId: string;
  setStandStatusUpdatedAt: Dispatch<SetStateAction<string>>;
};

export const StandStatusList: FC<StandStatusListPropsType> = memo(({ installationId, setStandStatusUpdatedAt }) => {
  const { enegateStatusList } = useEnegateStandStatus({ installationId });

  // データが取得できた場合、最初のスタンドのupdatedAtを画面に表示するために設定する（サーバー側でキャッシュしたものが設定されるのでどのスタンドも同じupdatedAtを持つ）
  useEffect(() => {
    if (enegateStatusList?.list && enegateStatusList?.list.length > 0) {
      const formattedDate = formatMDHmjaText(enegateStatusList.list[0].updatedAt);
      setStandStatusUpdatedAt(formattedDate);
    }
  }, [enegateStatusList?.list, setStandStatusUpdatedAt]);

  return (
    <Paper radius="xl" px={16} pt={24} pb={28}>
      <Stack spacing={12} pt={14}>
        {enegateStatusList?.list.map((stand) => (
          <StandStatusCard key={stand.standNo} standNo={stand.standNo} status={stand.standStatus} />
        ))}
      </Stack>
    </Paper>
  );
});
StandStatusList.displayName = 'StandStatusList';
