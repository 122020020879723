import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

// そのままではreact-routerのErrorBoundaryの機構(RootErrorBoundary.tsx)にエラーステータスが渡らないため
// ハンドリングが必要なステータスコードは明示的に扱う
axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    switch (error.response?.status) {
      case 401:
        throw new Response('', { status: 401 });
      default:
        return Promise.reject(error);
    }
  },
);
