import { Paper, Stack, Text } from '@mantine/core';
import type { FC } from 'react';
import { COLOR } from 'src/constants';

type NewsListCardPropsType = {
  createdAt: string;
  id: string;
  onClick: (id: string) => void;
  subject: string;
};

export const NewsListCard: FC<NewsListCardPropsType> = ({ createdAt, id, onClick, subject }) => {
  return (
    <Paper radius="lg" px={16} pt={22} pb={17} onClick={() => onClick(id)}>
      <Stack spacing={6}>
        <Text size={15} sx={{ fontWeight: 500 }}>
          {subject}
        </Text>
        <Text size={13} color={COLOR.MAIN} sx={{ fontWeight: 500 }}>
          {createdAt}
        </Text>
      </Stack>
    </Paper>
  );
};
