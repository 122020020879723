import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import { URL_PATH } from 'src/router/Router';
import type { MessageListResType } from 'src/types';
import { getApikey } from 'src/utils';

type UseMessageListPropsType = {
  activePage: number;
};

export const useMessageList = ({ activePage }: UseMessageListPropsType) => {
  const navigate = useNavigate();

  const { data } = useQuery<MessageListResType>({
    enabled: !!getApikey(),
    queryFn: async () => {
      const { data } = await axiosInstance.get<MessageListResType>(
        `${API_PATH.MESSAGE_LIST}?pageSize=7&page=${activePage}`,
        {
          headers: {
            Authorization: `Bearer ${getApikey()}`,
          },
        },
      );
      return data;
    },
    queryKey: [API_PATH.MESSAGE_LIST, activePage],
  });

  const onClickNewsListItem = useCallback(
    (id: string, messageList: MessageListResType | undefined) => {
      const targetNews = messageList?.list.find((news) => news.id === id);
      if (!targetNews) return;
      navigate(URL_PATH.NEWS_ARTICLE, { state: { news: targetNews } });
    },
    [navigate],
  );

  return { messageList: data, onClickNewsListItem };
};
