import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { UserQuitResType } from 'src/types';
import { getApikey } from 'src/utils';

export const useUserQuit = () => {
  const [isErrorResult, setIsErrorResult] = useState<boolean>(false);

  const { isError, isLoading, mutateAsync } = useMutation({
    mutationFn: async () => {
      return await axiosInstance.delete<UserQuitResType>(`${API_PATH.USER_QUIT}`, {
        headers: {
          Authorization: `Bearer ${getApikey()}`,
        },
      });
    },
    onMutate() {
      setIsErrorResult(false);
    },
    onSuccess({ data }) {
      if (data.result === 'ok') {
        setIsErrorResult(false);
      } else {
        setIsErrorResult(true);
      }
    },
  });
  // API自体のエラーと、正常終了時のresult: falseもエラーとして扱う
  return { isError: isError || isErrorResult, isLoading, userQuit: mutateAsync };
};
