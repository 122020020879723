import { Box, Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { Header } from 'src/components/layout/Header';
import { ERROR_NO } from 'src/constants/errorNo';

export const Page401: FC = () => {
  return (
    <Box>
      <Header />
      <Box pt={40} px={32}>
        <Center>
          <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
            認証エラー
          </Text>
        </Center>
        <Center pt={40}>
          <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            認証情報に問題があります。{'\n'}お手数ですがイオンモールアプリより{'\n'}再度アプリを開き直してください。
          </Text>
        </Center>
        <Center pt={16}>
          <Text size={16}>{ERROR_NO.E001}</Text>
        </Center>
      </Box>
    </Box>
  );
};
