import type { SVGProps } from 'react';

export const QaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={59.588} height={37.912} {...props}>
    <g
      data-name="\u304A\u554F\u3044\u5408\u308F\u305B\u306E\u30A2\u30A4\u30B3\u30F3 Q &amp; A\u306E\u5439\u304D\u51FA\u3057 1"
      fill="#76e0c7"
    >
      <path
        data-name="\u30D1\u30B9 344"
        d="M26.046 28.496a16.9 16.9 0 0 0 5.829-4.544 13.854 13.854 0 0 0 2.38-13.325 15.459 15.459 0 0 0-8.473-8.881c-.1-.046-.205-.091-.309-.136q-.462-.2-.936-.372c-.063-.023-.123-.049-.186-.071a19.164 19.164 0 0 0-1.163-.369l-.22-.061A20.26 20.26 0 0 0 8.612 2.056C3.662 4.596 0 9.497 0 15.188c0 5.315 3.187 10.205 8.283 12.953l.4 2.852a3.762 3.762 0 0 0 5.986 2.42l3.862-3.016a19.587 19.587 0 0 0 7.513-1.9Zm.981-4.971a13.385 13.385 0 0 1-1.3.916c-.074.046-.154.084-.229.129a14.519 14.519 0 0 1-1.339.705c-.057.026-.117.047-.175.073a15.469 15.469 0 0 1-1.51.578l-.046.013a16.353 16.353 0 0 1-5.2.751l-3.244 2.533-1.6 1.24q-.348-2.422-.685-4.844a12.559 12.559 0 0 1-7.389-7.115l-.158-.439.158.439A10.35 10.35 0 0 1 7.583 7.21a15.54 15.54 0 0 1 12.176-3.377A13.88 13.88 0 0 1 29.9 10.099a10.013 10.013 0 0 1-.376 10.768 12.08 12.08 0 0 1-2.386 2.577c-.038.03-.076.053-.111.08Z"
      />
      <path
        data-name="\u30D1\u30B9 345"
        d="M54.425 9.1a18.165 18.165 0 0 0-11.657-4.068 18.841 18.841 0 0 0-7.6 1.578l-.347.148-.017.007a15.864 15.864 0 0 1 1.539 3.2c0 .007.005.014.007.021 0 .006.005.011.007.017a15.071 15.071 0 0 1 6.407-1.391c7.315 0 13.245 4.945 13.245 11.044a9.393 9.393 0 0 1-.559 3.181c-.005.022-.016.044-.022.061a9.714 9.714 0 0 1-.274.662c-.044.1-.093.191-.143.285-.071.142-.137.285-.213.421-.066.12-.137.23-.2.345s-.142.235-.214.35-.142.2-.219.307c-.088.12-.17.246-.263.367-.055.071-.115.137-.175.208-.121.148-.241.3-.367.444a.887.887 0 0 0-.077.077 13.19 13.19 0 0 1-4.92 3.306l-.432 3.1-.151 1.047-.081.5-4.641-3.63c-.1.005-.192.011-.29.011a14.272 14.272 0 0 1-10.3-4.128 18.653 18.653 0 0 1-1.4 1.293c-.417.348-.856.68-1.3.993l-.035.024-.015.011a14.7 14.7 0 0 0 1.4 1.32 18.039 18.039 0 0 0 10.684 4.049l3.707 2.9a3.583 3.583 0 0 0 5.749-2.327l.383-2.738c4.9-2.64 7.951-7.332 7.951-12.435A13.658 13.658 0 0 0 54.425 9.1Z"
      />
      <path
        data-name="\u30D1\u30B9 346"
        d="m39.866 24.765.876-2.332h4.057l.876 2.332h2.332l-4.353-10.792h-1.768l-3.161 7.836-1.191 2.938h.007l-.007.018Zm4.3-4.019h-2.063l-.725-.006 1.394-3.607 1.028 2.654Z"
      />
      <path
        data-name="\u30D1\u30B9 347"
        d="M21.095 19.121a3.489 3.489 0 0 0 .425-.809 4.454 4.454 0 0 0 .221-.915 10.042 10.042 0 0 0 .085-1.077q.017-.582.017-1.278 0-.826-.025-1.489c-.006-.163-.039-.3-.053-.45a6.508 6.508 0 0 0-.042-.355c-.02-.135-.022-.29-.05-.417a3.979 3.979 0 0 0-.365-1.028 3.534 3.534 0 0 0-.705-.907 4.381 4.381 0 0 0-1.393-.9 5.487 5.487 0 0 0-3.67 0 4.582 4.582 0 0 0-1.411.9 3.542 3.542 0 0 0-.705.907 3.773 3.773 0 0 0-.356 1.028c-.028.138-.031.306-.051.455a5.74 5.74 0 0 0-.03.274c-.016.167-.048.315-.055.494q-.025.664-.025 1.489t.025 1.489a7.8 7.8 0 0 0 .136 1.222 3.78 3.78 0 0 0 .356 1.027 3.545 3.545 0 0 0 .705.907 4.573 4.573 0 0 0 1.411.9 4.877 4.877 0 0 0 1.835.316 5.144 5.144 0 0 0 1.333-.162 4.364 4.364 0 0 0 1.1-.453l1.087 1 1.169-1.113.059-.055.014-.013Zm-1.657-2.663a3.648 3.648 0 0 1-.139.769c-.016.045-.026.1-.046.139l-.911-.868-1.171 1.131-.056.053-.014.013.842.79.206.2a1.454 1.454 0 0 1-.777.209 2.046 2.046 0 0 1-.875-.178 2.235 2.235 0 0 1-.654-.453 1.654 1.654 0 0 1-.28-.4 2.219 2.219 0 0 1-.178-.566 6.323 6.323 0 0 1-.093-.891q-.025-.542-.026-1.368t.026-1.368a6.527 6.527 0 0 1 .093-.9 2.221 2.221 0 0 1 .178-.575 1.661 1.661 0 0 1 .28-.4 2.253 2.253 0 0 1 2.4-.445 1.918 1.918 0 0 1 .637.445 1.652 1.652 0 0 1 .28.4 2.234 2.234 0 0 1 .179.575 7.5 7.5 0 0 1 .1.9q.034.542.035 1.368.01.878-.033 1.42Z"
      />
    </g>
  </svg>
);
