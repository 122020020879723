import { Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo } from 'react';
import { MainModal } from 'src/components/modal/MainModal';

type CompleteDeleteAccountModalPropsType = {
  isOpen: boolean;
};

export const CompleteDeleteAccountModal: FC<CompleteDeleteAccountModalPropsType> = memo(({ isOpen }) => {
  return (
    <MainModal isOpen={isOpen} onClose={() => ({})} title={`退会しました`} isCloseOnClickOutside={false}>
      <Center pt={14} pb={8} px={32}>
        <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
          退会処理が完了しました。{'\n'}ご利用ありがとうございました。{'\n\n'}一度イオンモールアプリを{'\n'}
          終了してください。
        </Text>
      </Center>
    </MainModal>
  );
});
CompleteDeleteAccountModal.displayName = 'CompleteDeleteAccountModal';
