import { Box, Center, Pagination, Stack, Text } from '@mantine/core';
import { usePagination, useWindowScroll } from '@mantine/hooks';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { COLOR } from 'src/constants';
import { NewsListCard } from 'src/pages/news/components/NewsListCard';
import { PagingItem } from 'src/pages/news/components/PagingItem';
import { useMessageList } from 'src/pages/news/hooks/useMessageList';

export const NewsListWithPaging: FC = memo(() => {
  const [, scrollTo] = useWindowScroll();

  const [activePage, setActivePage] = useState<number>(1);

  // API実行時に1ページ目の時のみ返却されるsearchCount(全体の検索結果数)、limitRowNum(1ページの最大検索数)
  const [searchCount, setSearchCount] = useState<number>(0);
  const [limitRowNum, setLimitRowNum] = useState<number>(0);

  const { messageList, onClickNewsListItem } = useMessageList({ activePage });
  const pagination = usePagination({ total: Math.ceil(searchCount / limitRowNum) });

  useEffect(() => {
    setActivePage(pagination.active);
    scrollTo({ y: 0 });
  }, [pagination.active, scrollTo]);

  useEffect(() => {
    if (!messageList) return;
    if (messageList.searchCount !== undefined && messageList.searchCount > 0) {
      setSearchCount(messageList.searchCount);
    }
    if (messageList.limitRowNum !== undefined && messageList.limitRowNum > 0) {
      setLimitRowNum(messageList.limitRowNum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList?.searchCount, messageList?.limitRowNum]);

  const onClickNews = useCallback(
    (id: string) => onClickNewsListItem(id, messageList),
    [messageList, onClickNewsListItem],
  );

  return (
    <Box>
      <Box component="section" pt={48} pb={96} px={16} sx={{ backgroundColor: COLOR.BG_GRAY }}>
        {searchCount > 0 ? (
          <Stack spacing={12}>
            {messageList?.list.map((message) => (
              <NewsListCard
                key={message.id}
                subject={message.subject}
                createdAt={message.createdAt}
                id={message.id}
                onClick={onClickNews}
              />
            ))}
          </Stack>
        ) : (
          <Center>
            <Text size={16} sx={{ fontWeight: 500 }}>
              お知らせはまだありません。
            </Text>
          </Center>
        )}
      </Box>
      <Box pt={52} pb={99}>
        {searchCount > 0 && (
          <Pagination
            page={pagination.active}
            total={Math.ceil(searchCount / limitRowNum)}
            radius="md"
            size="sm"
            position="center"
            itemComponent={({ active, page }) => <PagingItem active={active} page={page} pagination={pagination} />}
            spacing={16}
            onChange={pagination.setPage}
          />
        )}
      </Box>
    </Box>
  );
});
NewsListWithPaging.displayName = 'NewsListWithPaging';
