import { Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { MainModal } from 'src/components/modal/MainModal';

type HowToUseFinishModalPropsType = {
  isFromHowToUsePage: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
};

export const HowToUseFinishModal: FC<HowToUseFinishModalPropsType> = memo(({ isFromHowToUsePage, isOpen, onClose }) => {
  return (
    <MainModal
      isOpen={isOpen}
      isCloseOnClickOutside={false}
      onClose={onClose}
      title={`電気自動車から${'\n'}放電してみよう！`}
      mainButton={<MainButton onClick={onClose}>{isFromHowToUsePage ? 'OK' : '使ってみる'}</MainButton>}
    >
      <Center py={16}>
        <Text data-autofocus size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
          現時点では一部の店舗でのみ{'\n'}
          利用可能です。{'\n'}
          スタンド利用状況から、{'\n'}
          利用可能な店舗を確認できます。
        </Text>
      </Center>
    </MainModal>
  );
});
HowToUseFinishModal.displayName = 'HowToUseFinishModal';
