import { useQuery } from '@tanstack/react-query';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { PointListResType } from 'src/types';
import { getApikey } from 'src/utils';

export const usePointList = () => {
  const { data } = useQuery<PointListResType>({
    enabled: !!getApikey(),
    queryFn: async () => {
      const { data } = await axiosInstance.get<PointListResType>(`${API_PATH.POINT_LIST}?pclass=1`, {
        headers: {
          Authorization: `Bearer ${getApikey()}`,
        },
      });
      return data;
    },
    queryKey: [API_PATH.POINT_LIST],
  });
  return { pointList: data };
};
