import { Box, Center, Group, Image, List, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainButton } from 'src/components/button/MainButton';
import { TextButton } from 'src/components/button/TextButton';
import { ErrorModal } from 'src/components/modal/ErrorModal';
import { COLOR, MESSAGE } from 'src/constants';
import { ERROR_NO } from 'src/constants/errorNo';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { WaonNoInput } from 'src/pages/pointExchange/components/WaonNoInput';
import { WaonNoSelect } from 'src/pages/pointExchange/components/WaonNoSelect';
import { useWaonNoForm } from 'src/pages/pointExchange/hooks/useWaonNoForm';

export const CardRegistration: FC = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as { isFromAccountPage?: boolean };

  const { userInfo } = useUserInfo();
  const { clearError, errorText, form, handleSubmit, isSubmitting } = useWaonNoForm({
    isFromAccountPage: state?.isFromAccountPage,
    waon: userInfo?.waon,
  });

  const [isOpenErrorModal, handleOpenErrorModal] = useDisclosure(false);
  const onCloseErrorModal = useCallback(() => handleOpenErrorModal.close(), [handleOpenErrorModal]);

  useEffect(() => {
    if (errorText) handleOpenErrorModal.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorText]);

  const onClickBack = () => navigate(-1);

  return (
    <Box>
      <Box py={26} px={16}>
        <Center>
          <Text
            size={22}
            sx={{ fontWeight: 'bold', letterSpacing: '2px', textAlign: 'center', whiteSpace: 'pre-wrap' }}
          >
            WAON POINT番号または{'\n'}
            WAON番号登録
          </Text>
        </Center>
        <Center pt={20}>
          <Text size={14} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            本サービスをご利用いただくには、WAON POINT番号またはWAON番号の登録が必要です。{'\n'}
            注意事項をご確認の上、番号をご入力ください。
          </Text>
        </Center>
        <Center pt={16}>
          <Text size={12} color={COLOR.RED} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            ※ なお、イオンモールアプリ内で既にWAON
            POINT番号またはWAON番号をご登録いただいている方も、改めてご登録が必要です。ご了承ください。
          </Text>
        </Center>
        <Box pt={49} pb={48}>
          <Text size={13} sx={{ fontWeight: 'bold' }}>
            ご注意
          </Text>
          <List size={13} pr={16} pt={4}>
            <List.Item>イオンJMBカード（JMB WAON含む）はご登録いただけません。</List.Item>
            <List.Item>
              WAON POINTカードをお持ちの方はカード裏面記載の1000から始まる16桁の番号をご入力ください。
            </List.Item>
            <List.Item>
              WAONカード、イオンカード（WAON一体型）をお持ちの方は裏面記載の6900から始まる16桁の番号をご入力ください。クレジットカード番号ではWAON
              POINTの進呈はできかねます。
            </List.Item>
            <List.Item>イオンカード（WAON一体型を除く）は対象外です。</List.Item>
            <List.Item>
              WAONカードに進呈されたWAON
              POINTは、イオンのお店に設置の「WAONステーション」または「イオン銀行ATM」（一部除く）でWAON（電子マネー）に交換いただくことで、全国のWAON加盟店でご利用いただけます。
            </List.Item>
            <List.Item>
              WAON
              POINT進呈内容のご確認方法については、以下のウェブサイトまたはアプリにログインすることでご確認いただけます。
              <br />
              ●ウェブサイトから「smart WAONウェブサイト」 <br />
              ●アプリから「iAEON」「smart WAONアプリ」
            </List.Item>
            <List.Item>
              進呈ポイントのお戻しや変更はできかねます。番号入力に誤りがあるとWAON
              POINTの進呈はできかねますので、ご確認をお願い致します。
            </List.Item>
          </List>
        </Box>
        <Text size={15} sx={{ fontWeight: 500 }}>
          お持ちのカードをお選びください。
        </Text>
        <Text size={13} sx={{ fontWeight: 400 }} mt={11}>
          ※以下のカードに記載の番号が対象となります。
        </Text>
        <Box
          sx={{
            backgroundColor: COLOR.WHITE,
            borderBottom: `solid 1px ${COLOR.LIGHT_GRAY}`,
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            cursor: 'pointer',
          }}
          mt={16}
          pt={21}
          pb={14}
          px={15}
        >
          <Group spacing={16}>
            <Image src={`${process.env.PUBLIC_URL}/image/card-waon-point.png`} width="109px" />
            <Text size={14} sx={{ fontWeight: 500 }}>
              WAON POINTカード
            </Text>
          </Group>
        </Box>
        <Box
          sx={{
            backgroundColor: COLOR.WHITE,
            borderBottom: `solid 1px ${COLOR.LIGHT_GRAY}`,
            cursor: 'pointer',
          }}
          py={14}
          px={15}
        >
          <Group spacing={16}>
            <Image src={`${process.env.PUBLIC_URL}/image/card-waon.jpg`} width="109px" />
            <Text size={14} sx={{ fontWeight: 500, whiteSpace: 'pre-wrap' }}>
              WAONカード{'\n'}モバイルWAON
            </Text>
          </Group>
        </Box>
        <Box
          sx={{
            backgroundColor: COLOR.WHITE,
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            cursor: 'pointer',
          }}
          pt={14}
          pb={21}
          px={15}
        >
          <Group spacing={16}>
            <Image src={`${process.env.PUBLIC_URL}/image/card-aeon.png`} width="109px" />
            <Text size={14} sx={{ fontWeight: 500, whiteSpace: 'pre-wrap' }}>
              イオンカード{'\n'}（WAON一体型）
            </Text>
          </Group>
        </Box>
        <form onSubmit={form.onSubmit(handleSubmit)} onChange={clearError} onSelect={clearError}>
          <Box pt={43} pb={24}>
            <Text size={14} sx={{ fontWeight: 500 }}>
              WAON POINT番号またはWAON番号
            </Text>
            <Group pt={6} spacing={8}>
              <WaonNoSelect {...form.getInputProps('waonNo1')} />
              <WaonNoInput {...form.getInputProps('waonNo2')} />
              <WaonNoInput {...form.getInputProps('waonNo3')} />
              <WaonNoInput {...form.getInputProps('waonNo4')} />
            </Group>
            <Text size={14} mt={6} sx={{ fontWeight: 500 }}>
              WAON POINT番号またはWAON番号(確認)
            </Text>
            <Group pt={6} spacing={8}>
              <WaonNoSelect {...form.getInputProps('waonNoConfirm1')} />
              <WaonNoInput {...form.getInputProps('waonNoConfirm2')} />
              <WaonNoInput {...form.getInputProps('waonNoConfirm3')} />
              <WaonNoInput {...form.getInputProps('waonNoConfirm4')} />
            </Group>
            <Text color={COLOR.RED} size={12}>
              {form.errors.waonNoError}
            </Text>
          </Box>
          <Text size={13} color={COLOR.RED} sx={{ fontWeight: 400 }}>
            イオンカード（WAON一体型）はクレジットカードの有効期限満了に伴う更新により、WAON番号が変更となりますのでご注意ください。
          </Text>
          <Stack spacing={31} px={41}>
            <Center pt={48}>
              <MainButton
                backgroundColor={COLOR.BLACK}
                type="submit"
                isLoading={isSubmitting}
                isDisabled={Object.keys(form.errors).length !== 0}
              >
                確定
              </MainButton>
            </Center>
            <Center>
              <TextButton isDisabled={isSubmitting} onClick={onClickBack}>
                戻る
              </TextButton>
            </Center>
          </Stack>
        </form>
      </Box>
      <ErrorModal
        isOpen={isOpenErrorModal}
        onClose={onCloseErrorModal}
        buttonElement={<MainButton onClick={onCloseErrorModal}>戻る</MainButton>}
        errorNo={errorText === MESSAGE.WAON_NO_REGISTER_FAULT ? ERROR_NO.E009 : ''}
      >
        {errorText}
      </ErrorModal>
    </Box>
  );
};
