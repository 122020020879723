import { Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { TextButton } from 'src/components/button/TextButton';
import { MainModal } from 'src/components/modal/MainModal';

type ConfirmCancelModalPropsType = {
  isLoading: boolean;
  isOpen: boolean;
  onClick: VoidFunction;
  onClose: VoidFunction;
};

export const ConfirmCancelModal: FC<ConfirmCancelModalPropsType> = memo(({ isLoading, isOpen, onClick, onClose }) => {
  return (
    <MainModal
      isOpen={isOpen}
      onClose={onClose}
      title={`本当に中止しても${'\n'}よろしいですか？`}
      mainButton={
        <MainButton isLoading={isLoading} onClick={onClick}>
          中止する
        </MainButton>
      }
      subButton={
        <TextButton isDisabled={isLoading} onClick={onClose}>
          戻る
        </TextButton>
      }
    >
      <Center pt={14} pb={8}>
        <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
          中止した場合は放電完了分のみの計測となります。{'\n'}
          放電時間が不足している場合、ポイントを進呈できない為、ご注意ください。
        </Text>
      </Center>
    </MainModal>
  );
});
ConfirmCancelModal.displayName = 'ConfirmCancelModal';
