// 問い合わせ対応円滑化のためにエラー画面に表示する用
export const ERROR_NO = {
  E001: 'E001', // 認証エラー
  E002: 'E002', // アプリケーションエラー
  E003: 'E003', // 二次元コード読取場所エラー
  E004: 'E004', // 放電開始エラー
  E005: 'E005', // 放電中止エラー
  E006: 'E006', // 放電中のスタンド故障エラー
  E007: 'E007', // アカウント設定エラー
  E008: 'E008', // 退会エラー
  E009: 'E009', // WAON番号更新エラー
  E010: 'E010', // URL未設定エラー
  E011: 'E011', // ポイント交換エラー
  E012: 'E012', // 放電認証エラー
  E013: 'E013', // 異なるQRコードエラー
} as const;
