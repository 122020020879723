import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { EnegateStopDischargeResType } from 'src/types';
import { getApikey } from 'src/utils';

export const useEnegateStopDischarge = () => {
  const [isErrorResult, setIsErrorResult] = useState<boolean>(false);

  const { isError, isLoading, mutateAsync } = useMutation({
    mutationFn: async () => {
      return await axiosInstance.delete<EnegateStopDischargeResType>(`${API_PATH.ENEGATE_STOP_DISCHARGE}`, {
        headers: {
          Authorization: `Bearer ${getApikey()}`,
        },
      });
    },
    onMutate() {
      setIsErrorResult(false);
    },
    onSuccess({ data }) {
      if (data.result) {
        setIsErrorResult(false);
      } else {
        setIsErrorResult(true);
      }
    },
  });
  // API自体のエラーと、正常終了時のresult: falseもエラーとして扱う
  return { enegateStopDischarge: mutateAsync, isError: isError || isErrorResult, isLoading };
};
