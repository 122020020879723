import type { StandStatusType } from 'src/types';
import { STAND_STATUS } from 'src/types';

export const isErrorDischargingStandStatus = (standStatus: StandStatusType): boolean => {
  switch (standStatus) {
    case STAND_STATUS.INACTIVE:
      return true;
    case STAND_STATUS.OUT_OF_ORDER:
      return true;
    default:
      return false;
  }
};

export const isShowDischargeHistory = (standStatus: StandStatusType): boolean => {
  switch (standStatus) {
    case STAND_STATUS.END:
      return true;
    case STAND_STATUS.OUT_OF_ORDER:
      return true;
    default:
      return false;
  }
};
