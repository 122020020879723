import type { SVGProps } from 'react';

export const ContactIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36.62} height={30.555} {...props}>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 845">
      <g data-name="\u30B0\u30EB\u30FC\u30D7 844">
        <path
          data-name="\u9577\u65B9\u5F62 546"
          d="M4.5 0h27.62a4.505 4.505 0 0 1 4.5 4.5v21.55a4.505 4.505 0 0 1-4.5 4.5H4.5a4.505 4.505 0 0 1-4.5-4.5V4.5A4.505 4.505 0 0 1 4.5 0Zm27.62 28.05a2 2 0 0 0 2-2V4.5a2 2 0 0 0-2-2H4.5a2 2 0 0 0-2 2v21.55a2 2 0 0 0 2 2Z"
          fill="#6c6c6c"
        />
      </g>
      <path
        data-name="\u30D1\u30B9 512"
        d="M18.31 18.303a4.255 4.255 0 0 1-3.032-1.254L5.294 7.062a1.25 1.25 0 0 1 1.768-1.768l9.987 9.984a1.783 1.783 0 0 0 2.521 0l9.987-9.987a1.25 1.25 0 1 1 1.768 1.768l-9.987 9.99a4.255 4.255 0 0 1-3.028 1.254Z"
        fill="#6c6c6c"
      />
    </g>
  </svg>
);
