import type { MantineNumberSize } from '@mantine/core';
import { Loader } from '@mantine/core';
import type { FC } from 'react';

type MainLoaderPropsType = {
  size?: MantineNumberSize;
};

export const MainLoader: FC<MainLoaderPropsType> = ({ size }) => {
  return <Loader color="gray" size={size} />;
};
