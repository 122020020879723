import { Box, createStyles, Text } from '@mantine/core';
import type { FC } from 'react';
import { useMemo } from 'react';
import { COLOR } from 'src/constants';
import type { StandStatusType } from 'src/types';
import { STAND_STATUS } from 'src/types';

type BalloonPropsType = {
  dischargeStatus: StandStatusType;
};

const useStyles = createStyles(() => ({
  balloon: {
    '&::before': {
      border: '10px solid transparent',
      borderTop: `10px solid ${COLOR.WHITE}`,
      content: '""',
      left: '50%',
      position: 'absolute',
      top: '100%',
      transform: 'translateX(-50%)',
    },
    background: COLOR.WHITE,
    borderRadius: '999px',
    display: 'inline-block',
    filter: 'drop-shadow(0 0 2px rgba(0, 0, 0, 0.1))',
    fontWeight: 'bold',
    padding: '10px 12px',
    position: 'relative',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    width: '325px',
  },
}));

export const Balloon: FC<BalloonPropsType> = ({ dischargeStatus }) => {
  const { classes } = useStyles();
  const message = useMemo(() => {
    switch (dischargeStatus) {
      case STAND_STATUS.DISCHARGING:
        return `ただいま放電中です。${'\n'}残り時間はこちらから確認できます。`;
      case STAND_STATUS.END:
        return `放電が完了しました！${'\n'}放電コネクタをはずしてください。`;
      default:
        return `ご家庭で発電した電力を、電気自動車で${'\n'}イオンモールに放電しよう。`;
    }
  }, [dischargeStatus]);
  return (
    <Box className={classes.balloon}>
      <Text size={14}>{message}</Text>
    </Box>
  );
};
