import { Paper } from '@mantine/core';
import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import type { URL_PATH } from 'src/router/Router';

type CommonPropsType = {
  children?: ReactNode;
};

type BottomSheetMenuIconPropsType = CommonPropsType &
  (
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        state?: any;
        to: typeof URL_PATH[keyof typeof URL_PATH];
      }
    | {
        url: string;
      }
  );

export const BottomSheetMenuIcon: FC<BottomSheetMenuIconPropsType> = (props) => {
  const { children } = props;

  if ('to' in props) {
    return (
      <Paper
        sx={{ borderRadius: '50%', height: '95px', width: '95px' }}
        mx="auto"
        component={Link}
        to={props.to}
        state={props.state}
      >
        {children}
      </Paper>
    );
  } else {
    return (
      <Paper sx={{ borderRadius: '50%', height: '95px', width: '95px' }} mx="auto" component="a" href={props.url}>
        {children}
      </Paper>
    );
  }
};
