import { Box, Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo } from 'react';
import { COLOR } from 'src/constants';

type TimeCardPropsType = {
  isSelected: boolean;
  minute: number;
  onClick: (minute: number) => void;
};

export const TimeCard: FC<TimeCardPropsType> = memo(({ isSelected, minute, onClick }) => {
  const backgroundColor = isSelected ? COLOR.MAIN_GREEN : COLOR.WHITE;
  const color = isSelected ? COLOR.WHITE : COLOR.BLACK;
  return (
    <Box
      px={38}
      py={24}
      sx={{ backgroundColor, border: `solid 3px ${COLOR.MAIN_GREEN}`, borderRadius: '20px' }}
      onClick={() => onClick(minute)}
    >
      <Center>
        <Text size={36} color={color} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
          {minute}
        </Text>
        <Text size={16} mt={10} ml={2} color={color} sx={{ fontWeight: 'bold' }}>
          分
        </Text>
      </Center>
    </Box>
  );
});
TimeCard.displayName = 'TimeCard';
