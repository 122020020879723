import { Box, Center, Modal, Text } from '@mantine/core';
import type { FC, ReactElement, ReactNode } from 'react';
import { memo } from 'react';

type ErrorModalPropsType = {
  buttonElement: ReactElement;
  children: ReactNode;
  errorNo?: string;
  isOpen: boolean;
  onClose: VoidFunction;
};

export const ErrorModal: FC<ErrorModalPropsType> = memo(({ buttonElement, children, errorNo, isOpen, onClose }) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      overlayOpacity={0.3}
      withCloseButton={false}
      centered
      closeOnClickOutside={false}
      radius="xl"
      padding={0}
      styles={() => ({
        body: {
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '31px',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '58px',
          textAlign: 'center',
          whiteSpace: 'pre-wrap',
        },
      })}
    >
      <Box data-autofocus>{children}</Box>
      {errorNo && (
        <Center pt={16}>
          <Text size={16}>{errorNo}</Text>
        </Center>
      )}
      <Center px={40} pt={42} pb={46}>
        {buttonElement}
      </Center>
    </Modal>
  );
});
ErrorModal.displayName = 'ErrorModal';
