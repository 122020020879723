import type { SVGProps } from 'react';

export const AllMenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 595" fill="#fff">
      <path
        data-name="\u9577\u65B9\u5F62 393"
        d="M2 2v5h5V2H2M1 0h7a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1Z"
      />
      <path
        data-name="\u9577\u65B9\u5F62 394"
        d="M13 2v5h5V2h-5m-1-2h7a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1Z"
      />
      <path
        data-name="\u9577\u65B9\u5F62 395"
        d="M13 13v5h5v-5h-5m-1-2h7a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1Z"
      />
      <path
        data-name="\u9577\u65B9\u5F62 396"
        d="M2 13v5h5v-5H2m-1-2h7a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1Z"
      />
    </g>
  </svg>
);
