import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { EnegateAuthParamType, EnegateAuthResType } from 'src/types';
import { getApikey } from 'src/utils';

export const useEnegateAuth = () => {
  const [isErrorResult, setIsErrorResult] = useState<boolean>(false);

  const { isError, isLoading, mutateAsync } = useMutation({
    mutationFn: async ({ installationId, standNo }: EnegateAuthParamType) => {
      return await axiosInstance.post<EnegateAuthResType>(
        `${API_PATH.ENEGATE_AUTH}`,
        {
          installationId,
          standNo,
        },
        {
          headers: {
            Authorization: `Bearer ${getApikey()}`,
          },
        },
      );
    },
    onMutate() {
      setIsErrorResult(false);
    },
    onSuccess({ data }) {
      if (data.result) {
        setIsErrorResult(false);
      } else {
        setIsErrorResult(true);
      }
    },
  });
  // API自体のエラーと、正常終了時のresult: falseもエラーとして扱う
  return { enegateAuth: mutateAsync, isError: isError || isErrorResult, isLoading };
};
