import { Box, Center, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { FC } from 'react';
import { useCallback } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { ErrorModal } from 'src/components/modal/ErrorModal';
import { COLOR } from 'src/constants';
import { ERROR_NO } from 'src/constants/errorNo';
import { CompleteDeleteAccountModal } from 'src/pages/deleteAccount/components/CompleteDeleteAccountModal';
import { ConfirmDeleteAccountModal } from 'src/pages/deleteAccount/components/ConfirmDeleteAccountModal';
import { useUserQuit } from 'src/pages/deleteAccount/hooks/useUserQuit';
import { clearStorage } from 'src/utils';

export const DeleteAccount: FC = () => {
  const { isLoading, userQuit } = useUserQuit();
  const [isOpenConfirmDeleteAccountModal, handleConfirmDeleteAccountModal] = useDisclosure(false);
  const [isOpenCompleteDeleteAccountModal, handleCompleteDeleteAccountModal] = useDisclosure(false);
  const [isOpenErrorModal, handleOpenErrorModal] = useDisclosure(false);

  const onClickOpenConfirmModal = () => handleConfirmDeleteAccountModal.open();
  const onClickBack = () => handleConfirmDeleteAccountModal.close();

  const onClickDeleteAccount = async () => {
    try {
      const { data } = await userQuit();
      if (data.result === 'ok') {
        handleConfirmDeleteAccountModal.close();
        handleCompleteDeleteAccountModal.open();
        clearStorage();
      } else {
        throw Error();
      }
    } catch {
      handleConfirmDeleteAccountModal.close();
      handleOpenErrorModal.open();
    }
  };

  const onCloseErrorModal = useCallback(() => handleOpenErrorModal.close(), [handleOpenErrorModal]);

  return (
    <Box>
      <Box px={57} py={26}>
        <Stack spacing={20} pb={59}>
          <Center>
            <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
              退会
            </Text>
          </Center>
          <Center>
            <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
              V2AEONMALLアプリから{'\n'}
              退会する方は下記から{'\n'}
              お願いいたします。
            </Text>
          </Center>
        </Stack>
        <MainButton backgroundColor={COLOR.BLACK} onClick={onClickOpenConfirmModal}>
          退会する
        </MainButton>
      </Box>
      <ConfirmDeleteAccountModal
        isOpen={isOpenConfirmDeleteAccountModal}
        isLoading={isLoading}
        onClose={onClickBack}
        onClickDeleteAccount={onClickDeleteAccount}
      />
      <CompleteDeleteAccountModal isOpen={isOpenCompleteDeleteAccountModal} />
      <ErrorModal
        errorNo={ERROR_NO.E008}
        isOpen={isOpenErrorModal}
        onClose={onCloseErrorModal}
        buttonElement={<MainButton onClick={onCloseErrorModal}>戻る</MainButton>}
      >
        退会処理に失敗しました。{'\n'}イオンモールアプリを再起動し、操作をやり直してください。
      </ErrorModal>
    </Box>
  );
};
