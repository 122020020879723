import { useQuery } from '@tanstack/react-query';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { UserInfoResType } from 'src/types';
import { getApikey } from 'src/utils';

// NOTE userInfoはデータ更新時以外のrefetchは行わず一度取得したキャッシュを使い回す
export const useUserInfo = () => {
  const { data } = useQuery<UserInfoResType>({
    cacheTime: Infinity,
    enabled: !!getApikey(),
    queryFn: async () => {
      const { data } = await axiosInstance.get<UserInfoResType>(API_PATH.USER_INFO, {
        headers: {
          Authorization: `Bearer ${getApikey()}`,
        },
      });
      return data;
    },
    queryKey: [API_PATH.USER_INFO],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return { userInfo: data };
};
