import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { UserInfoResType } from 'src/types';
import { getApikey, nowDateYYYYMMDD_dash } from 'src/utils';

export const useUpdateUserAccountInfo = () => {
  const queryClient = useQueryClient();

  const note1 = '太陽光発電・EV 充電器設置';
  const note2 = '太陽光発電で充電';
  const note3 = nowDateYYYYMMDD_dash();

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: async () => {
      return await axiosInstance.put(
        `${API_PATH.USER_UPDATE}`,
        {
          note1,
          note2,
          note3,
        },
        {
          headers: {
            Authorization: `Bearer ${getApikey()}`,
          },
        },
      );
    },
    onSuccess: () => {
      queryClient.setQueryData<UserInfoResType>([API_PATH.USER_INFO], (oldUserInfo?: UserInfoResType) => {
        if (!oldUserInfo) return undefined;
        return { ...oldUserInfo, note1, note2, note3 };
      });
    },
  });
  return { isLoading, updateUserAccountInfo: mutateAsync };
};
