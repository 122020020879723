import { Stack } from '@mantine/core';
import type { FC } from 'react';
import { memo, useState } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { TextButton } from 'src/components/button/TextButton';
import { MainModal } from 'src/components/modal/MainModal';
import { ConfirmCheckbox } from 'src/pages/discharge/components/ConfirmCheckbox';

type ConfirmBeforeDischargeModalPropsType = {
  isOpen: boolean;
  onClick: VoidFunction;
  onClose: VoidFunction;
};

export const ConfirmBeforeDischargeModal: FC<ConfirmBeforeDischargeModalPropsType> = memo(
  ({ isOpen, onClick, onClose }) => {
    const [isChecked1, setIsChecked1] = useState<boolean>(false);
    const [isChecked2, setIsChecked2] = useState<boolean>(false);

    const onClickCheck1 = () => setIsChecked1((prev) => !prev);
    const onClickCheck2 = () => setIsChecked2((prev) => !prev);

    return (
      <MainModal
        isOpen={isOpen}
        onClose={onClose}
        title="ご確認ください"
        mainButton={
          <MainButton isDisabled={!isChecked1 || !isChecked2} onClick={onClick}>
            放電開始
          </MainButton>
        }
        subButton={<TextButton onClick={onClose}>戻る</TextButton>}
      >
        <Stack>
          <ConfirmCheckbox isChecked={isChecked1} onClick={onClickCheck1}>
            対象車種の確認はお済みですか？
          </ConfirmCheckbox>
          <ConfirmCheckbox isChecked={isChecked2} onClick={onClickCheck2}>
            選択時間に誤りはないですか？
          </ConfirmCheckbox>
        </Stack>
      </MainModal>
    );
  },
);
ConfirmBeforeDischargeModal.displayName = 'ConfirmBeforeDischargeModal';
