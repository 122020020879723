import type { SVGProps } from 'react';

export const WaonPointVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={62.466} height={32.327} {...props}>
    <g fill="#1a1311" data-name="\u30B0\u30EB\u30FC\u30D7 847">
      <path
        d="m2.56.18 3.19 8.718L9.109.18h2.087l3.377 8.753L17.759.18h2.525l-5.277 14.326h-.824L10.139 4.021 6.087 14.509h-.8L-.004.183h2.56"
        data-name="\u30D1\u30B9 1"
      />
      <path
        d="m27.098.18 5.658 14.153h-2.609l-1.338-3.452h-5.7l-1.237 3.452h-2.6L24.561.18h2.527m-3.231 8.622h4.128l-2.16-5.59Z"
        data-name="\u30D1\u30B9 2"
      />
      <path
        d="M40.352 0a7.263 7.263 0 0 1 3.593.893 6.673 6.673 0 0 1 2.534 2.62 7.869 7.869 0 0 1 .017 7.42 6.617 6.617 0 0 1-2.537 2.637 7.409 7.409 0 0 1-7.24-.018 6.518 6.518 0 0 1-2.527-2.619 7.529 7.529 0 0 1-.914-3.677 7.688 7.688 0 0 1 .914-3.656 6.578 6.578 0 0 1 2.5-2.655A6.977 6.977 0 0 1 40.354 0m-4.517 7.256a5.491 5.491 0 0 0 .58 2.536 4.266 4.266 0 0 0 1.608 1.733 4.451 4.451 0 0 0 2.328.621 4.217 4.217 0 0 0 3.249-1.368 4.962 4.962 0 0 0 1.27-3.522 5.6 5.6 0 0 0-.571-2.535 4.133 4.133 0 0 0-1.618-1.743 4.662 4.662 0 0 0-4.687 0 4.1 4.1 0 0 0-1.6 1.74 5.619 5.619 0 0 0-.561 2.538Z"
        data-name="\u30D1\u30B9 3"
      />
      <path
        d="m51.931.18 7.614 9.871V.18h2.416v14.153h-2.122L52.18 4.295v10.038h-2.425V.18h2.172"
        data-name="\u30D1\u30B9 4"
      />
      <path
        d="M8.497 18.011a3.916 3.916 0 0 1 2.92 1.155 4.434 4.434 0 0 1-.014 5.919 4.1 4.1 0 0 1-3 1.137h-2.38v5.93H3.548V18.011h4.949m-.618 6.088a2.215 2.215 0 0 0 1.546-.521 1.865 1.865 0 0 0 .55-1.473c0-1.31-.753-1.966-2.271-1.966H6.02v3.96Z"
        data-name="\u30D1\u30B9 5"
      />
      <path
        d="M21.078 17.827a7.025 7.025 0 0 1 3.579.909 6.431 6.431 0 0 1 2.53 2.61 7.55 7.55 0 0 1 .954 3.737 7.677 7.677 0 0 1-.921 3.685 6.643 6.643 0 0 1-2.527 2.624 6.981 6.981 0 0 1-3.636.933 7.074 7.074 0 0 1-3.612-.942 6.669 6.669 0 0 1-2.544-2.615 7.573 7.573 0 0 1-.907-3.685 7.707 7.707 0 0 1 .907-3.662 6.653 6.653 0 0 1 2.506-2.655 7.114 7.114 0 0 1 3.671-.94m-4.521 7.257a5.484 5.484 0 0 0 .575 2.534 4.225 4.225 0 0 0 1.6 1.739 4.447 4.447 0 0 0 2.342.615 4.214 4.214 0 0 0 3.238-1.364 4.988 4.988 0 0 0 1.272-3.524 5.589 5.589 0 0 0-.573-2.539 4.179 4.179 0 0 0-1.62-1.739 4.438 4.438 0 0 0-2.338-.616 4.487 4.487 0 0 0-2.35.616 4.212 4.212 0 0 0-1.6 1.739 5.6 5.6 0 0 0-.542 2.54Z"
        data-name="\u30D1\u30B9 6"
      />
      <path d="M30.513 18.011h2.475v14.141h-2.475z" data-name="\u9577\u65B9\u5F62 1" />
      <path
        d="m38.983 18.01 7.612 9.859V18.01h2.423v14.14h-2.127l-7.652-10.037V32.15h-2.428V18.01h2.172"
        data-name="\u30D1\u30B9 7"
      />
      <path d="M62.466 18.01v2.128h-4.557V32.15h-2.47V20.139h-4.547V18.01h11.579" data-name="\u30D1\u30B9 8" />
    </g>
  </svg>
);
