import { Box, Center, Group, Paper, Stack, Text } from '@mantine/core';
import type { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RightArrowIcon } from 'src/components/icon/RightArrowIcon';
import { LinkText } from 'src/components/text/LinkText';
import { URL } from 'src/constants';
import { URL_PATH } from 'src/router/Router';

export const HowToUseTop: FC = () => {
  const navigate = useNavigate();
  const onClickContact = () => navigate(URL_PATH.CONTACT);

  return (
    <Box px={16} py={26}>
      <Stack spacing={22} pb={32}>
        <Center>
          <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
            使い方
          </Text>
        </Center>
        <Center>
          <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            本アプリの使い方になります。{'\n'}
            以下からご要望の情報がない場合は{'\n'}
            <LinkText url={URL.FAQ}>よくある質問</LinkText>もしくは
            <LinkText onClick={onClickContact}>お問い合わせ</LinkText>まで。
          </Text>
        </Center>
      </Stack>
      <Stack spacing={12}>
        <Paper radius="lg" pl={17} pt={39} pb={37} pr={12} component={Link} to="/" state={{ isShowHowToUse: true }}>
          <Group position="apart">
            <Text size={16} sx={{ fontWeight: 'bold' }}>
              V2AEONMALLとは？（使い方）
            </Text>
            <RightArrowIcon />
          </Group>
        </Paper>
      </Stack>
    </Box>
  );
};
