import { Text } from '@mantine/core';
import type { FC } from 'react';
import { usePointList } from 'src/pages/pointExchange/hooks/usePointList';

export const MyTotalPoint: FC = () => {
  const { pointList } = usePointList();
  const totalPoint = pointList?.balance ?? 0;
  return (
    <Text size={40} sx={{ fontWeight: 500, letterSpacing: '3px', lineHeight: 1 }}>
      {totalPoint.toLocaleString()}
    </Text>
  );
};
