import { Box, Center, Group, Image, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainButton } from 'src/components/button/MainButton';
import { ErrorModal } from 'src/components/modal/ErrorModal';
import { COLOR } from 'src/constants';
import { ERROR_NO } from 'src/constants/errorNo';
import { ConfirmCancelModal } from 'src/pages/discharge/components/ConfirmCancelModal';
import { useEnegateDischargingStatus } from 'src/pages/discharge/hooks/useEnegateDischargingStatus';
import { useEnegateStopDischarge } from 'src/pages/discharge/hooks/useEnegateStopDischarge';
import { URL_PATH } from 'src/router/Router';
import { STAND_STATUS } from 'src/types';
import { isErrorDischargingStandStatus, setIsCheckedDischargeError } from 'src/utils';

export const Discharging: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { settingTime: number } | undefined;

  const { enegateStopDischarge, isLoading } = useEnegateStopDischarge();

  const [isOpenConfirmCancelModal, handleConfirmCancelModal] = useDisclosure(false);
  const [isOpenStopDischargeErrorModal, handleOpenStopDischargeErrorModal] = useDisclosure(false);
  const [isOpenDischargingErrorModal, handleOpenDischargingErrorModal] = useDisclosure(false);

  const { disChargingStatusList } = useEnegateDischargingStatus({ isDisableCache: true });
  useEffect(() => {
    if (!disChargingStatusList?.list) return;
    const { list } = disChargingStatusList;
    // スタンド情報が複数とれる(放電終了し認証状態が解除されている)or放電終了ステータスの場合は放電終了画面に遷移
    if (list.length > 1 || list[0].standStatus === STAND_STATUS.END) navigate(URL_PATH.DISCHARGE_FINISH);
    // 故障ステータスの場合はエラーメッセージを表示
    if (isErrorDischargingStandStatus(list[0].standStatus)) handleOpenDischargingErrorModal.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disChargingStatusList]);
  const remainingTime = useMemo(() => {
    // 最初にデータ取得中は前の画面から渡した設定時間を表示
    if (!disChargingStatusList?.list) return state?.settingTime;
    return (disChargingStatusList?.list[0].settingTime ?? 0) - (disChargingStatusList?.list[0].utilityTime ?? 0);
  }, [disChargingStatusList?.list, state?.settingTime]);

  const onClickCancel = () => handleConfirmCancelModal.open();

  const onCloseConfirmCancelModal = useCallback(() => handleConfirmCancelModal.close(), [handleConfirmCancelModal]);
  const onClickConfirmCancelModalCancel = useCallback(async () => {
    try {
      const { data } = await enegateStopDischarge();
      if (data.result) {
        navigate(URL_PATH.DISCHARGE_FINISH);
      } else {
        throw Error();
      }
    } catch {
      handleConfirmCancelModal.close();
      handleOpenStopDischargeErrorModal.open();
    }
  }, [enegateStopDischarge, handleConfirmCancelModal, handleOpenStopDischargeErrorModal, navigate]);

  const onCloseStopDischargeErrorModal = useCallback(
    () => handleOpenStopDischargeErrorModal.close(),
    [handleOpenStopDischargeErrorModal],
  );
  const onCloseDischargingErrorModal = useCallback(() => {
    setIsCheckedDischargeError(true);
    navigate(URL_PATH.TOP);
  }, [navigate]);

  return (
    <Box>
      <Box py={26}>
        <Center>
          <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
            放電中
          </Text>
        </Center>
        <Center pt={22}>
          <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            現在放電中です。{'\n'}終了までしばらくお待ちください。
          </Text>
        </Center>
        <Center pt={10} px={40}>
          <Text size={13} sx={{ fontWeight: 400, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            アプリを閉じても、この画面に戻ることはできますが、開始直後は残り時間の反映まで時間がかかる場合がございます。
          </Text>
        </Center>
        <Center pt={10}>
          <Group align="baseline">
            <Text size={25} sx={{ fontWeight: 'bold' }}>
              残り約
            </Text>
            <Text size={60} color={COLOR.MAIN_GREEN} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
              {remainingTime ?? '-'}
            </Text>
            <Text size={25} sx={{ fontWeight: 'bold' }}>
              分
            </Text>
          </Group>
        </Center>
        <Image src={`${process.env.PUBLIC_URL}/image/car.png`} width="100%" mt={-30} />
        <Center px={57} pt={30}>
          <MainButton backgroundColor={COLOR.BLACK} onClick={onClickCancel}>
            中止する
          </MainButton>
        </Center>
      </Box>
      <ConfirmCancelModal
        isOpen={isOpenConfirmCancelModal}
        isLoading={isLoading}
        onClick={onClickConfirmCancelModalCancel}
        onClose={onCloseConfirmCancelModal}
      />
      <ErrorModal
        errorNo={ERROR_NO.E005}
        isOpen={isOpenStopDischargeErrorModal}
        onClose={onCloseStopDischargeErrorModal}
        buttonElement={<MainButton onClick={onCloseStopDischargeErrorModal}>戻る</MainButton>}
      >
        放電中止に失敗しました。{'\n'}お手数ですが、イオンモールアプリを再起動し、操作をやり直してください。
      </ErrorModal>
      <ErrorModal
        errorNo={ERROR_NO.E006}
        isOpen={isOpenDischargingErrorModal}
        onClose={onCloseDischargingErrorModal}
        buttonElement={<MainButton onClick={onCloseDischargingErrorModal}>TOPへ戻る</MainButton>}
      >
        放電処理中に問題が発生しました。{'\n'}
        スタンドに戻り放電コネクタを外し、放電を終了してください。お車の移動をお願い致します。
        <Text size={14} mt={8} color={COLOR.RED}>
          ※エラー詳細を確認後、放電時間をもとにポイントを進呈致します。後日ポイント履歴よりご確認ください。{'\n'}
          ※放電時間が不足する場合はポイントの進呈がない場合もございます。
        </Text>
      </ErrorModal>
    </Box>
  );
};
