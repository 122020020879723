import { Text, UnstyledButton } from '@mantine/core';
import type { FC, ReactNode } from 'react';
import { COLOR } from 'src/constants';

type TextButtonPropsType = {
  children: ReactNode;
  isDisabled?: boolean;
  onClick: VoidFunction;
};

export const TextButton: FC<TextButtonPropsType> = (props) => {
  const { children, isDisabled = false, onClick } = props;
  return (
    <UnstyledButton onClick={onClick} disabled={isDisabled}>
      <Text size={18} color={isDisabled ? COLOR.GRAY : COLOR.BLACK} sx={{ fontWeight: 500 }}>
        {children}
      </Text>
    </UnstyledButton>
  );
};
