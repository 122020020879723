import { Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { MainModal } from 'src/components/modal/MainModal';

type AccountSettingFinishModalPropsType = {
  isInitialFlow?: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
};

export const AccountSettingFinishModal: FC<AccountSettingFinishModalPropsType> = memo(
  ({ isInitialFlow = false, isOpen, onClose }) => {
    return (
      <MainModal
        isOpen={isOpen}
        isCloseOnClickOutside={false}
        onClose={onClose}
        title={`${isInitialFlow ? '登録' : '更新'}が完了しました！`}
        mainButton={<MainButton onClick={onClose}>{isInitialFlow ? 'はじめる' : 'OK'}</MainButton>}
      >
        <Center py={16}>
          <Text data-autofocus size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            登録内容は、アカウント設定より定期的に見直しをお願いします。
          </Text>
        </Center>
      </MainModal>
    );
  },
);
AccountSettingFinishModal.displayName = 'AccountSettingFinishModal';
