import { Box, Center, Modal, Stack } from '@mantine/core';
import type { FC, ReactElement, ReactNode } from 'react';
import { memo } from 'react';

type MainModalPropsType = {
  children?: ReactNode;
  isCloseOnClickOutside?: boolean;
  isOpen: boolean;
  mainButton?: ReactElement;
  onClose: VoidFunction;
  px?: number;
  subButton?: ReactElement;
  title?: string;
};

export const MainModal: FC<MainModalPropsType> = memo(
  ({ children, isCloseOnClickOutside = true, isOpen, mainButton, onClose, px = 16, subButton, title }) => {
    return (
      <Modal
        title={title}
        opened={isOpen}
        onClose={onClose}
        overlayOpacity={0.3}
        withCloseButton={false}
        centered
        closeOnClickOutside={isCloseOnClickOutside}
        radius="xl"
        padding={0}
        styles={() => ({
          body: {
            paddingBottom: `46px`,
            paddingLeft: `${px}px`,
            paddingRight: `${px}px`,
          },
          title: {
            fontSize: '20px',
            fontWeight: 'bold',
            paddingTop: '46px',
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
            width: '100%',
          },
        })}
      >
        <Box data-autofocus>{children}</Box>
        {(mainButton || subButton) && (
          <Stack px={40} pt={30}>
            <Center>{mainButton}</Center>
            <Center>{subButton}</Center>
          </Stack>
        )}
      </Modal>
    );
  },
);
MainModal.displayName = 'MainModal';
