import { Box, Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { TextButton } from 'src/components/button/TextButton';
import { MainModal } from 'src/components/modal/MainModal';
import { COLOR } from 'src/constants';
import { ERROR_NO } from 'src/constants/errorNo';
import { useEnegateAuth } from 'src/pages/discharge/hooks/useEnegateAuth';

type StandInfoModalPropsType = {
  installationId: string;
  isOpen: boolean;
  onClick: VoidFunction;
  onClose: VoidFunction;
  standName: string;
  standNo: number;
};

export const StandInfoModal: FC<StandInfoModalPropsType> = memo(
  ({ installationId, isOpen, onClick, onClose, standName, standNo }) => {
    const { enegateAuth, isError, isLoading } = useEnegateAuth();

    // モーダルclose時にエラー表示をリセットしたいためstate管理
    const [isAuthError, setIsAuthError] = useState<boolean>(false);
    useEffect(() => setIsAuthError(isError), [isError]);
    const onCloseStandInfoModal = useCallback(() => {
      setIsAuthError(false);
      onClose();
    }, [onClose]);

    const onClickCertification = async () => {
      const { data } = await enegateAuth({ installationId, standNo });
      if (data.result) {
        onClick();
      }
    };

    return (
      <MainModal
        isOpen={isOpen}
        onClose={onCloseStandInfoModal}
        isCloseOnClickOutside={false}
        title={`${standName}\nスタンド${standNo}`}
        mainButton={
          <MainButton isLoading={isLoading} onClick={onClickCertification}>
            認証
          </MainButton>
        }
        subButton={
          <TextButton isDisabled={isLoading} onClick={onCloseStandInfoModal}>
            戻る
          </TextButton>
        }
      >
        <Center pt={14} pb={8}>
          <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            放電コネクタを差し込み、準備が出来たら{'\n'}認証ボタンを押してください。
          </Text>
        </Center>
        {isAuthError && (
          <Box py={20} px={8}>
            <Text size={15} color={COLOR.RED} sx={{ fontWeight: 500, whiteSpace: 'pre-wrap' }}>
              現在、以下の理由でご利用いただけません。他の放電スタンドをご利用ください。{'\n'}
              <Text size={15} color={COLOR.RED} my={8} sx={{ fontWeight: 500, whiteSpace: 'pre-wrap' }}>
                ・他の利用者が放電中{'\n'}
                ・機器が故障中{'\n'}
              </Text>
              ※詳細はスタンド利用状況よりご確認いただけます。
            </Text>
            <Center pt={16}>
              <Text size={16}>{ERROR_NO.E012}</Text>
            </Center>
          </Box>
        )}
      </MainModal>
    );
  },
);
StandInfoModal.displayName = 'StandInfoModal';
