import { Box, Paper, Text } from '@mantine/core';
import type { FC } from 'react';
import { COLOR } from 'src/constants';

type DischargeHistoryCardPropsType = {
  kwh: number;
  standName: string;
  time: number;
};

export const DischargeHistoryCard: FC<DischargeHistoryCardPropsType> = ({ kwh, standName, time }) => {
  return (
    <Paper radius="lg" px={20} pt={14} pb={21}>
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <Box pr={17}>
          <Box sx={{ alignItems: 'flex-end', display: 'flex', justifyContent: 'center' }}>
            <Text size={24} color={COLOR.MAIN_GREEN} sx={{ fontWeight: 'bold', lineHeight: '26px' }}>
              {kwh}
            </Text>
            <Text size={11} sx={{ fontWeight: 'bold' }}>
              kWh
            </Text>
          </Box>
          <Box pt={1} sx={{ alignItems: 'flex-end', display: 'flex', justifyContent: 'center' }}>
            <Text size={15} sx={{ fontWeight: 'bold', lineHeight: '16px' }}>
              {time}
            </Text>
            <Text size={10} sx={{ fontWeight: 'bold' }}>
              分
            </Text>
          </Box>
        </Box>
        <Box pt={4} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <Text size={13} color={COLOR.DARK_GRAY} sx={{ fontWeight: 500 }}>
            {standName}
          </Text>
        </Box>
      </Box>
    </Paper>
  );
};
