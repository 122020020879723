import type { SVGProps } from 'react';

export const StandStatusUsing = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15.843} height={31.1} {...props}>
    <defs>
      <linearGradient id="a" x1={0.5} x2={0.5} y2={1} gradientUnits="objectBoundingBox">
        <stop offset={0} stopColor="#ede51d" />
        <stop offset={1} stopColor="#c8e817" />
      </linearGradient>
    </defs>
    <path
      data-name="\u30D1\u30B9 441"
      d="M141.428 11.736h-6.009L139.789 0h-12.018l-2.186 17.017h4.915L128.317 31.1Z"
      transform="translate(-125.585)"
      fill="url(#a)"
    />
  </svg>
);
