import { Paper, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo, useCallback } from 'react';
import type { CallBackProps, Step, TooltipRenderProps } from 'react-joyride';
import Joyride, { STATUS } from 'react-joyride';
import { COLOR } from 'src/constants';

type HowToUseStepPropsType = {
  isStart: boolean;
  onFinish: VoidFunction;
};

export const HowToUseStep: FC<HowToUseStepPropsType> = memo(({ isStart, onFinish }) => {
  const handleJoyrideCallback = useCallback(
    ({ status }: CallBackProps) => {
      if ([STATUS.FINISHED as string].includes(status)) onFinish();
    },
    [onFinish],
  );

  return (
    <Joyride
      steps={steps}
      run={isStart}
      hideCloseButton
      hideBackButton
      disableCloseOnEsc
      disableScrollParentFix
      locale={{ last: '次へ', next: '次へ' }}
      tooltipComponent={StepTooltip}
      callback={handleJoyrideCallback}
    />
  );
});
HowToUseStep.displayName = 'HowToUseStep';

const steps: Step[] = [
  {
    content: `現在のV２AEONMALLポイントが${'\n'}確認できます。`,
    disableBeacon: true,
    disableScrolling: true,
    placement: 'bottom',
    spotlightPadding: 20,
    target: '.step-1',
  },
  {
    content: 'こちらから放電をすることができます。',
    disableBeacon: true,
    placement: 'top',
    target: '.step-2',
  },
  {
    content: `獲得したポイントはこちらから${'\n'}交換することができます。`,
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 0,
    target: '.step-3',
  },
  {
    content: `放電スタンドの利用状況はこちらから${'\n'}確認することができます。`,
    disableBeacon: true,
    placement: 'top',
    target: '.step-4',
  },
  {
    content: `放電履歴を確認することができます。`,
    disableBeacon: true,
    placement: 'top',
    target: '.step-5',
  },
];

const StepTooltip: FC<TooltipRenderProps> = ({ step, tooltipProps }) => (
  <Paper
    {...tooltipProps}
    px={40}
    py={14}
    mx={16}
    radius="lg"
    sx={{ backgroundColor: COLOR.WHITE, textAlign: 'center', whiteSpace: 'pre-wrap' }}
  >
    <Text size={14} sx={{ fontWeight: 'bold' }}>
      {step.content}
    </Text>
  </Paper>
);
