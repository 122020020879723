import { Box, Center, Group, Paper, ScrollArea, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { FC } from 'react';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainButton } from 'src/components/button/MainButton';
import { TextButton } from 'src/components/button/TextButton';
import { PinIcon } from 'src/components/icon/PinIcon';
import { MainLoader } from 'src/components/loader/MainLoader';
import { MainModal } from 'src/components/modal/MainModal';
import { LinkText } from 'src/components/text/LinkText';
import { COLOR, STORE_INFO, URL } from 'src/constants';
import { useEnegateDischargingStatus } from 'src/pages/discharge/hooks/useEnegateDischargingStatus';
import { ChangeButton } from 'src/pages/standUsageStatus/components/ChangeButton';
import { StandStatusList } from 'src/pages/standUsageStatus/components/StandStatusList';
import { StoreSelectRadio } from 'src/pages/standUsageStatus/components/StoreSelectRadio';
import { URL_PATH } from 'src/router/Router';
import { STAND_STATUS } from 'src/types';

export const StandUsageStatus: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { isDischarging: boolean } | undefined;

  const [isOpenSelectStoreModal, handleSelectStoreModal] = useDisclosure(state?.isDischarging ? false : true);

  // TOPページ以外から遷移時は最新の放電状況を取得し、放電中であればモーダルを閉じて表示を変更する
  const { disChargingStatusList } = useEnegateDischargingStatus({ isDisableCache: false });
  const isDischarging = useMemo(() => {
    // 放電中が終わった時に表示が戻るように
    if (disChargingStatusList?.list && disChargingStatusList?.list.length > 1) return false;
    return state?.isDischarging || disChargingStatusList?.list.length === 1;
  }, [disChargingStatusList?.list, state?.isDischarging]);
  useEffect(() => {
    if (isDischarging) handleSelectStoreModal.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDischarging]);

  // モーダル上で選択されている店舗id
  const [radioSelectedInstallationId, setRadioSelectedInstallationId] = useState<string>(STORE_INFO[0].installationId);
  // 「店舗を選択する」ボタン押下で決定された店舗id
  const [confirmedInstallationId, setConfirmedInstallationId] = useState<string>(STORE_INFO[0].installationId);
  // スタンド状況更新日時。StandStatusList内で設定する
  const [standStatusUpdatedAt, setStandStatusUpdatedAt] = useState<string>('');

  const onOpenSelectStoreModal = useCallback(() => handleSelectStoreModal.open(), [handleSelectStoreModal]);
  const onCloseSelectStoreModal = useCallback(() => {
    handleSelectStoreModal.close();
    setRadioSelectedInstallationId(confirmedInstallationId); // 再度モーダルを開いた時に現在結果として表示している店舗がデフォルトで選択されるように
  }, [confirmedInstallationId, handleSelectStoreModal]);

  const onChangeStoreRadio = useCallback((value: string) => setRadioSelectedInstallationId(value), []);

  const onClickConfirmStore = useCallback(() => {
    setConfirmedInstallationId(radioSelectedInstallationId);
    handleSelectStoreModal.close();
  }, [handleSelectStoreModal, radioSelectedInstallationId]);

  // 放電中の時は他のスタンド状況は取得できないため表示を分ける
  if (isDischarging) {
    return (
      <Box px={16} py={26}>
        <Stack spacing={40}>
          <Center>
            <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
              スタンド利用状況
            </Text>
          </Center>
          <Box px={32}>
            {disChargingStatusList?.list.length &&
            disChargingStatusList?.list[0].standStatus === STAND_STATUS.IN_PREPARATION ? (
              <Center>
                <Text size={14} sx={{ fontWeight: 400, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                  現在認証中です。{'\n'}
                  放電を開始する場合は、TOP画面よりやり直してください。
                  <Text size={14} mt={8} color={COLOR.RED}>
                    ※数分経つと、認証が解除されスタンド利用状況がご確認いただけます。
                  </Text>
                </Text>
              </Center>
            ) : (
              <Center>
                <Text size={14} sx={{ fontWeight: 400, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                  現在放電中です。{'\n'}
                  放電の残り時間については
                  <LinkText onClick={() => navigate(URL_PATH.DISCHARGING)}>こちら</LinkText>
                  から確認可能です。
                  <Text size={14} mt={4} mb={40} color={COLOR.RED}>
                    ※放電量計測中の場合は、終了画面に遷移します。
                  </Text>
                  各店舗のスタンド利用状況は、放電利用時以外にてご確認いただけます。
                </Text>
              </Center>
            )}
          </Box>
        </Stack>
      </Box>
    );
  } else {
    return (
      <>
        <Box px={16} py={26}>
          <Stack spacing={13}>
            <Center>
              <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
                スタンド利用状況
              </Text>
            </Center>
            <Center>
              <Text size={13} sx={{ fontWeight: 500 }}>
                {standStatusUpdatedAt}現在
              </Text>
            </Center>
          </Stack>
          <Box px={32} py={35}>
            <Center>
              <Text size={13} sx={{ fontWeight: 400, textAlign: 'center' }}>
                利用可能となっている場合も、店舗の受付時間外や、車が停まっている場合は利用ができません。ご注意ください。
              </Text>
            </Center>
          </Box>
          <Group position="apart" px={8} pb={19}>
            <Group spacing={5} align="center">
              <PinIcon />
              <Text size={14} sx={{ fontWeight: 'bold' }}>
                {STORE_INFO.find((store) => store.installationId === confirmedInstallationId)?.name}
              </Text>
            </Group>
            <ChangeButton onClick={onOpenSelectStoreModal} />
          </Group>
          <Suspense
            fallback={
              <Center pt={40}>
                <MainLoader />
              </Center>
            }
          >
            <StandStatusList
              installationId={confirmedInstallationId}
              setStandStatusUpdatedAt={setStandStatusUpdatedAt}
            />
          </Suspense>
        </Box>
        <MainModal
          title={`確認したい${'\n'}店舗を選択してください。`}
          isOpen={isOpenSelectStoreModal}
          onClose={onCloseSelectStoreModal}
          mainButton={<MainButton onClick={onClickConfirmStore}>店舗を選択する</MainButton>}
          subButton={<TextButton onClick={onCloseSelectStoreModal}>閉じる</TextButton>}
        >
          <Stack>
            <Paper py={30} px={20} radius="lg" sx={{ backgroundColor: COLOR.LIGHT_GRAY }}>
              <Group position="apart">
                <Text size={18} sx={{ fontWeight: 'bold' }}>
                  地域
                </Text>
                <Text size={18} sx={{ fontWeight: 500 }}>
                  近畿
                </Text>
              </Group>
            </Paper>
            <Paper py={30} pl={20} pr={10} radius="lg" sx={{ backgroundColor: COLOR.LIGHT_GRAY }}>
              <ScrollArea offsetScrollbars scrollbarSize={8} style={{ height: '250px' }}>
                <StoreSelectRadio
                  stores={STORE_INFO}
                  value={radioSelectedInstallationId}
                  onChange={onChangeStoreRadio}
                />
              </ScrollArea>
            </Paper>
          </Stack>
          <Box pt={18}>
            <LinkText isWithIcon size={13} url={URL.SHOP_TIME}>
              実施店舗の放電受付時間はこちら
            </LinkText>
          </Box>
        </MainModal>
      </>
    );
  }
};
