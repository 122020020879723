import type { FC } from 'react';
import { useRouteError } from 'react-router-dom';
import { Page401 } from 'src/pages/error/Page401';
import { Page404 } from 'src/pages/error/Page404';
import { Page500 } from 'src/pages/error/Page500';
import { clearStorage } from 'src/utils';

export const RootErrorBoundary: FC = () => {
  const error = useRouteError() as { status: number };

  switch (error?.status) {
    case 401:
      clearStorage();
      return <Page401 />;
    case 404:
      return <Page404 />;
    default:
      return <Page500 />;
  }
};
