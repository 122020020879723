import { Box, Center, Group, Paper, Stack, Text } from '@mantine/core';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { RightArrowIcon } from 'src/components/icon/RightArrowIcon';
import { URL_PATH } from 'src/router/Router';

export const Account: FC = () => {
  return (
    <Box px={16} py={26}>
      <Center pb={35}>
        <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
          アカウント
        </Text>
      </Center>
      <Stack spacing={12}>
        <Paper radius="lg" pl={17} pt={39} pb={37} pr={12} component={Link} to={URL_PATH.ACCOUNT_SETTING}>
          <Group position="apart">
            <Text size={16} sx={{ fontWeight: 'bold' }}>
              アカウント設定
            </Text>
            <RightArrowIcon />
          </Group>
        </Paper>
        <Paper
          radius="lg"
          pl={17}
          pt={39}
          pb={37}
          pr={12}
          component={Link}
          to={URL_PATH.CARD_REGISTRATION}
          state={{ isFromAccountPage: true }}
        >
          <Group position="apart">
            <Text size={16} sx={{ fontWeight: 'bold', whiteSpace: 'pre-wrap' }}>
              WAON POINT番号・{'\n'}WAON番号の登録
            </Text>
            <RightArrowIcon />
          </Group>
        </Paper>
        <Paper radius="lg" pl={17} pt={39} pb={37} pr={12} component={Link} to={URL_PATH.DELETE_ACCOUNT}>
          <Group position="apart">
            <Text size={16} sx={{ fontWeight: 'bold' }}>
              退会する
            </Text>
            <RightArrowIcon />
          </Group>
        </Paper>
      </Stack>
    </Box>
  );
};
