import type { SVGProps } from 'react';

export const EnergySimpleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12.828} height={25.181} {...props}>
    <path
      data-name="\u30D1\u30B9 408"
      d="M12.828 9.5H7.962L11.501 0H1.769l-1.77 13.779H3.98l-1.769 11.4Z"
      fill="#fff"
    />
  </svg>
);
