import { Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { MainModal } from 'src/components/modal/MainModal';
import { COLOR } from 'src/constants';

type NoWaonNoModalPropsType = {
  isOpen: boolean;
  onClick: VoidFunction;
  onClose: VoidFunction;
};

export const NoWaonNoModal: FC<NoWaonNoModalPropsType> = memo(({ isOpen, onClick, onClose }) => {
  return (
    <MainModal isOpen={isOpen} onClose={onClose} mainButton={<MainButton onClick={onClick}>登録する</MainButton>}>
      <Center pt={40}>
        <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
          放電を行うには、WAON POINT番号またはWAON番号の登録が必要です。{'\n'}ご登録をお願いします。
        </Text>
      </Center>
      <Center pt={20}>
        <Text size={14} color={COLOR.RED}>
          ※登録内容は、アカウント ＞ WAON POINT番号またはWAON番号の登録からご確認・ご変更可能です。
        </Text>
      </Center>
    </MainModal>
  );
});
NoWaonNoModal.displayName = 'NoWaonNoModal';
