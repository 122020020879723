import type { SVGProps } from 'react';

export const MemoSmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={42.552} height={40.504} {...props}>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 579" fill="#519ff3">
      <path
        data-name="\u30D1\u30B9 271"
        d="m41.851 11.941-1.543-1.543a2.4 2.4 0 0 0-3.389 0l-1.444 1.444 4.932 4.932 1.443-1.444a2.4 2.4 0 0 0 .001-3.389Z"
      />
      <path data-name="\u30D1\u30B9 272" d="M21.948 25.37v4.932h4.932L39.066 18.12l-4.932-4.932Z" />
      <path data-name="\u9577\u65B9\u5F62 289" d="M10.066 10.803h13.931v2.322H10.066z" />
      <path data-name="\u9577\u65B9\u5F62 290" d="M10.066 19.316h13.931v2.322H10.066z" />
      <path data-name="\u9577\u65B9\u5F62 291" d="M10.066 27.829h8.513v2.322h-8.513z" />
      <path
        data-name="\u30D1\u30B9 273"
        d="M30.966 36.763a.648.648 0 0 1-.645.646H3.741a.649.649 0 0 1-.645-.646V3.742a.649.649 0 0 1 .645-.646H30.32a.648.648 0 0 1 .645.646v9.327l3.1-3.1V3.742A3.742 3.742 0 0 0 30.32 0H3.741A3.743 3.743 0 0 0 0 3.742v33.021a3.743 3.743 0 0 0 3.741 3.742H30.32a3.742 3.742 0 0 0 3.741-3.742V26.404l-3.1 3.1Z"
      />
    </g>
  </svg>
);
