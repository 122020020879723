import { Box, Button, Center, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainButton } from 'src/components/button/MainButton';
import { ScanIcon } from 'src/components/icon/ScanIcon';
import { ErrorModal } from 'src/components/modal/ErrorModal';
import { COLOR, URL } from 'src/constants';
import { ERROR_NO } from 'src/constants/errorNo';
import { CertificationCompleteModal } from 'src/pages/discharge/components/CertificationCompleteModal';
import { StandInfoModal } from 'src/pages/discharge/components/StandInfoModal';
import { useEnegateGeoloc } from 'src/pages/discharge/hooks/useEnegateGeoloc';
import { URL_PATH } from 'src/router/Router';
import type { EnegateGeolocResType } from 'src/types';
import { isWithInDistance } from 'src/utils';

export const DischargeScanQr: FC = () => {
  const navigate = useNavigate();

  const [isOpenStandInfoModal, handleStandInfoModal] = useDisclosure(false);
  const [isOpenCertificationCompleteModal, handleCertificationCompleteModal] = useDisclosure(false);

  const [userLocation, setUserLocation] = useState<{ lat: number; long: number }>({ lat: 0, long: 0 });

  const [scannedQr, setScannedQr] = useState<string>('');
  const [scannedStandInfo, setScannedStandInfo] = useState<EnegateGeolocResType['list'][number] | null>(null);

  const [isOpenErrorModal, handleOpenErrorModal] = useDisclosure(false);
  const onCloseErrorModal = useCallback(() => {
    setScannedQr('');
    handleOpenErrorModal.close();
  }, [handleOpenErrorModal]);

  const [isOpenDifferentQrErrorModal, handleOpenDifferentQrErrorModal] = useDisclosure(false);
  const onCloseDifferentErrorModal = useCallback(() => {
    setScannedQr('');
    handleOpenDifferentQrErrorModal.close();
  }, [handleOpenDifferentQrErrorModal]);

  const { enegateGeolocList, status } = useEnegateGeoloc({ qr: scannedQr });

  useEffect(() => {
    if (!enegateGeolocList?.list) return;
    // listが0件の場合はQRコードが異なる
    if (enegateGeolocList.list.length === 0) {
      handleOpenDifferentQrErrorModal.open();
      return;
    }
    if (status === 'success' && enegateGeolocList?.list.length > 0) {
      if (
        isWithInDistance(
          { latitude: userLocation.lat, longitude: userLocation.long },
          { latitude: enegateGeolocList.list[0].latitude, longitude: enegateGeolocList.list[0].longitude },
        )
      ) {
        setScannedStandInfo(enegateGeolocList.list[0]);
        handleStandInfoModal.open();
      } else {
        handleOpenErrorModal.open();
      }
    }
  }, [
    userLocation,
    enegateGeolocList,
    handleOpenErrorModal,
    handleStandInfoModal,
    status,
    handleOpenDifferentQrErrorModal,
  ]);

  // QR読み込み後にネイティブアプリ側から実行される関数
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).callFromNativeEnegateQR = (qr: string, lat: number, long: number) => {
      setScannedQr(qr);
      setUserLocation({ lat, long });
      console.log(qr);
      console.log({ lat, long });
    };
  }, []);

  const onClickBack = () => navigate(URL_PATH.TOP);

  const onCloseStandInfoModal = useCallback(() => {
    setScannedQr('');
    handleStandInfoModal.close();
  }, [handleStandInfoModal]);
  const onClickStandInfoModalCertification = useCallback(() => {
    onCloseStandInfoModal();
    handleCertificationCompleteModal.open();
  }, [handleCertificationCompleteModal, onCloseStandInfoModal]);

  const onCloseCertificationCompleteModal = useCallback(
    () => handleCertificationCompleteModal.close(),
    [handleCertificationCompleteModal],
  );
  const onClickCertificationCompleteModalNext = useCallback(() => {
    navigate(URL_PATH.SELECT_DISCHARGE_TIME);
  }, [navigate]);

  return (
    <Box>
      <Box py={26} px={32}>
        <Center>
          <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
            二次元コード読み取り
          </Text>
        </Center>
        <Center pt={48} pb={10}>
          <Text size={14} sx={{ fontWeight: 'bold', textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            スタンド番号を確認の上{'\n'}二次元コードを読み込んでください
          </Text>
        </Center>
        <Center pb={80} pt={80}>
          <Button
            rightIcon={<ScanIcon />}
            radius="xl"
            size="xl"
            fullWidth
            component="a"
            href={URL.AEON_CAMERA}
            sx={() => ({
              '&:hover': {
                backgroundColor: COLOR.MAIN,
              },
              backgroundColor: COLOR.MAIN,
              color: COLOR.WHITE,
              fontWeight: 500,
              maxWidth: '300px',
              zIndex: 1,
            })}
          >
            <Text size={21}>カメラを起動する</Text>
          </Button>
        </Center>
        <Center pt={39} px={73}>
          <MainButton backgroundColor={COLOR.MEDIUM_GRAY} onClick={onClickBack}>
            戻る
          </MainButton>
        </Center>
      </Box>
      <StandInfoModal
        isOpen={isOpenStandInfoModal}
        onClick={onClickStandInfoModalCertification}
        onClose={onCloseStandInfoModal}
        standName={scannedStandInfo?.name ?? ''}
        standNo={scannedStandInfo?.standNo ?? 0}
        installationId={scannedStandInfo?.installationId ?? ''}
      />
      <CertificationCompleteModal
        isOpen={isOpenCertificationCompleteModal}
        onClick={onClickCertificationCompleteModalNext}
        onClose={onCloseCertificationCompleteModal}
      />
      <ErrorModal
        errorNo={ERROR_NO.E003}
        isOpen={isOpenErrorModal}
        onClose={onCloseErrorModal}
        buttonElement={
          <MainButton backgroundColor={COLOR.BLACK} onClick={onCloseErrorModal}>
            戻る
          </MainButton>
        }
      >
        スタンドと離れすぎています。{'\n'}
        再度スタンドに近づいて二次元コードの読み込みを行ってください。
      </ErrorModal>
      <ErrorModal
        errorNo={ERROR_NO.E013}
        isOpen={isOpenDifferentQrErrorModal}
        onClose={handleOpenDifferentQrErrorModal.close}
        buttonElement={
          <MainButton backgroundColor={COLOR.BLACK} onClick={onCloseDifferentErrorModal}>
            戻る
          </MainButton>
        }
      >
        読み取る二次元コードが異なります。{'\n'}
        放電スタンドに設置されている二次元コードの読み取りをお願いします。
      </ErrorModal>
    </Box>
  );
};
