import type { FC } from 'react';
import { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop: FC = memo(() => {
  const location = useLocation();
  // react-routerが保持するページ毎のkey。新規の画面やリロード時は新しく生成される。ブラウザバック時は同じkeyとなる。
  const [pageKeys, setPageKeys] = useState<string[]>([]);
  useEffect(() => {
    if (!pageKeys.includes(location.key)) {
      setPageKeys([...pageKeys, location.key]);
      window.scrollTo(0, 0);
    }
  }, [location.key, pageKeys]);
  return null;
});
ScrollToTop.displayName = 'ScrollToTop';
