import { Box, Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { Suspense } from 'react';
import { MainLoadingOverlay } from 'src/components/loader/MainLoadingOverlay';
import { NewsListWithPaging } from 'src/pages/news/components/NewsListWithPaging';

export const News: FC = () => {
  return (
    <Box py={26}>
      <Center pb={35}>
        <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
          お知らせ
        </Text>
      </Center>
      <Suspense fallback={<MainLoadingOverlay />}>
        <NewsListWithPaging />
      </Suspense>
    </Box>
  );
};
