import { Box, Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainButton } from 'src/components/button/MainButton';
import { Header } from 'src/components/layout/Header';
import { LinkText } from 'src/components/text/LinkText';
import { ERROR_NO } from 'src/constants/errorNo';
import { URL_PATH } from 'src/router/Router';

export const Page500: FC = () => {
  const navigate = useNavigate();
  const onClickContact = () => navigate(URL_PATH.CONTACT);

  return (
    <Box>
      <Header />
      <Box pt={40} px={32}>
        <Center>
          <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
            アプリケーションエラー
          </Text>
        </Center>
        <Center pt={40}>
          <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            アプリケーションエラーが発生しました。{'\n'}イオンモールアプリを再起動し、操作をやり直してください。{'\n'}
            {'\n'}改善しない場合は、申し訳ありませんが、<LinkText onClick={onClickContact}>お問い合わせ</LinkText>
            よりご連絡をお願いします。
          </Text>
        </Center>
        <Center pt={16}>
          <Text size={16}>{ERROR_NO.E002}</Text>
        </Center>
        <Center pt={40}>
          <MainButton onClick={() => navigate(URL_PATH.TOP)}>TOPへ戻る</MainButton>
        </Center>
      </Box>
    </Box>
  );
};
