import { STORAGE_KEY } from 'src/constants';

export const getApikey = () => {
  // HTTPヘッダに設定する際に""が邪魔になるため除去して返却
  return (localStorage.getItem(STORAGE_KEY.API_KEY) ?? '').replace(/"/g, '');
};

export const getEid = () => {
  return (localStorage.getItem(STORAGE_KEY.EID) ?? '').replace(/"/g, '');
};

export const clearStorage: VoidFunction = () => {
  localStorage.removeItem(STORAGE_KEY.API_KEY);
  localStorage.removeItem(STORAGE_KEY.EID);
};

export const getIsCheckedDischargeError = (): boolean => {
  return (localStorage.getItem(STORAGE_KEY.IS_CHECKED_DISCHARGE_ERROR) ?? '').replace(/"/g, '') === 'true'
    ? true
    : false;
};

export const setIsCheckedDischargeError = (flag: boolean) => {
  localStorage.setItem(STORAGE_KEY.IS_CHECKED_DISCHARGE_ERROR, flag ? 'true' : 'false');
};
