import { useQuery } from '@tanstack/react-query';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { EnegateHistoryResType } from 'src/types';
import { getApikey, nowDateYYYYMMDD_dash, twoYearsBeforeDateYYYYMMDD_dash } from 'src/utils';

export const useEnegateHistory = ({
  isDisableCache = false,
  isEnable = true,
  isSuspense = true,
}: {
  isDisableCache?: boolean;
  isEnable?: boolean;
  isSuspense?: boolean;
}) => {
  const nowDate = nowDateYYYYMMDD_dash();
  const twoYearsBeforeDate = twoYearsBeforeDateYYYYMMDD_dash();
  const { data } = useQuery<EnegateHistoryResType>({
    // トップ画面ではキャッシュを使用しない(キャッシュの最新放電履歴でエラー判定してしまうため)
    cacheTime: isDisableCache ? 0 : 5 * 60 * 1000,
    enabled: !!getApikey() && isEnable,
    queryFn: async () => {
      const { data } = await axiosInstance.get<EnegateHistoryResType>(
        `${API_PATH.ENEGATE_HISTORY}?from=${twoYearsBeforeDate}&to=${nowDate}`,
        {
          headers: {
            Authorization: `Bearer ${getApikey()}`,
          },
        },
      );
      return data;
    },
    queryKey: [API_PATH.ENEGATE_HISTORY],
    refetchInterval: 5 * 60 * 1000,
    // トップ画面ではsupenseを用いない(キャッシュを使わず毎回最新取得するので不要なローディングを表示させないため)
    suspense: isSuspense,
  });
  return { enegateHistory: data };
};
