import { Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { MainModal } from 'src/components/modal/MainModal';

type CertificationCompleteModalPropsType = {
  isOpen: boolean;
  onClick: VoidFunction;
  onClose: VoidFunction;
};

export const CertificationCompleteModal: FC<CertificationCompleteModalPropsType> = memo(
  ({ isOpen, onClick, onClose }) => {
    return (
      <MainModal
        isOpen={isOpen}
        onClose={onClose}
        isCloseOnClickOutside={false}
        title="認証が完了しました！"
        mainButton={<MainButton onClick={onClick}>次へ</MainButton>}
      >
        <Center pt={14} pb={40}>
          <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            次の画面で放電時間を{'\n'}設定してください。
          </Text>
        </Center>
      </MainModal>
    );
  },
);
CertificationCompleteModal.displayName = 'CertificationCompleteModal';
