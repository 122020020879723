import { Text } from '@mantine/core';
import type { FC, ReactNode } from 'react';

type BottomSheetMenuTextPropsType = {
  children: ReactNode;
};

export const BottomSheetMenuText: FC<BottomSheetMenuTextPropsType> = ({ children }) => {
  return (
    <Text sx={{ fontWeight: 'bold' }} size={15}>
      {children}
    </Text>
  );
};
