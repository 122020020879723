import type { SVGProps } from 'react';

export const RightArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 587" transform="translate(-151.434 -2002.434)">
      <circle
        data-name="\u6955\u5186\u5F62 34"
        cx={11}
        cy={11}
        r={11}
        transform="translate(151.434 2002.434)"
        fill="#b60081"
      />
      <path
        data-name="\u30D1\u30B9 275"
        d="M160.535 2018.311a.75.75 0 0 1-.5-1.309l3.966-3.544-3.971-3.613a.75.75 0 0 1 1.009-1.109l5.2 4.732-5.207 4.652a.747.747 0 0 1-.497.191Z"
        fill="#fff"
      />
    </g>
  </svg>
);
