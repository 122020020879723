import { useQuery } from '@tanstack/react-query';
import { API_PATH } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { EnegateStatusResType } from 'src/types';
import { getApikey } from 'src/utils';

type UseEnegateStatusPropsType = {
  installationId: string;
};

export const useEnegateStandStatus = ({ installationId }: UseEnegateStatusPropsType) => {
  const { data } = useQuery<EnegateStatusResType>({
    enabled: !!getApikey(),
    queryFn: async () => {
      const { data } = await axiosInstance.get<EnegateStatusResType>(
        `${API_PATH.ENEGATE_STATUS}?installationId=${installationId}`,
        {
          headers: {
            Authorization: `Bearer ${getApikey()}`,
          },
        },
      );
      return data;
    },
    queryKey: [API_PATH.ENEGATE_STATUS, installationId],
  });
  return { enegateStatusList: data };
};
