import { LoadingOverlay } from '@mantine/core';
import type { FC } from 'react';
import { MainLoader } from 'src/components/loader/MainLoader';

type MainLoaderPropsType = {
  isVisible?: boolean;
};

export const MainLoadingOverlay: FC<MainLoaderPropsType> = ({ isVisible = true }) => {
  return <LoadingOverlay visible={isVisible} overlayOpacity={0.5} loader={<MainLoader />} />;
};
