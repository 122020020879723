import type { SVGProps } from 'react';

export const StandSmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40.533} height={40.671} {...props}>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 473" fill="#09b555">
      <path
        data-name="\u524D\u9762\u30AA\u30D6\u30B8\u30A7\u30AF\u30C8\u3067\u578B\u629C\u304D 6"
        d="M28.468 40.671H.726A.726.726 0 0 1 0 39.95v-4.439a.726.726 0 0 1 .213-.514.726.726 0 0 1 .514-.213H2.35V4.4A4.373 4.373 0 0 1 6.685 0h15.829a4.373 4.373 0 0 1 4.336 4.4v14.461a5.192 5.192 0 0 1 2.917 1.109 5.631 5.631 0 0 1 1.758 2.423 5.3 5.3 0 0 1 .351 1.864v5.116a1.923 1.923 0 0 0 .306 1.237.289.289 0 0 0 .238.126.509.509 0 0 0 .349-.189 1.782 1.782 0 0 0 .335-1.177v-9.2a.726.726 0 0 1 .726-.726h2.68a.726.726 0 0 1 .726.726v9.2a5.891 5.891 0 0 1-1.399 3.98 4.6 4.6 0 0 1-3.421 1.57h-.018a4.336 4.336 0 0 1-1.812-.4 4.45 4.45 0 0 1-1.536-1.176 5.986 5.986 0 0 1-1.312-3.972V24.248a1.338 1.338 0 0 0-.189-.593v-.006a1.255 1.255 0 0 0-.521-.506 1.053 1.053 0 0 0-.18-.068v11.71h1.619a.726.726 0 0 1 .726.726v4.433a.727.727 0 0 1-.725.727ZM1.45 39.219h26.292v-2.981h-1.619a.726.726 0 0 1-.726-.726V22.341a.726.726 0 0 1 .62-.719 3.357 3.357 0 0 1 .5-.043 2.6 2.6 0 0 1 1.152.257 2.7 2.7 0 0 1 1.138 1.077 2.745 2.745 0 0 1 .393 1.348v5.112a4.566 4.566 0 0 0 .964 3.028 3.007 3.007 0 0 0 1.036.792 2.871 2.871 0 0 0 1.2.269h.02a3.152 3.152 0 0 0 2.33-1.082 4.451 4.451 0 0 0 1.029-3.005V20.9h-1.224v8.473a3.155 3.155 0 0 1-.7 2.134l-.005.006a1.933 1.933 0 0 1-1.434.673H32.4a1.737 1.737 0 0 1-1.317-.627l-.006-.007a3.268 3.268 0 0 1-.654-2.179v-5.115a3.853 3.853 0 0 0-.258-1.351 4.176 4.176 0 0 0-1.3-1.8 3.747 3.747 0 0 0-2.331-.8h-.016c-.116 0-.235.008-.335.015a.726.726 0 0 1-.781-.724V4.4a2.919 2.919 0 0 0-2.888-2.95H6.681A2.919 2.919 0 0 0 3.798 4.4v31.111a.726.726 0 0 1-.726.726H1.45ZM20.762 18.85H8.433a.726.726 0 0 1-.726-.726V6.183a.726.726 0 0 1 .726-.726h12.33a.726.726 0 0 1 .726.726v11.936a.726.726 0 0 1-.727.731Zm-11.6-1.453h10.874V6.909H9.159Z"
      />
      <g data-name="\u30D5\u309A\u30E9\u30AF\u3099\u3001\u30B3\u30F3\u30BB\u30F3\u30C8\u306E\u30A2\u30A4\u30B3\u30F31">
        <path
          data-name="\u30D1\u30B9 264"
          d="M38.507 10.516V7.241a.442.442 0 0 0-.467-.411h-.748a.442.442 0 0 0-.468.411v3.275H33.44V7.241a.442.442 0 0 0-.466-.411h-.747a.442.442 0 0 0-.468.411v3.275h-1.95v1.394h10.648v-1.394Z"
        />
        <path
          data-name="\u30D1\u30B9 265"
          d="M29.734 12.341h10.8v2.455a4.88 4.88 0 0 1-2.435 4.216v.459a1.512 1.512 0 0 1-1.51 1.51h-2.908a1.512 1.512 0 0 1-1.51-1.51v-.459a4.881 4.881 0 0 1-2.435-4.216Zm9.345 1.453h-7.892v1a3.424 3.424 0 0 0 2.008 3.112l.427.193v1.369a.057.057 0 0 0 .057.057h2.908a.057.057 0 0 0 .057-.057v-1.369l.427-.193a3.424 3.424 0 0 0 2.008-3.112Z"
        />
      </g>
    </g>
  </svg>
);
