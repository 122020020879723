import { Box, Center, Grid, List, Paper, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainButton } from 'src/components/button/MainButton';
import { TextButton } from 'src/components/button/TextButton';
import { ErrorModal } from 'src/components/modal/ErrorModal';
import { LinkText } from 'src/components/text/LinkText';
import { COLOR } from 'src/constants';
import { ERROR_NO } from 'src/constants/errorNo';
import { ConfirmBeforeDischargeModal } from 'src/pages/discharge/components/ConfirmBeforeDischargeModal';
import { LoadingBeforeDischargeModal } from 'src/pages/discharge/components/LoadingBeforeDischargeModal';
import { TimeCard } from 'src/pages/discharge/components/TimeCard';
import { useEnegateDischargingStatus } from 'src/pages/discharge/hooks/useEnegateDischargingStatus';
import { useEnegateStartDischarge } from 'src/pages/discharge/hooks/useEnegateStartDischarge';
import { URL_PATH } from 'src/router/Router';
import { STAND_STATUS } from 'src/types';
import { setIsCheckedDischargeError } from 'src/utils';

const DISCHARGE_TIME: number[] = [30, 60, 90, 120, 150, 180];

export const SelectDischargeTime: FC = () => {
  const navigate = useNavigate();
  const { enegateStartDischarge } = useEnegateStartDischarge();

  const [settingTime, setSettingTime] = useState<number>(DISCHARGE_TIME[1]);
  const [isOpenErrorModal, handleOpenErrorModal] = useDisclosure(false);
  const [isCheckingStatus, setIsCheckingStatus] = useState<boolean>(false);
  const [startDischargeTime, setStartDischargeTime] = useState<Dayjs | null>(null);

  const { dataUpdatedAt, disChargingStatusList } = useEnegateDischargingStatus({
    isDisableCache: true,
    isEnable: isCheckingStatus,
    refetchInterval: 5000,
  });
  useEffect(() => {
    if (!disChargingStatusList?.list) return;
    const { list } = disChargingStatusList;
    const nowDatetime = dayjs();
    // スタンド情報が複数とれる(認証状態が解除されている)場合、放電開始要求から2分以上経過した場合はエラーモーダルを表示する
    if (list.length > 1 || startDischargeTime?.add(2, 'minute').isBefore(nowDatetime)) {
      handleOpenErrorModal.open();
      setIsCheckingStatus(false);
    }
    // ステータスが放電開始になったら放電画面に遷移する
    if (list[0].standStatus === STAND_STATUS.DISCHARGING) navigate(URL_PATH.DISCHARGING, { state: { settingTime } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disChargingStatusList, settingTime, startDischargeTime, dataUpdatedAt]); // dataUpdatedAtはAPIが実行完了される度にこの処理を実行したいので記載

  const onCloseErrorModal = useCallback(() => {
    navigate(URL_PATH.DISCHARGE_SCAN_QR);
  }, [navigate]);
  const [isOpenConfirmBeforeDischargeModal, handleOpenConfirmBeforeDischargeModal] = useDisclosure(false);
  const onOpenConfirmBeforeDischargeModal = useCallback(
    () => handleOpenConfirmBeforeDischargeModal.open(),
    [handleOpenConfirmBeforeDischargeModal],
  );
  const onCloseConfirmBeforeDischargeModal = useCallback(
    () => handleOpenConfirmBeforeDischargeModal.close(),
    [handleOpenConfirmBeforeDischargeModal],
  );

  const onClickDischargeTime = useCallback((minute: number) => setSettingTime(minute), []);

  const onClickStartDischarge = async () => {
    try {
      onCloseConfirmBeforeDischargeModal();
      setIsCheckingStatus(true);
      const { data } = await enegateStartDischarge({ settingTime });
      if (data.result) {
        setIsCheckedDischargeError(false);
        setStartDischargeTime(dayjs());
      } else {
        throw Error();
      }
    } catch {
      setIsCheckingStatus(false);
      handleOpenErrorModal.open();
    }
  };
  const onClickBack = () => navigate(-1);

  return (
    <Box>
      <Box pt={26} pb={36} px={16}>
        <Center>
          <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
            放電時間
          </Text>
        </Center>
        <Center pt={20}>
          <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            放電時間を選択して{'\n'}放電開始ボタンを押してください。
          </Text>
        </Center>
        <Box pt={20} pb={26} pl={10} pr={16}>
          <List
            size={12}
            styles={() => ({
              item: {
                '&:before': {
                  background: COLOR.RED,
                },
                color: COLOR.RED,
              },
            })}
          >
            <List.Item>
              放電時間30分で24WAON POINTを進呈します。ポイントは月毎に集計し翌月21日以降に使用可能となります。
            </List.Item>
            <List.Item>
              ポイントは放電時間15分単位で進呈します。15分未満の場合、ポイント進呈はできません。
              <br />
              （例：40分放電の場合、24ポイント進呈）
            </List.Item>
          </List>
        </Box>
        <Paper px={16} py={28} radius="lg">
          <Grid gutter="sm">
            {DISCHARGE_TIME.map((time) => (
              <Grid.Col key={time} span={6}>
                <TimeCard minute={time} isSelected={settingTime === time} onClick={onClickDischargeTime} />
              </Grid.Col>
            ))}
          </Grid>
          <Center pt={34} pb={9}>
            <Text size={15} color={COLOR.MAIN} sx={{ fontWeight: 500 }}>
              注意事項
            </Text>
          </Center>
          <Paper radius="md" py={20} px={14} sx={{ backgroundColor: COLOR.LIGHT_GRAY }}>
            <List size={12}>
              <List.Item>本サービスは1日1回、3時間までのご利用とさせていただきます。</List.Item>
              <List.Item>
                放電量の目安は30分間で約3kWhです。
                <br />
                車両の状況により放電量の低下や操作の中断が起こる場合があります。
              </List.Item>
              <List.Item>お帰りに必要な電気量が残るよう余裕を持って放電時間を選択してください。</List.Item>
              <List.Item>
                車種により設定されている放電下限値を超えた放電ではできません。初めてご利用いただく際は、以下「対象車種一覧・車種による放電時間目安(外部サイト)」を事前にご確認ください。
              </List.Item>
              <List.Item>
                次のお客様がお待ちしている場合があります。
                <br />
                放電完了後は速やかにお車のご移動をお願い致します。※本アプリのPush通知を許可していただくと、放電完了時に通知が届きます。
              </List.Item>
            </List>
          </Paper>
          <Stack>
            <Center px={41} pt={35}>
              <MainButton onClick={onOpenConfirmBeforeDischargeModal}>放電開始</MainButton>
            </Center>
            <Center px={41}>
              <TextButton onClick={onClickBack}>戻る</TextButton>
            </Center>
          </Stack>
        </Paper>
        <Box pt={15} pl={16}>
          <LinkText url="https://www.nichicon.co.jp/products/v2h/model/" isWithIcon size={14}>
            対象車種一覧・車種による放電時間目安(外部サイト)
          </LinkText>
        </Box>
      </Box>
      <ConfirmBeforeDischargeModal
        isOpen={isOpenConfirmBeforeDischargeModal}
        onClick={onClickStartDischarge}
        onClose={onCloseConfirmBeforeDischargeModal}
      />
      <LoadingBeforeDischargeModal isOpen={isCheckingStatus} />
      <ErrorModal
        errorNo={ERROR_NO.E004}
        isOpen={isOpenErrorModal}
        onClose={onCloseErrorModal}
        buttonElement={<MainButton onClick={onCloseErrorModal}>戻る</MainButton>}
      >
        放電開始処理に失敗しました。もう一度操作をやり直してください。{'\n'}
        <Text size={14} mt={8} color={COLOR.RED}>
          ※放電コネクタが正しく差し込まれているか確認してください。
        </Text>
      </ErrorModal>
    </Box>
  );
};
