import { Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo } from 'react';
import { MainLoader } from 'src/components/loader/MainLoader';
import { MainModal } from 'src/components/modal/MainModal';
import { COLOR } from 'src/constants';

type CertificationCompleteModalPropsType = {
  isOpen: boolean;
};

export const LoadingBeforeDischargeModal: FC<CertificationCompleteModalPropsType> = memo(({ isOpen }) => {
  return (
    <MainModal isOpen={isOpen} onClose={() => ({})} isCloseOnClickOutside={false}>
      <Center pt={50} px={16}>
        <Text size={20} sx={{ fontWeight: 'bold', textAlign: 'center', whiteSpace: 'pre-wrap' }}>
          安全確認後、放電処理が開始されます。{'\n'}この画面を表示したままお待ちください。
        </Text>
      </Center>
      <Center pt={16} pb={24}>
        <Text size={15} mt={8} color={COLOR.RED}>
          ※通常、20～30秒程で放電が開始されます。放電コネクタが正しく差し込まれていない場合、放電が開始されない為、ご注意ください。
        </Text>
      </Center>
      <Center pb={20} px={16}>
        <MainLoader size={'lg'} />
      </Center>
    </MainModal>
  );
});
LoadingBeforeDischargeModal.displayName = 'LoadingBeforeDischargeModal';
