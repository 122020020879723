import type { SVGProps } from 'react';

export const AccountIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={43.36} height={45.339} {...props}>
    <path
      data-name="\u30D1\u30B9 341"
      d="M27.813 19.66a10.787 10.787 0 1 0-12.266 0A24.424 24.424 0 0 0 0 32.11a24.379 24.379 0 0 0 43.36 0 24.424 24.424 0 0 0-15.547-12.45Z"
      fill="#718ec3"
    />
  </svg>
);
