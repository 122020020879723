import type { FC } from 'react';
import { memo } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { CheckIcon } from 'src/components/icon/CheckIcon';
import { EnergySimpleIcon } from 'src/components/icon/EnergySimpleIcon';
import { ScanIcon } from 'src/components/icon/ScanIcon';
import { COLOR } from 'src/constants';
import type { StandStatusType } from 'src/types';
import { STAND_STATUS } from 'src/types';

type TopMainButtonPropsType = {
  dischargeStatus: StandStatusType;
  isLoading: boolean;
  onClick: VoidFunction;
};

export const TopMainButton: FC<TopMainButtonPropsType> = memo(({ dischargeStatus, isLoading, onClick }) => {
  switch (dischargeStatus) {
    case STAND_STATUS.DISCHARGING:
      return (
        <MainButton
          rightIcon={<EnergySimpleIcon />}
          backgroundColor={COLOR.MEDIUM_GREEN}
          isLoading={isLoading}
          size={25}
          onClick={onClick}
        >
          放電中
        </MainButton>
      );
    case STAND_STATUS.END:
      return (
        <MainButton
          rightIcon={<CheckIcon />}
          backgroundColor={COLOR.BLACK}
          isLoading={isLoading}
          size={25}
          onClick={onClick}
        >
          放電完了
        </MainButton>
      );
    default:
      return (
        <MainButton
          rightIcon={<ScanIcon />}
          backgroundColor={COLOR.MAIN}
          isLoading={isLoading}
          size={25}
          onClick={onClick}
        >
          放電する
        </MainButton>
      );
  }
});
TopMainButton.displayName = 'TopMainButton';
