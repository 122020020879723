import { useForm } from '@mantine/form';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MESSAGE } from 'src/constants';
import { useUpdateWaonNo } from 'src/pages/pointExchange/hooks/useUpdateWaonNo';
import { URL_PATH } from 'src/router/Router';
import { isNotHalfSizeNum, replaceNotHalfSizeNum, splitWaonNo } from 'src/utils';

export const useWaonNoForm = ({ isFromAccountPage, waon }: { isFromAccountPage?: boolean; waon?: string | null }) => {
  const navigate = useNavigate();
  const { errorText, isLoading, updateWaonNo } = useUpdateWaonNo();

  const [waonNo1, waonNo2, waonNo3, waonNo4] = splitWaonNo(waon);

  const form = useForm({
    initialValues: {
      waonNo1,
      waonNo2,
      waonNo3,
      waonNo4,
      waonNoConfirm1: waonNo1,
      waonNoConfirm2: waonNo2,
      waonNoConfirm3: waonNo3,
      waonNoConfirm4: waonNo4,
    },
  });

  useEffect(() => {
    // 半角数値以外が入力されないようにする
    const { waonNo2, waonNo3, waonNo4, waonNoConfirm2, waonNoConfirm3, waonNoConfirm4 } = form.values;
    if (
      !isNotHalfSizeNum(waonNo2) &&
      !isNotHalfSizeNum(waonNo3) &&
      !isNotHalfSizeNum(waonNo4) &&
      !isNotHalfSizeNum(waonNoConfirm2) &&
      !isNotHalfSizeNum(waonNoConfirm3) &&
      !isNotHalfSizeNum(waonNoConfirm4)
    )
      return;

    form.setValues({
      waonNo2: replaceNotHalfSizeNum(waonNo2),
      waonNo3: replaceNotHalfSizeNum(waonNo3),
      waonNo4: replaceNotHalfSizeNum(waonNo4),
      waonNoConfirm2: replaceNotHalfSizeNum(waonNoConfirm2),
      waonNoConfirm3: replaceNotHalfSizeNum(waonNoConfirm3),
      waonNoConfirm4: replaceNotHalfSizeNum(waonNoConfirm4),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  const handleSubmit = useCallback(
    async (values: typeof form.values) => {
      const { waonNo1, waonNo2, waonNo3, waonNo4, waonNoConfirm1, waonNoConfirm2, waonNoConfirm3, waonNoConfirm4 } =
        values;
      const waonNo = `${waonNo1}${waonNo2}${waonNo3}${waonNo4}`;
      const waonNoConfirm = `${waonNoConfirm1}${waonNoConfirm2}${waonNoConfirm3}${waonNoConfirm4}`;
      if (waonNo.length !== 16 || waonNoConfirm.length !== 16) {
        form.setFieldError('waonNoError', MESSAGE.WAON_NO_LENGTH);
        return;
      }
      if (waonNo !== waonNoConfirm) {
        form.setFieldError('waonNoError', MESSAGE.WAON_NO_UNMATCHED);
        // 該当要素を赤文字にするための処理（useFrom内のvalidateに記載するとsubmitに到達しないためここに記載）
        if (waonNo1 !== waonNoConfirm1) form.setFieldError('waonNoConfirm1', ' ');
        if (waonNo2 !== waonNoConfirm2) form.setFieldError('waonNoConfirm2', ' ');
        if (waonNo3 !== waonNoConfirm3) form.setFieldError('waonNoConfirm3', ' ');
        if (waonNo4 !== waonNoConfirm4) form.setFieldError('waonNoConfirm4', ' ');
        return;
      }
      // イオンJMBカードは対象外のためエラーとする
      if (
        (waonNo >= '6900177000000000' && waonNo <= '6900177129999999') ||
        (waonNo >= '6900177310000000' && waonNo <= '6900177509999999')
      ) {
        form.setFieldError('waonNoError', MESSAGE.WAON_NO_JMB);
        return;
      }
      // updateWaonNo内でエラーハンドリングしているので正常時のみ画面遷移
      await updateWaonNo({ waon: waonNoConfirm });
      if (isFromAccountPage) {
        navigate(URL_PATH.ACCOUNT);
      } else {
        navigate(URL_PATH.DISCHARGE_SCAN_QR);
      }
    },
    [form, isFromAccountPage, navigate, updateWaonNo],
  );

  const clearError = useCallback(() => form.clearErrors(), [form]);

  return { clearError, errorText, form, handleSubmit, isSubmitting: isLoading };
};
