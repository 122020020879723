import { Box, Center, Group, Paper, Text } from '@mantine/core';
import type { FC } from 'react';
import { StandStatusAvailable } from 'src/components/icon/StandStatusAvailable';
import { StandStatusUnavailable } from 'src/components/icon/StandStatusUnavailable';
import { StandStatusUsing } from 'src/components/icon/StandStatusUsing';
import { COLOR } from 'src/constants';
import type { StandStatusType } from 'src/types';
import { STAND_STATUS } from 'src/types';

type StandStatusCardPropsType = {
  standNo: number;
  status: StandStatusType;
};

export const StandStatusCard: FC<StandStatusCardPropsType> = ({ standNo, status }) => {
  let statusName = '利用中';
  let statusIcon = <StandStatusUsing />;
  let border = 'none';
  let backgroundColor = '#FFF8D3';
  switch (status) {
    case STAND_STATUS.AVAILABLE:
      statusName = '利用可能';
      statusIcon = <StandStatusAvailable />;
      border = `solid 3px ${COLOR.MAIN_GREEN}`;
      backgroundColor = COLOR.WHITE;
      break;
    case STAND_STATUS.INACTIVE:
      statusName = '休止中';
      statusIcon = <StandStatusUnavailable />;
      border = 'none';
      backgroundColor = '#EBEBEB';
      break;
    case STAND_STATUS.OUT_OF_ORDER:
      statusName = '故障中';
      statusIcon = <StandStatusUnavailable />;
      border = 'none';
      backgroundColor = '#EBEBEB';
      break;
    default:
      break;
  }

  return (
    <Paper radius="lg" px={20} py={20} sx={{ backgroundColor, border }}>
      <Group position="apart">
        <Text size={31} sx={{ fontWeight: 500, lineHeight: '46px' }}>
          {standNo}
        </Text>
        <Box sx={{ width: '96px' }}>
          <Center>
            <Text size={17} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
              {statusName}
            </Text>
          </Center>
        </Box>
        <Box sx={{ width: '40px' }}>
          <Center>{statusIcon}</Center>
        </Box>
      </Group>
    </Paper>
  );
};
