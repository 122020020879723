import type { SVGProps } from 'react';

export const StandStatusAvailable = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={39} height={39} {...props}>
    <path
      data-name="\u6955\u5186\u5F62 47"
      d="M19.5 4A15.5 15.5 0 0 0 8.54 30.46 15.5 15.5 0 1 0 30.46 8.54 15.4 15.4 0 0 0 19.5 4m0-4A19.5 19.5 0 1 1 0 19.5 19.5 19.5 0 0 1 19.5 0Z"
      fill="#09b555"
    />
  </svg>
);
