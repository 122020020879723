import { useLocalStorage } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { API_PATH, STORAGE_KEY } from 'src/constants';
import { axiosInstance } from 'src/libs/axios';
import type { LoginAeonResType } from 'src/types';
import { getApikey, getEid } from 'src/utils';

export const useLogin = () => {
  const [searchParams] = useSearchParams();

  const [apikey, setApiKey] = useLocalStorage({ defaultValue: getApikey(), key: STORAGE_KEY.API_KEY });
  const [, setEid] = useLocalStorage({ defaultValue: '', key: STORAGE_KEY.EID });

  useQuery<LoginAeonResType>({
    onSuccess: (data) => {
      setApiKey(data.apikey);
      setEid(data.eid);
    },
    queryFn: async () => {
      const token = searchParams.get('token');
      if (!token && apikey) return { apikey, eid: getEid() };
      const { data } = await axiosInstance.post<LoginAeonResType>(
        API_PATH.LOGIN_AEON,
        { device: 'v2aeon' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data;
    },
    queryKey: [API_PATH.LOGIN_AEON],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
