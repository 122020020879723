import { Text, UnstyledButton } from '@mantine/core';
import type { FC } from 'react';
import { COLOR } from 'src/constants';

type ChangeButtonPropsType = {
  onClick: VoidFunction;
};

export const ChangeButton: FC<ChangeButtonPropsType> = ({ onClick }) => {
  return (
    <UnstyledButton onClick={onClick} py={5} px={17} sx={{ backgroundColor: COLOR.WHITE, borderRadius: '999px' }}>
      <Text size={13} sx={{ fontWeight: 500 }}>
        変更する
      </Text>
    </UnstyledButton>
  );
};
