import Big from 'big.js';

type LatLongType = {
  latitude: number;
  longitude: number;
};

// 2点間の緯度経度を導出(参考：https://qiita.com/kawanet/items/a2e111b17b8eb5ac859a)
const R = Math.PI / 180;
const calcLatLongDistance = (place1: LatLongType, place2: LatLongType) => {
  const lat1 = place1.latitude * R;
  const long1 = place1.longitude * R;
  const lat2 = place2.latitude * R;
  const long2 = place2.longitude * R;
  return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(long2 - long1) + Math.sin(lat1) * Math.sin(lat2));
};

// 有効範囲とする半径(km)
const STORE_RADIUS = 0.2;
export const isWithInDistance = (place1: LatLongType, place2: LatLongType): boolean => {
  // 有効範囲から２点間の距離を除算して0より大きければokとする
  const distance = new Big(STORE_RADIUS).minus(calcLatLongDistance(place1, place2));
  return distance.gte(0);
};
