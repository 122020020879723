import { Box, Center, Text } from '@mantine/core';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainButton } from 'src/components/button/MainButton';
import { Header } from 'src/components/layout/Header';
import { ERROR_NO } from 'src/constants/errorNo';
import { URL_PATH } from 'src/router/Router';

export const Page404: FC = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Header />
      <Box pt={40} px={32}>
        <Center>
          <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
            ページが見つかりません
          </Text>
        </Center>
        <Center pt={40}>
          <Text size={15} sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            指定されたページは見つかりませんでした。{'\n'}お問い合わせよりご連絡をお願い致します。
          </Text>
        </Center>
        <Center pt={16}>
          <Text size={16}>{ERROR_NO.E010}</Text>
        </Center>
        <Center pt={40}>
          <MainButton onClick={() => navigate(URL_PATH.TOP)}>TOPへ戻る</MainButton>
        </Center>
      </Box>
    </Box>
  );
};
