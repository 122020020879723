import { Box, Center, Stack, Text } from '@mantine/core';
import type { FC } from 'react';
import { LinkText } from 'src/components/text/LinkText';
import { URL } from 'src/constants';
import { getEid } from 'src/utils';

export const Contact: FC = () => {
  const eid = getEid();

  return (
    <Box px={16} py={26}>
      <Center pb={35}>
        <Text size={22} sx={{ fontWeight: 'bold', letterSpacing: '2px' }}>
          お問い合わせ
        </Text>
      </Center>
      <Stack px={16} spacing={20}>
        <Text size={15} fw={500}>
          V2AEONMALLについてのお問い合わせは、以下のメールアドレス宛に、V2AEONMALLトップ画面に記載のeidと合わせて、ご連絡をお願い致します。
        </Text>
        <Text size={14} ta="center">
          <a
            href={`mailto:v2aeonmall_info@felicapocketmk.co.jp?subject=V2AEONMALL問い合わせ&body=・eid：${eid}%0D%0A・ご利用店舗：%0D%0A・ご使用の端末のOS：%0D%0A・OSバージョン：%0D%0A・発生時刻：%0D%0A%0D%0A・以下より問い合わせ内容をご記載ください%0D%0A------------------------------------`}
          >
            v2aeonmall_info@felicapocketmk.co.jp
          </a>
        </Text>
        <Text size={15} fw={500}>
          通常、2営業日以内（土日祝、年末年始除く）にご返信致します。
        </Text>
        <Text size={14} fw={500} ta="center" mt={20}>
          よくある質問は
          <LinkText url={URL.FAQ}>こちら</LinkText>
        </Text>
      </Stack>
    </Box>
  );
};
